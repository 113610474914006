import React from 'react';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel, Paper, Link, Typography, Box } from '@mui/material';
import { styled } from '@mui/system';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
}));

const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  fontWeight: 'bold',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontSize: '1rem',
}));

const StyledTableSortLabel = styled(TableSortLabel)(({ theme }) => ({
  '&.MuiTableSortLabel-root': {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
    '&.Mui-active': {
      color: theme.palette.common.white,
    },
    '& .MuiTableSortLabel-icon': {
      color: theme.palette.common.white,
    },
  },
}));

const NumberContainer = styled(Box)({
  display: 'inline-block',
  width: '50px',  // Adjust this width as needed
  textAlign: 'right',
});

const TableComponent = ({ data, columns, sortConfig, onSort, linkPrefix, isProvider, showAgreements }) => {
  const renderCellContent = (column, row) => {
    if (column.key === 'providerName' || column.key === 'categoryName') {
      return (
        <Link 
          href={`${linkPrefix}/${isProvider ? row.providerId : row.categoryId}/${encodeURIComponent(row[column.key])}`} 
          target="_blank" 
          rel="noopener noreferrer"
        >
          {row[column.key]}
        </Link>
      );
    } else if (column.key === 'total') {
      return row.published + row.unpublished;
    } else if (column.key === 'published' && showAgreements) {
      const withAgreements = row.publishedWithAgreements || 0;
      const percentage = ((withAgreements / row[column.key]) * 100).toFixed(1);
      const percentageColor = percentage === '100.0' ? 'green' : 'red';

      return (
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <NumberContainer>
            {row[column.key]}
          </NumberContainer>
          <span style={{ 
            fontSize: '0.8rem', 
            color: percentageColor, 
            marginLeft: '4px', 
            minWidth: '45px' 
          }}>
        ({percentage}%)
      </span>
        </Box>
      );
    } else {
      return row[column.key];
    }
  };

  return (
    <TableContainer component={Paper}>
      <StyledTable>
        <StyledTableHeader>
          <TableRow>
            {columns.map((column) => (
              <StyledTableHeaderCell key={column.key} align={column.align || 'left'}>
                {column.sortable ? (
                  <StyledTableSortLabel
                    active={sortConfig.key === column.key}
                    direction={sortConfig.key === column.key ? sortConfig.direction : 'desc'}
                    onClick={() => onSort(column.key)}
                  >
                    {column.label}
                    {column.icon && <FiberManualRecordIcon sx={{ color: column.iconColor, ml: 1 }} />}
                  </StyledTableSortLabel>
                ) : (
                  column.label
                )}
              </StyledTableHeaderCell>
            ))}
          </TableRow>
        </StyledTableHeader>
        <TableBody>
          {data.map((row) => (
            <TableRow key={isProvider ? row.providerId : row.categoryId}>
              {columns.map((column) => (
                <StyledTableCell key={column.key} align={column.align || 'left'}>
                  {renderCellContent(column, row)}
                </StyledTableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default TableComponent;

// const TableComponent = ({ data, columns, sortConfig, onSort, linkPrefix, isProvider }) => {
//   return (
//     <TableContainer component={Paper}>
//       <StyledTable>
//         <StyledTableHeader>
//           <TableRow>
//             {columns.map((column) => (
//               <StyledTableHeaderCell key={column.key} align={column.align || 'left'}>
//                 {column.sortable ? (
//                   <StyledTableSortLabel
//                     active={sortConfig.key === column.key}
//                     direction={sortConfig.key === column.key ? sortConfig.direction : 'desc'}
//                     onClick={() => onSort(column.key)}
//                   >
//                     {column.label}
//                     {column.icon && <FiberManualRecordIcon sx={{ color: column.iconColor, ml: 1 }} />}
//                   </StyledTableSortLabel>
//                 ) : (
//                   column.label
//                 )}
//               </StyledTableHeaderCell>
//             ))}
//           </TableRow>
//         </StyledTableHeader>
//         <TableBody>
//           {data.map((row, index) => (
//             <TableRow key={isProvider ? row.providerId : row.categoryId}>
//               {columns.map((column) => (
//                 <StyledTableCell key={column.key} align={column.align || 'left'}>
//                   {column.key === 'providerName' || column.key === 'categoryName' ? (
//                     <Link 
//                       href={`${linkPrefix}/${isProvider ? row.providerId : row.categoryId}/${encodeURIComponent(row[column.key])}`} 
//                       target="_blank" 
//                       rel="noopener noreferrer"
//                     >
//                       {row[column.key]}
//                     </Link>
//                   ) : column.key === 'total' ? (
//                     row.published + row.unpublished
//                   ) : (
//                     row[column.key]
//                   )}
//                 </StyledTableCell>
//               ))}
//             </TableRow>
//           ))}
//         </TableBody>
//       </StyledTable>
//     </TableContainer>
//   );
// };

// export default TableComponent;
