import {
    Box,
    Tooltip,
    CircularProgress
  } from '@mui/material';
  import {
    CheckCircle as CheckCircleIcon,
    Schedule as ScheduleIcon,
    Error as ErrorIcon
  } from '@mui/icons-material';




const StatusChip = ({ status }) => {
    const getStatusConfig = (status) => {
      switch (status) {
        case 'completed':
          return { 
            color: 'success.main', 
            label: 'Completed',
            icon: <CheckCircleIcon sx={{ fontSize: 20 }} />
          };
        case 'processing':
          return { 
            color: 'primary.main', 
            label: 'Processing',
            icon: <CircularProgress size={18} />
          };
        case 'error':
          return { 
            color: 'error.main', 
            label: 'Error',
            icon: <ErrorIcon sx={{ fontSize: 20 }} />
          };
        case 'pending':
        default:
          return { 
            color: 'text.disabled', 
            label: 'Pending',
            icon: <ScheduleIcon sx={{ fontSize: 20 }} />
          };
      }
    };

  const config = getStatusConfig(status);

  return (
    <Tooltip title={config.label}>
      <Box 
        component="span" 
        sx={{ 
          display: 'inline-flex',
          color: config.color,
          alignItems: 'center',
          '& svg': {
            transition: 'all 0.2s'
          },
          '&:hover svg': {
            transform: 'scale(1.1)'
          }
        }}
      >
        {config.icon}
      </Box>
    </Tooltip>
  );
};



export default StatusChip;