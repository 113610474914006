import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Chip,
    Button,
    Tooltip,
    Link,
    Avatar,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import LaunchIcon from '@mui/icons-material/Launch'; 
import { useNavigate } from 'react-router-dom';
import MoneyIcon from '@mui/icons-material/Money';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DeleteIcon from '@mui/icons-material/Delete';

// Optional: Function to format dates
const formatDate = (date) => {
  return new Date(date).toLocaleDateString();
};

    const TableHeader = () => (
    <TableHead sx={{ backgroundColor: 'primary.main' }}>
        <TableRow>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>#</TableCell> {/* New column for Name */}
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell> {/* New column for Name */}
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Contract Type</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Education Provider</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Fees & Limits</TableCell>
        {/* <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Students</TableCell> */}
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Start Date</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Contract</TableCell>
        <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Actions</TableCell>
        {/* <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Assign to All Courses</TableCell>  */}
        </TableRow>
    </TableHead>
    );

    const MydraAgreementsList = ({ agreements, onEditAgreement, onAssignToAllCourses, onDeleteAgreement }) => {
        const navigate = useNavigate();
        const [assignToAllCourses, setAssignToAllCourses] = useState({});
        const [deleteDialog, setDeleteDialog] = useState({
            open: false,
            agreement: null
        });


    console.log('Agreements:', agreements);


    if (!agreements || agreements.length === 0) {
        return <Typography>No agreements found.</Typography>;
    }

    // Handler for navigating to the provider's courses page
    const handleProviderClick = (providerId, providerName) => {
        navigate(`/mydra/provider/${providerId}/${encodeURIComponent(providerName)}`);
    };

    const handleToggleChange = (agreement) => {
        const newValue = !assignToAllCourses[agreement._id];
        setAssignToAllCourses(prevState => ({
            ...prevState,
            [agreement._id]: newValue
        }));
        onAssignToAllCourses(agreement);
    };

    const handleDeleteClick = (agreement) => {
        setDeleteDialog({
            open: true,
            agreement: agreement
        });
    };

    const handleDeleteConfirm = () => {
        if (deleteDialog.agreement) {
            onDeleteAgreement(deleteDialog.agreement._id);
        }
        setDeleteDialog({
            open: false,
            agreement: null
        });
    };

    const handleDeleteCancel = () => {
        setDeleteDialog({
            open: false,
            agreement: null
        });
    };


        return ( 
        <>
        <TableContainer component={Paper}>
            <Table aria-label="agreements table">
                <TableHeader />
                <TableBody>
                {agreements.map((agreement, index) => (
                    <TableRow key={agreement?._id} hover>
                    <TableCell>{index + 1}</TableCell> {/* Display Counter */}
                    <TableCell>{agreement?.name}</TableCell> {/* Display the name */}
                    <TableCell>{agreement?.contractType}</TableCell>
                    <TableCell>
                        {agreement?.educationProvider && (
                        <Chip
                            avatar={<Avatar alt={agreement.educationProvider.name} src={agreement.educationProvider.logoUrl} />}
                            label={agreement.educationProvider.name}
                            clickable
                            color="primary"
                            onClick={() => handleProviderClick(agreement.educationProvider._id, agreement.educationProvider.name)}
                        />
                        )}
                    </TableCell>
                    <TableCell>
                        <Chip
                        label={agreement?.status}
                        color={agreement?.status === 'active' ? 'success' : 'default'}
                        />
                    </TableCell>
                    
                    <TableCell>
                        {Object.values(agreement.fees.reduce((acc, fee) => {
                            // Group escalation fees by type and origin
                            const key = `${fee.type}-${fee.origin}`;
                            if (!acc[key]) {
                                acc[key] = [];
                            }
                            acc[key].push(fee);
                            return acc;
                        }, {})).map((feeGroup, index) => {
                            // Check if it's an escalation fee group
                            if (feeGroup[0].type === 'escalation') {
                                const title = feeGroup.map((d, i) => `Discount ${i + 1}: ${d.discount * 100}% up to ${d.maxStudents} students`).join('; ');
                                const labelSummary = feeGroup.length > 1 ? `${feeGroup.length} levels` : `Discount: ${feeGroup[0].discount * 100}% up to ${feeGroup[0].maxStudents} students`;

                                return (
                                    <Tooltip key={index} title={title}>
                                        <Chip
                                            icon={<TrendingUpIcon />}
                                            label={labelSummary}
                                            color="secondary"
                                            variant="outlined"
                                            sx={{ mr: 1, mb: 1 }}
                                        />
                                    </Tooltip>
                                );
                            } else {
                                // Handle flat fees
                                const fee = feeGroup[0]; // Assuming only one flat fee per group
                                const title = `Flat: ${fee.discount * 100}% up to N/A students`;
                                return (
                                    <Tooltip key={index} title={title}>
                                        <Chip
                                            icon={<MoneyIcon />}
                                            label={`${fee.discount * 100}%`}
                                            color="primary"
                                            variant="outlined"
                                            sx={{ mr: 1, mb: 1 }}
                                        />
                                    </Tooltip>
                                );
                            }
                        })}
                    </TableCell>

                    
                    {/* <TableCell>{agreement?.studentsInAgreement}</TableCell> */}
                    <TableCell>{formatDate(agreement?.startAt)}</TableCell>
                    {/* <TableCell>{agreement.emailForNotifications}</TableCell> */}
                    <TableCell>
                        <Link href={agreement?.contractUrl} target="_blank" rel="noopener noreferrer">
                        <Tooltip title="Open Contract">
                            <LaunchIcon color="action" />
                        </Tooltip>
                        </Link>
                    </TableCell>
                    <TableCell>
                        <Tooltip title="Edit Agreement">
                            <IconButton
                                color="primary"
                                onClick={() => onEditAgreement(agreement)}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        
                        <Tooltip title="Delete Agreement">
                            <IconButton
                                color="error"
                                onClick={() => handleDeleteClick(agreement)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>

                        <Tooltip title="Assign to All Courses">
                            <Switch
                                checked={assignToAllCourses[agreement._id] || false}
                                onChange={() => handleToggleChange(agreement)}
                                color="primary"
                            />
                        </Tooltip>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
        {/* Delete Confirmation Dialog */}
        <Dialog
        open={deleteDialog.open}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
    >
        <DialogTitle id="delete-dialog-title">
            Confirm Delete Agreement
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="delete-dialog-description">
                Are you sure you want to delete the agreement "{deleteDialog.agreement?.name}"? 
                This action cannot be undone.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleDeleteCancel} color="primary">
                Cancel
            </Button>
            <Button onClick={handleDeleteConfirm} color="error" variant="contained">
                Delete
            </Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

export default MydraAgreementsList;

