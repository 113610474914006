import { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  Switch,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Chip,
  Checkbox,
  Button,
  useTheme,
  Grid
} from '@mui/material';
import {
  Info as InfoIcon,
  OpenInNew as OpenInNewIcon,
  FiberManualRecord as FiberManualRecordIcon,
  CheckCircle as CheckCircleIcon,
  Block as BlockIcon,
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
  Search as SearchIcon,
  School as SchoolIcon
} from '@mui/icons-material';
import { BarChart, XAxis, YAxis, Bar, ResponsiveContainer, CartesianGrid, Tooltip as RechartsTooltip } from 'recharts';
import { alpha } from '@mui/material/styles';
import { getAuth , onAuthStateChanged} from 'firebase/auth';
// import { formatDistanceToNow, format } from 'date-fns';

// Add these utility functions at the top of the file, before the component declarations

const formatDateCohorts = (dateString) => {
    if (!dateString) return 'Self-paced';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric' 
    });
  };
  
  const formatTimeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    
    let interval = seconds / 31536000; // years
    if (interval > 1) return Math.floor(interval) + ' years ago';
    
    interval = seconds / 2592000; // months
    if (interval > 1) return Math.floor(interval) + ' months ago';
    
    interval = seconds / 86400; // days
    if (interval > 1) return Math.floor(interval) + ' days ago';
    
    interval = seconds / 3600; // hours
    if (interval > 1) return Math.floor(interval) + ' hours ago';
    
    interval = seconds / 60; // minutes
    if (interval > 1) return Math.floor(interval) + ' minutes ago';
    
    return Math.floor(seconds) + ' seconds ago';
  };

const EnvironmentSwitch = ({ isProduction, onChange }) => (
  <Box 
    sx={{ 
      display: 'flex',
      alignItems: 'center',
      bgcolor: (theme) => alpha(theme.palette.background.default, 0.6),
      borderRadius: 2,
      p: 1
    }}
  >
    <Typography 
      variant="body2" 
      sx={{ 
        color: !isProduction ? 'primary.main' : 'text.secondary',
        fontWeight: !isProduction ? 600 : 400
      }}
    >
      Staging
    </Typography>
    <Switch
      checked={isProduction}
      onChange={onChange}
      sx={{ mx: 1 }}
    />
    <Typography 
      variant="body2"
      sx={{ 
        color: isProduction ? 'primary.main' : 'text.secondary',
        fontWeight: isProduction ? 600 : 400
      }}
    >
      Production
    </Typography>
  </Box>
);

const StatCard = ({ icon: Icon, value, label, color }) => (
  <Box 
    sx={{ 
      display: 'flex', 
      alignItems: 'center',
      gap: 2,
      p: 2,
      borderRadius: 2,
      bgcolor: (theme) => alpha(theme.palette.background.default, 0.6)
    }}
  >
    <Box
      sx={{
        width: 48,
        height: 48,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.1),
        color: `${color}.main`
      }}
    >
      <Icon sx={{ fontSize: 24 }} />
    </Box>
    <Box>
      <Typography variant="h4" sx={{ fontWeight: 700, color: `${color}.main` }}>
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary">
        {label}
      </Typography>
    </Box>
  </Box>
);

const CourseTable = ({ courses, isProduction }) => {
  const theme = useTheme();
  
  return (
    <TableContainer 
      component={Paper} 
      elevation={0}
      sx={{ 
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox />
            </TableCell>
            <TableCell sx={{ fontWeight: 600 }}>#</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Course Name</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Category</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Cost</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Next cohort</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Created At</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Agreement</TableCell>
            <TableCell sx={{ fontWeight: 600 }}>
              Quality Score
              <Tooltip title="Quality score is calculated based on course content completeness and richness">
                <InfoIcon 
                  sx={{ 
                    ml: 1, 
                    fontSize: 16, 
                    color: 'primary.main',
                    cursor: 'help'
                  }} 
                />
              </Tooltip>
            </TableCell>
            <TableCell sx={{ fontWeight: 600 }}>Published</TableCell>
            <TableCell align="right" sx={{ fontWeight: 600 }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map((course, index) => (
            <TableRow 
              key={course._id} 
              hover
              sx={{
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04)
                }
              }}
            >
              <TableCell padding="checkbox">
                <Checkbox />
              </TableCell>
              <TableCell>{index + 1}</TableCell>
              <TableCell>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  {course.name}
                </Typography>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                  {course.categories?.map(cat => (
                    <Chip 
                      key={cat.name}
                      label={cat.name}
                      size="small"
                      sx={{ 
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                        color: 'primary.main',
                        fontWeight: 500
                      }}
                    />
                  ))}
                </Box>
              </TableCell>
              <TableCell>
                {course.cohorts?.[0]?.cost ? 
                  <Typography 
                    variant="subtitle2"
                    sx={{ fontWeight: 600 }}
                  >
                    {`${(course.cohorts[0].cost.amount / 100).toFixed(2)} ${course.cohorts[0].cost.currency}`}
                  </Typography> : 
                  <Typography variant="body2" color="text.disabled">N/A</Typography>
                }
              </TableCell>
              <TableCell>
                <Typography variant="subtitle2">
                  {course.cohorts?.[0]?.startDate ? 
                    formatDateCohorts(course.cohorts[0].startDate) : 
                    'Self-paced'
                  }
                </Typography>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                  <Typography variant="caption" color="text.secondary">
                    {formatTimeAgo(course.createdAt)}
                  </Typography>
                  <Typography variant="caption" color="text.disabled">
                    {new Date(course.createdAt).toLocaleDateString()}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell>
                {course.agreements?.length ? (
                  <Chip 
                    icon={<CheckCircleIcon sx={{ fontSize: 16 }} />} 
                    label={course.agreements[0].name}
                    size="small"
                    sx={{
                      bgcolor: (theme) => alpha(theme.palette.success.main, 0.1),
                      color: 'success.main',
                      fontWeight: 500,
                      '& .MuiChip-icon': {
                        color: 'success.main'
                      }
                    }}
                  />
                ) : (
                  <Chip 
                    icon={<BlockIcon sx={{ fontSize: 16 }} />}
                    label="No agreement"
                    size="small"
                    sx={{
                      bgcolor: (theme) => alpha(theme.palette.error.main, 0.1),
                      color: 'error.main',
                      fontWeight: 500,
                      '& .MuiChip-icon': {
                        color: 'error.main'
                      }
                    }}
                  />
                )}
              </TableCell>
              <TableCell>
                <Typography 
                  variant="subtitle2"
                  sx={{ 
                    fontWeight: 600,
                    color: theme => {
                      const score = course.qualityScore || 0;
                      if (score > 90) return theme.palette.success.main;
                      if (score > 70) return theme.palette.warning.main;
                      return theme.palette.error.main;
                    }
                  }}
                >
                  {course.qualityScore}%
                </Typography>
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <FiberManualRecordIcon 
                    sx={{ 
                      color: course.published ? 'success.main' : 'error.main',
                      fontSize: 12
                    }} 
                  />
                  <Typography 
                    variant="subtitle2"
                    sx={{ 
                      fontWeight: 500,
                      color: course.published ? 'success.main' : 'error.main'
                    }}
                  >
                    {course.published ? 'Published' : 'Draft'}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                <IconButton 
                  onClick={() => window.open(`/mydra/course/${course._id}${!isProduction ? '?env=Staging' : ''}`, '_blank')}
                  sx={{
                    color: 'primary.main',
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08)
                    }
                  }}
                >
                  <OpenInNewIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const CategoryChart = ({ courses }) => (
    <Box sx={{ height: 300 }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart 
          data={Object.entries(
            courses.reduce((acc, course) => {
              course.categories?.forEach(cat => {
                acc[cat.name] = (acc[cat.name] || 0) + 1;
              });
              return acc;
            }, {})
          ).map(([name, value]) => ({ name, value }))}
          margin={{ bottom: 90 }}
        >
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke={(theme) => alpha(theme.palette.divider, 0.3)}
          />
          <XAxis 
            dataKey="name" 
            angle={-45} 
            textAnchor="end" 
            interval={0}
            tick={{ fill: (theme) => theme.palette.text.secondary, fontSize: 12 }}
          />
          <YAxis
            tick={{ fill: (theme) => theme.palette.text.secondary, fontSize: 12 }}
          />
          <RechartsTooltip />
          <Bar 
            dataKey="value" 
            fill="#3B82F6"
            radius={[4, 4, 0, 0]}
            style={{
                filter: 'drop-shadow(0 2px 4px rgba(59, 130, 246, 0.1))'
            }}
            />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
const ActionButtons = () => (
  <Box 
    sx={{ 
      display: 'flex', 
      gap: 2,
      mb: 3
    }}
  >
    {[
      { icon: CheckCircleIcon, label: 'Publish Selected', color: 'success' },
      { icon: BlockIcon, label: 'Unpublish Selected', color: 'warning' },
      { icon: DeleteIcon, label: 'Delete Selected', color: 'error' }
    ].map((action) => (
      <Button
        key={action.label}
        variant="outlined"
        color={action.color}
        startIcon={<action.icon />}
        sx={{ 
          textTransform: 'none',
          fontWeight: 500
        }}
      >
        {action.label}
      </Button>
    ))}
  </Box>
);

const NoConnectionMessage = ({ isProduction }) => (
  <Paper 
    elevation={0}
    sx={{ 
      p: 4, 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center',
      gap: 2,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'divider'
    }}
  >
    <InfoIcon color="info" sx={{ fontSize: 48 }} />
    <Typography variant="h6">No Mydra Connection</Typography>
    <Typography 
      variant="body1" 
      color="text.secondary" 
      align="center"
      sx={{ maxWidth: 500 }}
    >
      You need to connect to Mydra first to view and manage courses in 
      {isProduction ? ' Production' : ' Staging'} environment.
      Please go to the Overview tab and set up the connection.
    </Typography>
  </Paper>
);
const ProviderCourses = ({ provider }) => {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isProduction, setIsProduction] = useState(true);
  const [authToken, setAuthToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [connectionStatus] = useState({
    Staging: provider.mydra_education_provider?.Staging || '',
    Production: provider.mydra_education_provider?.Production || '',
  });


  const formatDateCohorts = (dateString) => {
    if (!dateString) return 'Self-paced';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
  };

  const formatTimeAgo = (date) => {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    
    let interval = seconds / 31536000;
    if (interval > 1) return Math.floor(interval) + ' years ago';
    
    interval = seconds / 2592000;
    if (interval > 1) return Math.floor(interval) + ' months ago';
    
    interval = seconds / 86400;
    if (interval > 1) return Math.floor(interval) + ' days ago';
    
    interval = seconds / 3600;
    if (interval > 1) return Math.floor(interval) + ' hours ago';
    
    interval = seconds / 60;
    if (interval > 1) return Math.floor(interval) + ' minutes ago';
    
    return Math.floor(seconds) + ' seconds ago';
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setAuthToken(token);
        setUserEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchCourses = async () => {
        console.log("fetchCourses - fetching courses")
      const currentEnvironment = isProduction ? 'Production' : 'Staging';
      const environmentConnectionId = connectionStatus[currentEnvironment];
      
      if (!environmentConnectionId || !authToken || !userEmail) {
        setCourses([]);
        return;
      }

      try {
        setIsLoading(true);
        const response = await fetch(`${API_BASE_URL}/mydra/provider/${environmentConnectionId}/courses`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'X-Environment': currentEnvironment,
            'X-User-Email': userEmail
          }
        });

        if (response.ok) {
          const data = await response.json();
          console.log("Courses: ", data);
          // Sort courses by createdAt in descending order (newest first)
          const sortedCourses = [...data].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
          setCourses(sortedCourses || []);
        }
      } catch (error) {
        console.error('Error fetching courses:', error);
        setCourses([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCourses();
  }, [authToken, userEmail, isProduction, connectionStatus, API_BASE_URL]);



  // Show message if no Mydra connection
  const currentEnvironmentConnection = connectionStatus[isProduction ? 'Production' : 'Staging'];
  if (!currentEnvironmentConnection) {
    return (
      <Box sx={{ p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Typography variant="body1">Staging</Typography>
          <Switch
            checked={isProduction}
            onChange={() => setIsProduction(!isProduction)}
          />
          <Typography variant="body1">Production</Typography>
        </Box>
        {isProduction && (
          <Typography variant="subtitle2" color="error">
            You are in Production mode. Changes here will affect live courses.
          </Typography>
        )}
      </Box>
        <Paper 
          sx={{ 
            p: 4, 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center',
            gap: 2
          }}
        >
          <InfoIcon color="info" sx={{ fontSize: 48 }} />
          <Typography variant="h6">No Mydra Connection for {isProduction ? 'Production' : 'Staging'}</Typography>
          <Typography variant="body1" color="text.secondary" align="center">
            You need to connect to Mydra first to view and manage courses in this environment.
            Please go to the Overview tab and set up the connection.
          </Typography>
        </Paper>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 4 }}>
      {/* Header Section */}
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center', 
          mb: 4 
        }}
      >
        <EnvironmentSwitch 
          isProduction={isProduction} 
          onChange={() => setIsProduction(!isProduction)} 
        />
        {isProduction && (
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              px: 2,
              py: 1,
              borderRadius: 2,
              bgcolor: (theme) => alpha(theme.palette.error.main, 0.1),
              color: 'error.main'
            }}
          >
            <InfoIcon sx={{ fontSize: 20 }} />
            <Typography variant="subtitle2">
              Production mode - Changes will affect live courses
            </Typography>
          </Box>
        )}
      </Box>
  
      {!currentEnvironmentConnection ? (
        <NoConnectionMessage isProduction={isProduction} />
      ) : isLoading ? (
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'center', 
            justifyContent: 'center',
            minHeight: '400px',
            gap: 2
          }}
        >
          <CircularProgress size={40} />
          <Typography variant="body1" color="text.secondary">
            Loading courses...
          </Typography>
        </Box>
      ) : (
        <>
          {/* Stats Section */}
          <Grid container spacing={3} sx={{ mb: 4 }}>
            <Grid item xs={12} md={4}>
              <StatCard
                icon={SchoolIcon}
                value={courses.length}
                label="Total Courses"
                color="primary"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard
                icon={CheckCircleIcon}
                value={courses.filter(c => c.published).length}
                label="Published Courses"
                color="success"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <StatCard
                icon={BlockIcon}
                value={courses.filter(c => !c.published).length}
                label="Unpublished Courses"
                color="error"
              />
            </Grid>
          </Grid>
  
          {/* Categories Chart */}
          <Paper 
            elevation={0}
            sx={{ 
              p: 3, 
              mb: 4,
              borderRadius: 2,
              border: '1px solid',
              borderColor: 'divider'
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 3
            }}>
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Courses by Category
              </Typography>
              <Button
                startIcon={<FilterListIcon />}
                variant="outlined"
                sx={{ 
                  textTransform: 'none',
                  fontWeight: 500
                }}
              >
                Filter
              </Button>
            </Box>
            <CategoryChart courses={courses} />
          </Paper>
  
          {/* Table Section */}
          <Box sx={{ mb: 4 }}>
            <Box 
              sx={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 3
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Course Management
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  startIcon={<SearchIcon />}
                  variant="outlined"
                  sx={{ 
                    textTransform: 'none',
                    fontWeight: 500
                  }}
                >
                  Search
                </Button>
                <Button
                  startIcon={<FilterListIcon />}
                  variant="outlined"
                  sx={{ 
                    textTransform: 'none',
                    fontWeight: 500
                  }}
                >
                  Filter
                </Button>
              </Box>
            </Box>
  
            <ActionButtons />
            
            <CourseTable 
              courses={courses} 
              isProduction={isProduction}
            />
          </Box>
        </>
      )}
    </Box>
  );

};



export default ProviderCourses;
