import React, { useState, useEffect } from 'react';
import {
    Box, Typography, CircularProgress, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Button, TextField,
    Dialog, DialogTitle, DialogContent, DialogActions, Switch,
    Container, Snackbar, Alert, Collapse, IconButton, Link, Chip
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { styled } from '@mui/material/styles';
import LabelIcon from '@mui/icons-material/Label';


import { getAuth, onAuthStateChanged } from 'firebase/auth';

const StyledHeaderCell = styled(TableCell)(({ theme }) => ({
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    width: '25%',
    maxWidth: '25%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const TagSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    overflow: 'hidden',
}));

const FixedWidthCell = styled(TableCell)(({ theme }) => ({
    width: '25%',
    maxWidth: '25%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
}));

const MydraTagsPage = () => {
    const [authToken, setAuthToken] = useState(null);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [newTagName, setNewTagName] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [isProduction, setIsProduction] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [expandedTag, setExpandedTag] = useState(null);
    const sortedTags = tags.sort((a, b) => b.courses.length - a.courses.length);
    const [isCreatingTag, setIsCreatingTag] = useState(false);




    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthToken(token);
                });
            } else {
                console.log('User not logged in');
            }
        });
    }, []);

    useEffect(() => {
        if (authToken) {    
            fetchTags();
        }
    }, [authToken, isProduction]);

    const fetchTags = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (response.ok) {
                const data = await response.json();
                const tagsWithCourses = await Promise.all(data.map(async (tag) => {
                    const courses = await fetchCoursesForTag(tag.slug);
                    return { ...tag, courses };
                }));
                setTags(tagsWithCourses);
            } else {
                throw new Error("Failed to fetch tags");
            }
        } catch (error) {
            console.error("Error fetching tags:", error);
            setError("Failed to load tags. Please try again.");
        } finally {
            setLoading(false);
        }
    };

    const fetchCoursesForTag = async (tagSlug) => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags/${tagSlug}/courses`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            const data = await response.json();
            
            if (response.ok) {
                console.log(`Courses fetched for tag ${tagSlug}:`, data);
                return data.data; // Return the 'data' array from the response
            } else {
                console.error(`Error response from server for tag ${tagSlug}:`, data);
                throw new Error(data.error || `Failed to fetch courses for tag ${tagSlug}`);
            }
        } catch (error) {
            console.error(`Error fetching courses for tag ${tagSlug}:`, error);
            showSnackbar(`Failed to fetch courses for tag ${tagSlug}. ${error.message}`, 'error');
            return [];
        }
    };

    const handleCreateTag = async () => {
        setIsCreatingTag(true);

        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: newTagName }),
            });
            if (response.ok) {
                setNewTagName('');
                setOpenDialog(false);
                fetchTags();
                showSnackbar('Tag created successfully', 'success');
            } else {
                throw new Error("Failed to create tag");
            }
        } catch (error) {
            console.error("Error creating tag:", error);
            showSnackbar("Failed to create tag. Please try again.", 'error');
        } finally {
            setIsCreatingTag(false);
        }
    };

    const toggleEnvironment = () => {
        setIsProduction(!isProduction);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleExpandTag = (tagSlug) => {
        setExpandedTag(expandedTag === tagSlug ? null : tagSlug);
    };

    const getCourseUrl = (courseId) => {
        const env = isProduction ? '' : 'staging.';
        const hubUrl = `https://www.mydrahub.com/mydra/course/${courseId}?env=${isProduction ? 'Production' : 'Staging'}`;
        const marketplaceUrl = `https://marketplace.${env}mydra.io/en/courses/${courseId}`;
        return { hubUrl, marketplaceUrl };
    };

    if (loading) return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
        </Box>
    );
    if (error) return <Typography color="error">{error}</Typography>;

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Typography variant="h4" align="center" sx={{ mt: 2, mb: 4, fontWeight: 'bold', color: 'primary.main' }}>
                Tag Management
            </Typography>
            <Button variant="contained" onClick={() => setOpenDialog(true)} sx={{ mb: 2 }}>
                Create New Tag
            </Button>

            <Box display="flex" alignItems="center" mb={2} ml={2}>
                <Typography variant="body1">Staging</Typography>
                <Switch
                    checked={isProduction}
                    onChange={toggleEnvironment}
                    inputProps={{ 'aria-label': 'Environment toggle' }}
                />
                <Typography variant="body1">Production</Typography>
            </Box>
            {isProduction && (
                <Typography variant="subtitle1" color="error" sx={{ mb: 2 }}>
                    Warning: You are in Production mode. Changes may impact all live courses.
                </Typography>
            )}

            {sortedTags.map((tag) => (
                <TagSection key={tag.slug}>
                    <TableContainer component={Paper}>
                        <Table>
                        <TableHead>
                            <StyledTableRow>
                                <StyledHeaderCell>Tag Name</StyledHeaderCell>
                                <StyledHeaderCell>Slug</StyledHeaderCell>
                                <StyledHeaderCell>Associated Courses</StyledHeaderCell>
                                <StyledHeaderCell>Actions</StyledHeaderCell>
                            </StyledTableRow>
                        </TableHead>
                            <TableBody>
                                <StyledTableRow>
                                <FixedWidthCell >
                                    <Chip
                                        icon={<LabelIcon />}
                                        label={tag.name}
                                        color="primary"
                                        variant="outlined"
                                    />
                                    </FixedWidthCell>
                                    <FixedWidthCell>{tag.slug}</FixedWidthCell>
                                    <FixedWidthCell>{tag.courses.length}</FixedWidthCell>
                                    <FixedWidthCell>
                                        <IconButton onClick={() => handleExpandTag(tag.slug)}>
                                            {expandedTag === tag.slug ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </IconButton>
                                    </FixedWidthCell>
                                </StyledTableRow>
                                <TableRow>
                                    <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                                        <Collapse in={expandedTag === tag.slug} timeout="auto" unmountOnExit>
                                            <Box margin={1}>
                                                <Typography variant="h6" gutterBottom component="div">
                                                    Courses
                                                </Typography>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Name</TableCell>
                                                            <TableCell>Education Provider</TableCell>
                                                            <TableCell>Category</TableCell>
                                                            <TableCell>Links</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {tag.courses.map((course) => {
                                                            const { hubUrl, marketplaceUrl } = getCourseUrl(course._id);
                                                            return (
                                                                <TableRow key={course._id}>
                                                                    <TableCell component="th" scope="row">
                                                                        {course.name}
                                                                    </TableCell>
                                                                    <TableCell>{course.educationProvider.name}</TableCell>
                                                                    <TableCell>
                                                                        {course.categories.map(cat => (
                                                                            <Chip
                                                                            key={cat._id}
                                                                            label={cat.name}
                                                                            size="small"
                                                                            sx={{ mr: 0.5, mb: 0.5 }}
                                                                            />
                                                                        ))}
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Link href={hubUrl} target="_blank" rel="noopener noreferrer" sx={{ mr: 1 }}>
                                                                            Hub <OpenInNewIcon fontSize="small" />
                                                                        </Link>
                                                                        <Link href={marketplaceUrl} target="_blank" rel="noopener noreferrer">
                                                                            Marketplace <OpenInNewIcon fontSize="small" />
                                                                        </Link>
                                                                    </TableCell>
                                                                </TableRow>
                                                            );
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </Box>
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </TagSection>
            ))}

            <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
                <DialogTitle>Create New Tag</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Tag Name"
                        fullWidth
                        variant="outlined"
                        value={newTagName}
                        onChange={(e) => setNewTagName(e.target.value)}
                        disabled={isCreatingTag}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    <Button 
                        onClick={handleCreateTag} 
                        disabled={isCreatingTag || !newTagName.trim()}
                    >
                        {isCreatingTag ? (
                            <>
                                Creating... <CircularProgress size={20} sx={{ ml: 1 }} />
                            </>
                        ) : (
                            'Create Tag'
                        )}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default MydraTagsPage;
