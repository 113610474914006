// const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'http://192.168.0.21:8081';

const getHeaders = () => {
    const headers = {
        'Content-Type': 'application/json'
    };

    const token = localStorage.getItem('accessToken');
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    return headers;
};

const handleResponse = async (response) => {
    // First get the response data
    const data = await response.json().catch(e => {
        console.error('Failed to parse response as JSON:', e);
        return null;
    });
    
    console.log('Response status:', response.status);
    console.log('Response data:', data);

    if (!response.ok) {
        if (response.status === 401) {
            window.location.href = '/login';
            throw new Error('Unauthorized access');
        }
        
        // Create an error object with more details
        const error = new Error(data?.message || `HTTP error! status: ${response.status}`);
        error.status = response.status;
        error.data = data;
        error.response = response;
        throw error;
    }
    
    return data;
};


export const api = {
    get: async (endpoint, params = {}) => {
        const queryString = new URLSearchParams(params).toString();
        const url = `${endpoint}${queryString ? `?${queryString}` : ''}`;
        
        console.log('GET Request URL:', url);
        
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: getHeaders()
            });
            
            return handleResponse(response);
        } catch (error) {
            console.error('GET Request failed:', {
                url,
                error,
                status: error.status,
                data: error.data
            });
            throw error;
        }
    },

    post: async (endpoint, data) => {
        console.log('POST Request:', {
            url: endpoint,
            data: data
        });
        
        try {
            const response = await fetch(endpoint, {
                method: 'POST',
                headers: getHeaders(),
                body: JSON.stringify(data)
            });
            
            return handleResponse(response);
        } catch (error) {
            console.error('POST Request failed:', {
                url: endpoint,
                data: data,
                error,
                status: error.status,
                responseData: error.data
            });
            throw error;
        }
    },

    put: async (endpoint, data) => {
        console.log('PUT Request:', {
            url: endpoint,
            data: data
        });
        
        try {
            const response = await fetch(endpoint, {
                method: 'PUT',
                headers: getHeaders(),
                body: JSON.stringify(data)
            });
            
            return handleResponse(response);
        } catch (error) {
            console.error('PUT Request failed:', {
                url: endpoint,
                data: data,
                error,
                status: error.status,
                responseData: error.data
            });
            throw error;
        }
    },

    delete: async (endpoint) => {
        console.log('DELETE Request:', { url: endpoint });
        
        try {
            const response = await fetch(endpoint, {
                method: 'DELETE',
                headers: getHeaders()
            });
            
            return handleResponse(response);
        } catch (error) {
            console.error('DELETE Request failed:', {
                url: endpoint,
                error,
                status: error.status,
                responseData: error.data
            });
            throw error;
        }
    }
};
