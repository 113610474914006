import React, { useState, useEffect } from 'react';
import { 
    Box, Chip, TextField, Button, IconButton, CircularProgress, Typography,
    Card, CardContent, CardHeader, Select, MenuItem, FormControl, InputLabel,
    Modal, List, ListItem, ListItemText
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';

const TagManagementInfoModal = ({ open, handleClose }) => (
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="tag-management-modal-title"
        aria-describedby="tag-management-modal-description"
    >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
        }}>
            <Typography id="tag-management-modal-title" variant="h6" component="h2" gutterBottom>
                How to Manage Tags?
            </Typography>
            <List id="tag-management-modal-description">
                <ListItem>
                    <ListItemText primary="You can add tags to a course from the list of available tags." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="You can remove tags by clicking on the 'x' on the tag." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="If you want to create a new tag, you first need to create a 'global tag'." />
                </ListItem>
                <ListItem>
                    <ListItemText primary="Once a global tag is created, you can assign it to the course." />
                </ListItem>
            </List>
            <Button onClick={handleClose}>Close</Button>
        </Box>
    </Modal>
);


const MydraTagComponent = ({ course, authToken, API_BASE_URL, isProduction, onSaveSection }) => {
    const [courseTags, setCourseTags] = useState(course?.tags || []);
    const [globalTags, setGlobalTags] = useState([]);
    const [selectedGlobalTag, setSelectedGlobalTag] = useState('');
    const [newGlobalTag, setNewGlobalTag] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [isAddingGlobalTag, setIsAddingGlobalTag] = useState(false);
    const [error, setError] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [infoModalOpen, setInfoModalOpen] = useState(false);

    useEffect(() => {
        setCourseTags(course?.tags || []);
        fetchGlobalTags();
    }, [course]);

    const fetchGlobalTags = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
            });
            if (response.ok) {
                const data = await response.json();
                setGlobalTags(data.map(tag => ({
                    _id: tag._id,
                    name: tag.name,
                    slug: tag.slug
                })));
            } else {
                throw new Error("Failed to fetch global tags");
            }
        } catch (error) {
            console.error("Error fetching global tags:", error);
            setError("Failed to load global tags. Please try again.");
        }
    };

    const createGlobalTag = async (tagName) => {
        setIsAddingGlobalTag(true);
        try {
            const response = await fetch(`${API_BASE_URL}/mydra/tags`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ name: tagName }),
            });
            if (response.ok) {
                const data = await response.json();
                await fetchGlobalTags(); // Refetch all tags
                return data;
            } else {
                throw new Error("Failed to create global tag");
            }
        } catch (error) {
            console.error("Error creating global tag:", error);
            setError("Failed to create global tag. Please try again.");
            throw error;
        } finally {
            setIsAddingGlobalTag(false);
        }
    };

    const updateCourseTags = async (newTags) => {
        setIsSaving(true);
        try {
            const updatePayload = { 
                nonTranslationFields: { 
                    tags: newTags.map(tag => tag.slug) 
                },
                language: 'en' // Assuming 'en' as default language
            };
            await onSaveSection('tags', updatePayload);
            setCourseTags(newTags);
            setError('');
        } catch (error) {
            console.error('Error updating course tags:', error);
            setError('Error updating course tags');
        } finally {
            setIsSaving(false);
            setIsEditing(false);
        }
    };

    const handleAddTagToCourse = () => {
        if (!selectedGlobalTag) return;
        const tagToAdd = globalTags.find(tag => tag._id === selectedGlobalTag);
        if (tagToAdd && !courseTags.some(tag => tag.slug === tagToAdd.slug)) {
            const updatedTags = [...courseTags, tagToAdd];
            const tagSlugs = updatedTags.map(tag => tag.slug);
            onSaveSection('tags', { 
                nonTranslationFields: { 
                    tags: tagSlugs 
                },
                language: 'en' // or whatever the current language is
            });
        }
        setSelectedGlobalTag('');
    };

    const handleDeleteTag = (tagToDelete) => {
        const updatedTags = courseTags.filter((tag) => tag.slug !== tagToDelete.slug);
        updateCourseTags(updatedTags);
    };

    const handleEdit = () => setIsEditing(true);
    const handleCancel = () => {
        setIsEditing(false);
        setCourseTags(course?.nonTranslationFields?.tags || []);
    };

    const handleSave = () => updateCourseTags(courseTags);

    const handleAddNewGlobalTag = async () => {
        if (newGlobalTag.trim() === '') return;
        try {
            await createGlobalTag(newGlobalTag);
            setNewGlobalTag('');
        } catch (error) {
            // Error is already set in createGlobalTag
        }
    };

    const handleOpenInfoModal = () => setInfoModalOpen(true);
    const handleCloseInfoModal = () => setInfoModalOpen(false);

    if (!course) return <Typography>Loading course data...</Typography>;

    return (
        <Card variant="outlined" sx={{ mb: 2 }}>
            <CardHeader
                action={
                    <>
                        <IconButton onClick={handleOpenInfoModal} sx={{ mr: 1, color: 'common.white' }}>
                            <InfoIcon />
                        </IconButton>
                        {isEditing ? (
                            <Box sx={{ display: 'flex', gap: 1 }}>
                                <Button
                                    onClick={handleSave}
                                    startIcon={<SaveIcon />}
                                    variant="contained"
                                    color="primary"
                                    disabled={isSaving}
                                >
                                    Save
                                </Button>
                                <Button
                                    onClick={handleCancel}
                                    startIcon={<CancelIcon />}
                                    variant="outlined"
                                    color="inherit"
                                    disabled={isSaving}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        ) : (
                            <IconButton onClick={handleEdit} sx={{ color: 'common.white' }}>
                                <EditIcon />
                            </IconButton>
                        )}
                    </>
                }
                title="Course Tag Management"
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                    '.MuiCardHeader-title': { color: 'primary.contrastText' }
                }}
            />
            <CardContent>
                <Typography variant="subtitle1" sx={{ mb: 1 }}>Current Course Tags:</Typography>
                <Box sx={{ mb: 2 }}>
                {courseTags.map((tag) => (
                        <Chip 
                            key={tag.slug} 
                            label={tag.name} 
                            onDelete={isEditing ? () => handleDeleteTag(tag) : undefined}
                            sx={{ m: 0.5 }}
                        />
                    ))}
                </Box>

                {isEditing && (
                    <>
                        <Box sx={{ mb: 2 }}>
                            <FormControl sx={{ minWidth: 200, mr: 1 }}>
                                <InputLabel id="global-tag-select-label">Select Global Tag</InputLabel>
                                <Select
                                    labelId="global-tag-select-label"
                                    value={selectedGlobalTag}
                                    onChange={(e) => setSelectedGlobalTag(e.target.value)}
                                    label="Select Global Tag"
                                >
                                    {globalTags.map((tag) => (
                                        <MenuItem key={tag._id} value={tag._id}>{tag.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={handleAddTagToCourse}
                                disabled={!selectedGlobalTag}
                            >
                                Add to Course
                            </Button>
                        </Box>

                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 1 }}>Create New Global Tag:</Typography>
                        <Box sx={{ mb: 2 }}>
                            <TextField
                                label="New Global Tag"
                                variant="outlined"
                                size="small"
                                value={newGlobalTag}
                                onChange={(e) => setNewGlobalTag(e.target.value)}
                                sx={{ width: 200, mr: 1 }}
                            />
                            <Button
                                variant="contained"
                                startIcon={<AddIcon />}
                                onClick={handleAddNewGlobalTag}
                                disabled={isAddingGlobalTag || newGlobalTag.trim() === ''}
                            >
                                {isAddingGlobalTag ? 'Adding...' : 'Create Global Tag'}
                            </Button>
                        </Box>
                    </>
                )}

                {error && <Typography color="error" sx={{ mt: 2 }}>{error}</Typography>}
                {isSaving && <CircularProgress size={24} sx={{ mt: 2 }} />}
            </CardContent>
            <TagManagementInfoModal open={infoModalOpen} handleClose={handleCloseInfoModal} />

        </Card>
    );
};

export default MydraTagComponent;
