import React, {useState, useEffect} from 'react';
import { 
    Container,
    Snackbar, 
    Alert,

} from '@mui/material';

import CourseInformationSection from '../CourseInformationSection/CourseInformationSection';
import CourseCategorySection from '../CourseCategorySection/CourseCategorySection';
import CourseContentDescriptionSection from '../CourseContentDescriptionSection/CourseContentDescriptionSection';
import CourseValidationAgreementSection from '../CourseValidationAgreementSection/CourseValidationAgreementSection';
import CourseEngagementOutcomesSection from '../CourseEngagementOutcomesSection/CourseEngagementOutcomesSection';

import CourseInstructorsSection from '../CourseInstructorsSection/CourseInstructorsSection';
import CourseTestimonialsSection from '../CourseTestimonialsSection/CourseTestimonialsSection';
import CourseAdditionalResourcesSection from '../CourseAdditionalResourcesSection/CourseAdditionalResourcesSection';
import CourseAdministrativeDetailsSection from '../CourseAdministrativeDetailsSection/CourseAdministrativeDetailsSection';
import CourseMotivationSection from '../CourseMotivationSection';
import MydraTagComponent from '../MydraTagComponent/MydraTagComponent';

const MydraCourse = ({ key, course, authToken, userEmail, isProduction, onCourseUpdate, selectedLanguage }) => {

    const courseId = course._id;

    const [editedCourse, setEditedCourse] = useState(course); // Assuming course data is passed as a prop
    const [editingSection, setEditingSection] = useState(null);
    const [updatedCourseData, setUpdatedCourseData] = useState(course);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Ensure this is defined in your environment variables

    // Snackbar
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        // This effect will run whenever the `course` prop changes
        setUpdatedCourseData(course);
    }, [course]); // Dependency array with `course` to listen for its changes

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (!course) return <div>No course data available</div>;

    const handleSectionUpdate = async (section, newSectionData) => {
        setIsSaving(true);
    
        console.log('Received section to update:', section);
        console.log('Received new data for section:', newSectionData);
    
        let payload;
    
        if (section === 'tags') {
            // Handle tag updates
            payload = newSectionData; // This will be { nonTranslationFields: { tags: newTags }, language: 'en' }
        } else if (
            section === 'courseInformation' ||
            section === 'validationAgreement' ||
            section === 'contentDescription' ||
            section === 'motivation' ||
            section === 'engagementOutcomes' ||
            section === 'administrativeDetails' ||
            section === 'additionalResources' ||
            section === 'testimonials' ||
            section === 'categorySelection' ||
            section === 'courseMotivation' ||
            section === 'instructors'
        ) {
            payload = { ...newSectionData };
        } else {
            payload = { [section]: newSectionData };
        }
    
        // Check if the 'category' section is being updated, then include 'categoryId'
        if (section === 'category' && updatedCourseData.category && updatedCourseData.category._id) {
            payload.categoryId = updatedCourseData.category._id;
        }
    
        // Merge the updated section data with the existing course data
        const newCourseData = {
            ...updatedCourseData,
            ...payload,
            nonTranslationFields: {
                ...updatedCourseData.nonTranslationFields,
                ...(payload.nonTranslationFields || {})
            }
        };
        setUpdatedCourseData(newCourseData);
    
        console.log('Updating section:', section, 'with data:', payload);
    
        try {
            console.log('Updating course with id:', courseId);
    
            const response = await fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'Content-Type': 'application/json',
                    'X-User-Email': userEmail,
                    'X-Environment': isProduction ? 'Production' : 'Staging',
                },
                body: JSON.stringify(payload),
            });
    
            if (response.ok) {
                const responseData = await response.json();
                console.log('Section updated successfully:', responseData);
                setEditedCourse(responseData['data']);
                onCourseUpdate(responseData['data']);
    
                setSnackbarMessage('Changes saved successfully!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } else {
                console.error('Failed to update section:', response.statusText);
                setSnackbarMessage('Failed to save changes.');
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            }
        } catch (error) {
            console.error('Error updating section:', error);
            setSnackbarMessage('An error occurred while saving changes.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setIsSaving(false);
            setEditingSection(null);
        }
    };


    const handleEditSection = (section) => {
        setEditingSection(section);
    };

    const isSectionEditable = (section) => {
        return editingSection === section;
    };

    return (
        <Container maxWidth="lg" disableGutters={true}>       
            {/* CourseInformationSection */}
            <CourseInformationSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />    
            {/* CourseCategorySection */}
            <CourseCategorySection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
                apiBaseUrl={API_BASE_URL} // Assuming this is defined somewhere in your parent component
                authToken={authToken} // Pass authToken as a prop
                userEmail={userEmail} // Pass userEmail as a prop
                isProduction={isProduction} // Pass isProduction as a prop
            />
            <CourseValidationAgreementSection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
                authToken={authToken} // Pass authToken as a prop
                userEmail={userEmail} // Pass userEmail as a prop
                isProduction={isProduction} // Pass isProduction as a prop
            />

            <CourseContentDescriptionSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <CourseMotivationSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />  

            <CourseEngagementOutcomesSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <CourseInstructorsSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}                
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
                authToken={authToken} // Pass authToken as a prop
                userEmail={userEmail} // Pass userEmail as a prop
                isProduction={isProduction} // Pass isProduction as a prop
                onCourseUpdate={(isInstructorUpdate) => onCourseUpdate(null, isInstructorUpdate)} // Pass isInstructorUpdate as a parameter                
            />

            <CourseTestimonialsSection 
                course={updatedCourseData}
                selectedLanguage={selectedLanguage}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <CourseAdditionalResourcesSection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            <MydraTagComponent 
                course={updatedCourseData}
                authToken={authToken}
                API_BASE_URL={API_BASE_URL}
                isProduction={isProduction}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
            />

            <CourseAdministrativeDetailsSection 
                course={updatedCourseData}
                isSectionEditable={isSectionEditable}
                onEditSection={handleEditSection}
                onSaveSection={(section, data) => handleSectionUpdate(section, data)}
                isSaving={isSaving} // Pass isSaving as a prop
            />

            {/* Snackb */}
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
    
        </Container>
    );

}

export default MydraCourse;