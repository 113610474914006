
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EducationProviderForm from './components/EducationProviderForm';
import EducationProviderList from './components/EducationProviderList';
import EducationProviderDetails from './components/EducationProviderDetails'; // Ensure this path is correct
import EPScores from './components/EPScores';
import Sidebar from './components/Sidebar';
import CategoriesPage from './components/CategoriesPage/index.js';
import LoginPage from './pages/LoginPage';
import ContactPage from './pages/ContactsPage/ContactsPage.js';
import MydraCategoriesPage from './pages/MydraCategoriesPage/index.js';
import MydraCategoryPage from './pages/MydraCategoryPage/MydraCategoryPage.js';
import MydraCoursePage from './pages/MydraCoursePage/MydraCoursePage.js';
import MydraAddCoursePage from './pages/MydraAddCoursePage/MydraAddCoursePage.js';
import MydraProvidersPage from './pages/MydraProvidersPage/MydraProvidersPage.js';
import MydraProviderPage from './pages/MydraProviderPage/MydraProviderPage.js';
import MydraAgreementsPage from './pages/MydraAgreementsPage/MydraAgreementsPage.js';
import AIUpskillingPage from './pages/AIUpskillingPage/AIUpskillingPage.js';
import MydraNewCoursesPage from './pages/MydraNewCoursesPage/MydraNewCoursesPage.js';
import MydraAnalyticsPage from './pages/MydraAnalyticsPage/MydraAnalyticsPage.js';
import MydraCompaniesPage from './pages/MydraCompaniesPage/MydraCompaniesPage.js';
import MydraUserPaymentsPage from './pages/MydraUserPaymentsPage/MydraUserPaymentsPage.js';
import MydraUserOrdersPage from './pages/MydraUserOrdersPage/MydraUserOrdersPage.js';
import MydraEnquiriesPage from './pages/MydraEnquiriesPage/MydraEnquiriesPage.js';
import MydraUserScoring from './pages/MydraUserScoring/MydraUserScoring.js';
import MydraBenefitProvidersPage from './pages/MydraBenefitProvidersPage/index.js';
import CopilotAnalyticsDashboard from './components_mydra/CopilotAnalyticsDashboard/CopilotAnalyticsDashboard.js';
import CourseAutomationsReporting from './components_mydra/CourseAutomationsReporting/CourseAutomationsReporting.js';
import CourseQAPage from './components_mydra/CourseQAPage/CourseQAPage.js';
import MydraTagsPage from './pages/MydraTagsPage/index.js';

// Course Stream AI
import ProviderList from './course-stream-ai/dashboard/ProviderList.js';  
import ProviderView from './course-stream-ai/provider/ProviderView.js';


import ProtectedRoute from './components/ProtectedRoute';

import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';


// import theme
import { ThemeProvider, Box, CssBaseline, Toolbar } from '@mui/material';
import theme from './themes/theme.js';

// import EducationProviderList from './components/EducationProviderList';
import './styles/App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [open, setOpen] = useState(true);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user);
    });

    return () => unsubscribe();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <Sidebar open={open} handleDrawerToggle={handleDrawerToggle} />
          <Box component="main" sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}>
            <Toolbar /> {/* Provides spacing at the top */}
            <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={EducationProviderForm} />} />
              <Route path="/add-provider" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={EducationProviderForm} />} />
              <Route path="/providers" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={EducationProviderList} />} />
              <Route path="/provider/:site_id" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={EducationProviderDetails} />} />
              <Route path="/ep-scores" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={EPScores} />} />
              {/* <Route path="/categories" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={CategoriesPage} />} /> */}
              <Route path="/mydra/add-course" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraAddCoursePage} />} />
              <Route path="/mydra/course/" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraCoursePage} />} />
              <Route path="/mydra/course/:courseId" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraCoursePage} />} />
              <Route path="/mydra/new-courses" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraNewCoursesPage} />} />
              <Route path="/mydra-categories" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraCategoriesPage} />} />
              <Route path="/mydra/providers" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraProvidersPage} />} />
              <Route path="/mydra/tags" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraTagsPage} />} />
              <Route path="/mydra/agreements" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraAgreementsPage} />} />
              <Route path="/mydra/provider/:providerId/:providerName" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraProviderPage} />} />
              <Route path="/contacts" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={ContactPage} />} />
              <Route path="/ai-upskilling" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={AIUpskillingPage} />} />
              <Route path="/analytics" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraAnalyticsPage} />} /> {/* Add the new route */}
              <Route path="/mydra/category/:categoryId/:categoryName" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraCategoryPage} />} /> {/* Add the new route */}
              <Route path="/mydra/companies" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraCompaniesPage} />} />
              <Route path="/mydra/user-payments" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraUserPaymentsPage} />} />
              <Route path="/mydra/user-orders" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraUserOrdersPage} />} />
              <Route path="/mydra/enquiries" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraEnquiriesPage} />} />
              <Route path="/mydra/user-scoring" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraUserScoring} />} />
              <Route path="/mydra/benefit-providers" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={MydraBenefitProvidersPage} />} />
              <Route path="/mydra/copilot-analytics" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={CopilotAnalyticsDashboard} />} />
              <Route path="/mydra/course-automations-reporting" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={CourseAutomationsReporting} />} />
              <Route path="/mydra/course-qa" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={CourseQAPage} />} />

              {/* Course Stream AI */}
              <Route path="/course-stream-ai/providers" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={ProviderList} />} />
              <Route path="/course-stream-ai/provider/:providerId" element={<ProtectedRoute isAuthenticated={isAuthenticated} element={ProviderView} />} />

              {/* Add other routes as needed */}
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;

