// src/services/providerConnectionService.js

import { getAuth } from 'firebase/auth';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


// services/providerConnectionService.js
export const providerConnectionService = {
    autoConnect: async (providerId) => {
        console.log("autoConnect")
      try {
        const token = await getAuth().currentUser?.getIdToken();
        const response = await fetch(`${API_BASE_URL}/api/coursestream/providers/${providerId}/auto-connect`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
  
        if (!response.ok) {
          throw new Error('Failed to auto-connect provider');
        }
  
        const data = await response.json();
        return data;
      } catch (error) {
        console.error('Auto-connect error:', error);
        throw error;
      }
    }
  };

