// course-stream-ai/hooks/useProviderStatusListener.js

import { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { onSnapshot, doc } from 'firebase/firestore';

export const useProviderStatusListener = (provider) => {
    const [status, setStatus] = useState(provider);
    const [isProcessing, setIsProcessing] = useState(false);

    useEffect(() => {
        const needsListener = () => {
            const statuses = {
                enrichment: {
                    main: provider.status?.enrichment?.status,
                    linkedin: provider.status?.enrichment?.linkedin,
                    google: provider.status?.enrichment?.google,
                    homepage: provider.status?.enrichment?.homepage
                },
                crawling: {
                    status: provider.status?.crawling?.status
                },
                courses: {
                    status: provider.status?.courses?.status
                },
                outreach: {
                    status: provider.status?.outreach?.status
                }
            };

            const hasProcessingStatus = Object.values(statuses).some(statusGroup => 
                Object.values(statusGroup).some(status => 
                    status === 'pending' || status === 'processing'
                )
            );

            const needsScoreUpdate = !provider.score || provider.score === 'N/A';
            const needsCrawlingUpdates = provider.status?.crawling?.status === 'processing';
            const needsMydraConnectionUpdate = !provider.mydra_education_provider?.Production && 
                                             !provider.mydra_education_provider?.Staging;
            
            console.log(`[PROVIDER_STATUS] Status check for ${provider.site_id}:`, {
                hasProcessingStatus,
                needsScoreUpdate,
                needsCrawlingUpdates,
                needsMydraConnectionUpdate,
                currentStatuses: statuses
            });

            return hasProcessingStatus || needsScoreUpdate || 
                   needsCrawlingUpdates || needsMydraConnectionUpdate;
        };

        const documentId = provider.site_id || provider.id;
        console.log(`[PROVIDER_STATUS] Initializing listener check for provider ${documentId}`, {
            provider: provider
        });

        if (!documentId || !needsListener()) {
            console.log(`[PROVIDER_STATUS] No listener needed for provider ${documentId}`);
            setIsProcessing(false);
            return;
        }

        setIsProcessing(true);
        console.log(`[PROVIDER_STATUS] 🚀 Setting up listener for provider ${documentId} in Firestore collection 'sites'`);
        
        let previousData = null;
        
        const unsubscribe = onSnapshot(
            doc(db, 'sites', documentId),
            (snapshot) => {
                if (snapshot.exists()) {
                    const newData = snapshot.data();
                    
                    // Compare with previous data to show what changed
                    const changes = previousData ? compareData(previousData, newData) : { all: 'initial data' };
                    
                    console.log(`[PROVIDER_STATUS] 📥 Received update for provider ${documentId}:`, {
                        changes,
                        fullData: newData
                    });
                    
                    setStatus(prevStatus => {
                        const updatedStatus = {
                            ...prevStatus,
                            status: {
                                ...prevStatus.status,
                                ...newData.status
                            },
                            score: newData.score || prevStatus.score,
                            linkedin_data: {
                                ...prevStatus.linkedin_data,
                                ...newData.linkedin_data
                            },
                            google_reviews: {
                                ...prevStatus.google_reviews,
                                ...newData.google_reviews
                            },
                            education_provider_analysis: {
                                ...prevStatus.education_provider_analysis,
                                ...newData.education_provider_analysis
                            },
                            mydra_education_provider: {
                                ...prevStatus.mydra_education_provider,
                                ...newData.mydra_education_provider
                            },
                            id: prevStatus.id,
                            site_id: prevStatus.site_id
                        };

                        // Log what's being updated in the state
                        console.log(`[PROVIDER_STATUS] 🔄 Updating state for ${documentId}:`, {
                            crawlingStatus: newData.status?.crawling?.status,
                            newCrawlingData: newData.status?.crawling,
                            previousScore: prevStatus.score,
                            newScore: updatedStatus.score,
                            mydraConnections: updatedStatus.mydra_education_provider
                        });

                        if (newData.status?.crawling?.status === 'processing') {
                            updatedStatus.status.crawling = {
                                ...updatedStatus.status.crawling,
                                courses_found: newData.status?.crawling?.courses_found || 0,
                                emails_found: newData.status?.crawling?.emails_found || 0,
                                linkedin_urls_found: newData.status?.crawling?.linkedin_urls_found || 0,
                                pages_crawled: newData.status?.crawling?.pages_crawled || 0
                            };
                        }

                        previousData = newData;
                        return updatedStatus;
                    });
                } else {
                    console.warn(`[PROVIDER_STATUS] ⚠️ No data found for provider ${documentId} in Firestore`);
                }
            },
            (error) => {
                console.error(`[PROVIDER_STATUS] ❌ Error listening to provider ${documentId} updates:`, error);
            }
        );

        // Cleanup function
        return () => {
            console.log(`[PROVIDER_STATUS] 🧹 Cleaning up listener for provider ${documentId}`);
            unsubscribe();
            setIsProcessing(false);
        };
    }, [provider]);

    return { status, isProcessing };
};

// Helper function to compare data changes
const compareData = (prev, next) => {
    const changes = {};
    
    // Compare status changes
    if (prev.status?.crawling?.status !== next.status?.crawling?.status) {
        changes.crawlingStatus = {
            from: prev.status?.crawling?.status,
            to: next.status?.crawling?.status
        };
    }
    
    // Compare crawling metrics
    if (prev.status?.crawling) {
        const crawlingChanges = {};
        ['courses_found', 'emails_found', 'linkedin_urls_found', 'pages_crawled'].forEach(metric => {
            if (prev.status.crawling[metric] !== next.status?.crawling?.[metric]) {
                crawlingChanges[metric] = {
                    from: prev.status.crawling[metric],
                    to: next.status?.crawling?.[metric]
                };
            }
        });
        if (Object.keys(crawlingChanges).length > 0) {
            changes.crawlingMetrics = crawlingChanges;
        }
    }
    
    // Compare score changes
    if (prev.score !== next.score) {
        changes.score = {
            from: prev.score,
            to: next.score
        };
    }

    // Compare Mydra connection changes
    if (prev.mydra_education_provider?.Production !== next.mydra_education_provider?.Production ||
        prev.mydra_education_provider?.Staging !== next.mydra_education_provider?.Staging) {
        changes.mydraConnections = {
            from: {
                Production: prev.mydra_education_provider?.Production,
                Staging: prev.mydra_education_provider?.Staging
            },
            to: {
                Production: next.mydra_education_provider?.Production,
                Staging: next.mydra_education_provider?.Staging
            }
        };
    }
    
    return changes;
};
