import { api } from '../utils/api';
import { getAuth } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
console.log('API_BASE_URL:', API_BASE_URL);
const BASE_URL = `${API_BASE_URL}/api/coursestream/providers`;

export const providerService = {
    listProviders: async (params = {}) => {
        console.log("Calling listProviders")
        try {
            console.log('Listing providers from:', `${BASE_URL}/list`); // Debug log
            const response = await api.get(`${BASE_URL}/list`, params);
            console.log('Provider list response:', response);
            return response;
        } catch (error) {
            console.error('Error listing providers:', error);
            throw error;
        }
    },

    listProvidersPaginated: async (params = {}) => {
        console.log("Calling listProvidersPaginated with params:", params);
        try {
            // Ensure we're sending the correct parameters
            const queryParams = new URLSearchParams({
                page: String(params.page),         // Ensure we're sending as strings
                pageSize: String(params.pageSize),
                sortBy: params.sortBy || 'metadata.created_at',
                sortOrder: params.sortOrder || 'desc'
            });
    
            const url = `${BASE_URL}/list-paginated?${queryParams}`;
            console.log('Requesting URL:', url); // Debug the full URL
    
            const response = await api.get(url);
            console.log('Server response:', response); // Debug the response
            return response;
        } catch (error) {
            console.error('Error listing providers:', error);
            throw error;
        }
    },

    addProvider: async (providerData) => {
        console.log("Calling addProvider with data:", providerData);
        try {
            const response = await api.post(`${BASE_URL}/add`, providerData);
            console.log('Add provider response:', response);
            
            // Return the entire response object as is
            return response;
    
        } catch (error) {
            console.error('Error adding provider:', error);
            throw error;
        }
    },



    getProvider: async (providerId) => {
        console.log("Calling getProvider")
        try {
            const response = await api.get(`${BASE_URL}/${providerId}`);
            console.log('Provider details response:', response);
            return response;
        } catch (error) {
            console.error('Error getting provider:', error);
            throw error;
        }
    },

    updateProvider: async (providerId, providerData) => {
        console.log("Calling updateProvider")
        try {
            return await api.put(`${BASE_URL}/${providerId}`, providerData);
        } catch (error) {
            console.error('Error updating provider:', error);
            throw error;
        }
    },

    getIdentifiedCourses: async (providerId, { offset = 0, limit = 100 } = {}) => {
        try {
            const token = await getAuth().currentUser?.getIdToken();
            
            // Add offset and limit to URL as query parameters
            const url = new URL(`${API_BASE_URL}/api/coursestream/providers/${providerId}/identified-courses`);
            url.searchParams.append('offset', offset);
            url.searchParams.append('limit', limit);
            
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Failed to fetch courses');
            }

            const data = await response.json();
            console.log('Pagination request:', { offset, limit });
            console.log('Pagination response:', data.pagination);
            return data;
        } catch (error) {
            console.error('Error fetching courses:', error);
            throw error;
        }
    },

    uploadCourse: async (providerId, courseData) => {
        try {
            // Create FormData object
            const formData = new FormData();
            formData.append('educationProviderId', providerId);
            
            // Add course URL if provided
            if (courseData.courseUrl) {
                formData.append('courseUrl', courseData.courseUrl);
            }
            
            // Add additional info if provided
            if (courseData.additionalInfo) {
                formData.append('additionalInfo', JSON.stringify(courseData.additionalInfo));
            }
            
            // Add PDF file if provided
            if (courseData.pdfFile) {
                formData.append('pdfFile', courseData.pdfFile);
            }

            const token = await getAuth().currentUser?.getIdToken();
            // const response = await fetch(`${API_BASE_URL}/api/coursestream/mydra/course`, {
            const response = await fetch(`${API_BASE_URL}/mydra/course`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formData
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to upload course');
            }

            const data = await response.json();
            console.log('Upload response:', data);
            return data;
        } catch (error) {
            console.error('Error in uploadCourse:', error);
            throw error;
        }
    },

    // getIdentifiedCourses: async (providerId, params = {}) => {
    //     console.log("Calling getIdentifiedCourses")
    //     try {
    //         const response = await api.get(`${BASE_URL}/${providerId}/identified-courses`, { params });
    //         return response;
    //     } catch (error) {
    //         console.error('Error getting identified courses:', error);
    //         throw error;
    //     }
    // },
};
