import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    OutlinedInput,
    Box,  
    CircularProgress,  
    Typography,
    Paper,
    List,
    ListItem,
    ListItemText,
    Divider,
    IconButton,
    Grid

} from '@mui/material';

import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';


const ProviderInfoForm = ({ providerId, initialData, onSubmit, onCancel }) => {
    const [formData, setFormData] = useState({
        legalName: '',
        address: '',
        vat: '',
        bankName: '',
        educationProviderId: providerId,
        contacts: [],
        contractRepresentativeContact: '',
        ...initialData
    });
    const [isSaving, setIsSaving] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({...prevData, [name]: value}));
    };

    const handleContactChange = (index, field, value) => {
        const updatedContacts = [...formData.contacts];
        updatedContacts[index] = { ...updatedContacts[index], [field]: value };
        setFormData(prevData => ({...prevData, contacts: updatedContacts}));
    };

    const addContact = () => {
        setFormData(prevData => ({
            ...prevData,
            contacts: [...prevData.contacts, { firstName: '', lastName: '', email: '', phone: '', role: '', documentNumber: '' }]
        }));
    };

    const removeContact = (index) => {
        const updatedContacts = formData.contacts.filter((_, i) => i !== index);
        setFormData(prevData => ({...prevData, contacts: updatedContacts}));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSaving(true);
        await onSubmit(formData);
        setIsSaving(false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField
                name="legalName"
                label="Legal Name"
                value={formData?.legalName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                name="address"
                label="Address"
                value={formData?.address}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                name="vat"
                label="VAT"
                value={formData?.vat}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />
            <TextField
                name="bankName"
                label="Bank Name"
                value={formData?.bankName}
                onChange={handleChange}
                fullWidth
                margin="normal"
                required
            />


            <Typography variant="h6" style={{ marginTop: '20px', marginBottom: '10px' }}>Contacts</Typography>
            {formData?.contacts?.map((contact, index) => (
                <Box key={index} mb={2} p={2} border={1} borderColor="grey.300" borderRadius={1}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="First Name"
                                value={contact.firstName}
                                onChange={(e) => handleContactChange(index, 'firstName', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Last Name"
                                value={contact.lastName}
                                onChange={(e) => handleContactChange(index, 'lastName', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Email"
                                value={contact.email}
                                onChange={(e) => handleContactChange(index, 'email', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Phone"
                                value={contact.phone}
                                onChange={(e) => handleContactChange(index, 'phone', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Role"
                                value={contact.role}
                                onChange={(e) => handleContactChange(index, 'role', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Document Number"
                                value={contact.documentNumber}
                                onChange={(e) => handleContactChange(index, 'documentNumber', e.target.value)}
                            />
                        </Grid>
                    </Grid>
                    <IconButton onClick={() => removeContact(index)} color="secondary">
                        <DeleteIcon />
                    </IconButton>
                </Box>
            ))}
            <Button startIcon={<AddIcon />} onClick={addContact} variant="outlined" style={{ marginBottom: '20px' }}>
                Add Contact
            </Button>

            <FormControl fullWidth margin="normal">
                <InputLabel>Contract Representative Contact</InputLabel>
                <Select
                    value={formData.contractRepresentativeContact}
                    onChange={handleChange}
                    name="contractRepresentativeContact"
                >
                    {formData.contacts.map((contact, index) => (
                        <MenuItem key={index} value={contact._id || index}>
                            {`${contact.firstName} ${contact.lastName}`}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <Button type="submit" variant="contained" color="primary" disabled={isSaving}>
                {isSaving ? 'Saving...' : (initialData ? 'Update' : 'Create') + ' Provider Info'}
            </Button>
            {onCancel && (
                <Button onClick={onCancel} variant="outlined" color="secondary" style={{ marginLeft: '8px' }}>
                    Cancel
                </Button>
            )}
        </form>
    );
};


const AddEditProviderModal = ({ open, onClose, onAdd, onUpdate, showSnackbar, authToken, userEmail, environment, initialProvider }) => {
    const [provider, setProvider] = useState(null);
    const [providerData, setProviderData] = useState({
        name: '',
        country: '',
        isAffiliate: false,
        logoUrl: '',
    });
    const [providerInfo, setProviderInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingInfo, setIsLoadingInfo] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const handleonClose = () => {
        setProvider(null);
        setProviderData({
            name: '',
            country: '',
            isAffiliate: false,
            logoUrl: '',
        });
        setProviderInfo(null);
        onClose();
    }

    const fetchProviderInfo = useCallback(async (providerId) => {
        console.log('Fetching provider info for providerId:', providerId);
        try {
            setIsLoadingInfo(true);
            const response = await fetch(`${API_BASE_URL}/mydra/education-provider-infos/${providerId}`, {
                headers: {
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': environment,
                },
            });
            if (response.ok) {
                const data = await response.json();
                console.log('Provider info:', data);
                setProviderInfo(data.data);
            } else if (response.status !== 404) {
                throw new Error('Failed to fetch provider info');
            }
        } catch (error) {
            console.error('Error fetching provider info:', error);
            showSnackbar('Error fetching provider info', 'error');
        } finally {
            setIsLoadingInfo(false);
        }
    }, [API_BASE_URL, authToken, userEmail, environment, showSnackbar]);

    useEffect(() => {
        console.log('Initial provider:', initialProvider);
        if (initialProvider) {
            setProvider(initialProvider);
        }
    }, [initialProvider]);

    useEffect(() => {
        if (initialProvider && initialProvider._id) {
            console.log('Setting provider data:', 
                initialProvider.name,
                initialProvider.country,
                initialProvider.isAffiliate,
                initialProvider.logoUrl,
                initialProvider.about
            );
            setProviderData({
                name: initialProvider.name || '',
                country: initialProvider.country || '',
                isAffiliate: initialProvider.isAffiliate || false,
                logoUrl: initialProvider.logoUrl || '',
                about: initialProvider.about || '',
            });
        } else {
            resetState();
        }
    }, [initialProvider, initialProvider?._id]); // Only run when initialProvider._id changes
    
    useEffect(() => {
        if (provider && provider._id && !providerInfo) {
            fetchProviderInfo(provider._id);
        }
    }, [provider, fetchProviderInfo, providerInfo]);


    const handleInputChange = (event) => {
        const { name, value, checked } = event.target;
        setProviderData(prevData => ({
            ...prevData,
            [name]: name === 'isAffiliate' ? checked : value
        }));
    };

    const resetState = () => {
        // setProvider(null);
        setProviderInfo(null);
        setIsEditing(false);
    };

    const handleAddProvider = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${API_BASE_URL}/mydra/education-providers`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': environment,
                },
                body: JSON.stringify(providerData),
            });

            if (response.ok) {
                const newProvider = await response.json();
                onAdd(newProvider.provider);
                showSnackbar('Provider added successfully', 'success');
                handleonClose();
            } else {
                throw new Error('Failed to add provider');
            }
        } catch (error) {
            console.error('Error adding provider:', error);
            showSnackbar(error.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };

    const handleUpdateProviderDetails = async () => {
        if (!provider || !provider._id) {
            showSnackbar('No provider to update', 'error');
            return;
        }
        console.log('Updating provider details:', providerData);

        try {
            setIsLoading(true);
            const response = await fetch(`${API_BASE_URL}/mydra/education-providers/${provider._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': environment,
                },
                body: JSON.stringify(providerData),
            });
    
            if (response.ok) {
                const updatedProvider = await response.json();
                console.log('Updated provider:', updatedProvider.provider);
                // Update both provider and providerData states
                setProvider(updatedProvider.provider);
                setProviderData({
                    name: updatedProvider.provider.name,
                    country: updatedProvider.provider.country,
                    isAffiliate: updatedProvider.provider.isAffiliate,
                    logoUrl: updatedProvider.provider.logoUrl,
                });
                onUpdate(updatedProvider.provider);
                showSnackbar('Provider details updated successfully', 'success');
            } else {
                throw new Error('Failed to update provider details');
            }
        } catch (error) {
            console.error('Error updating provider details:', error);
            showSnackbar(error.message, 'error');
        } finally {
            setIsLoading(false);
        }
    };


    const handleUpdateProviderInfo = async (infoData) => {
        if (!provider || !provider._id) {
            showSnackbar('No provider selected', 'error');
            return;
        }
        console.log('Updating provider info:', infoData);

         // Create a cleaned version of the data to send
         const cleanedInfoData = {
            legalName: infoData.legalName,
            address: infoData.address,
            vat: infoData.vat,
            bankName: infoData.bankName,
            educationProviderId: provider._id,
            contacts: infoData.contacts.map(contact => ({
                _id: contact._id, // Include the _id if it exists
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email,
                phone: contact.phone,
                role: contact.role,
                documentNumber: contact.documentNumber
            })),
            contractRepresentativeContact: infoData.contacts.find(
                contact => contact._id === infoData.contractRepresentativeContact || 
                           contact.tempId === infoData.contractRepresentativeContact
            )?._id || null
        };
        console.log('Cleaned info data:', cleanedInfoData); 
        
        try {
            setIsLoadingInfo(true);
            const url = providerInfo && providerInfo._id
                ? `${API_BASE_URL}/mydra/education-provider-infos/${providerInfo._id}`
                : `${API_BASE_URL}/mydra/education-provider-infos`;
            const method = providerInfo && providerInfo._id ? 'PUT' : 'POST';

            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                    'X-User-Email': userEmail,
                    'X-Environment': environment,
                },
                body: JSON.stringify(cleanedInfoData),
            });

            if (response.ok) {
                const updatedInfo = await response.json();
                setProviderInfo(updatedInfo.data);
                console.log('Updated provider info:', updatedInfo.data);
                showSnackbar('Provider info updated successfully', 'success');
                setIsEditing(false);
            } else {
                throw new Error('Failed to update provider info');
            }
        } catch (error) {
            console.error('Error updating provider info:', error);
            showSnackbar(error.message, 'error');
        } finally {
            setIsLoadingInfo(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleonClose} fullWidth maxWidth="md">
            <DialogTitle>{provider ? 'Edit Provider' : 'Add New Provider'}</DialogTitle>
            <DialogContent>
                <Box mb={3}>
                    <Typography variant="h6" gutterBottom>Provider Details</Typography>
                    <TextField
                        name="name"
                        label="Provider Name"
                        value={providerData.name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    <FormControl fullWidth margin="normal">
                        <InputLabel>Provider Country</InputLabel>
                        <Select
                            name="country"
                            value={providerData.country}
                            onChange={handleInputChange}
                            input={<OutlinedInput label="Provider Country" />}
                        >
                            <MenuItem value="">Select Country</MenuItem>
                            <MenuItem value="es">Spain</MenuItem>
                            <MenuItem value="uk">United Kingdom</MenuItem>
                            <MenuItem value="pt">Portugal</MenuItem>
                            <MenuItem value="de">Germany</MenuItem>
                            <MenuItem value="it">Italy</MenuItem>
                            <MenuItem value="nl">Netherlands</MenuItem>
                            <MenuItem value="usa">United States of America</MenuItem>
                            <MenuItem value="online">Online</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={
                            <Switch
                                name="isAffiliate"
                                checked={providerData.isAffiliate}
                                onChange={handleInputChange}
                            />
                        }
                        label="Is Affiliate"
                    />

                    <TextField
                        name="about"
                        label="About"
                        value={providerData?.about || ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                    />


                    <TextField
                        name="logoUrl"
                        label="Logo URL"
                        value={providerData.logoUrl}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                    />
                    {providerData.logoUrl && (
                        <Box mt={1}>
                            <img src={providerData.logoUrl} alt="Logo Preview" style={{ maxWidth: '100%', maxHeight: '80px' }} />
                        </Box>
                    )}
                    <Button 
                        onClick={provider ? handleUpdateProviderDetails : handleAddProvider} 
                        variant="contained" 
                        color="primary"
                        disabled={isLoading}
                        style={{ marginTop: '16px' }}
                    >
                        {isLoading ? <CircularProgress size={24} /> : (provider ? 'Update Provider Details' : 'Add Provider')}
                    </Button>
                </Box>

                {provider && (
                    <>
                        <Divider style={{ margin: '24px 0' }} />
                        <Box>
                            <Typography variant="h6" gutterBottom>Provider Info</Typography>
                            {isLoadingInfo ? (
                                <Box display="flex" justifyContent="center" my={4}>
                                    <CircularProgress />
                                </Box>
                                ) : isEditing || !providerInfo ? (
                                    <ProviderInfoForm
                                        providerId={provider._id}
                                        initialData={providerInfo || {}}
                                        onSubmit={handleUpdateProviderInfo}
                                        onCancel={() => {
                                            setIsEditing(false);
                                            if (!providerInfo) {
                                                setProviderInfo(null);
                                            }
                                        }}
                                    />
                                ) : (
                                    <Paper elevation={3} style={{ padding: '16px', marginTop: '16px' }}>
                                        <List>
                                            <ListItem>
                                                <ListItemText primary="Legal Name" secondary={providerInfo.legalName} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem>
                                                <ListItemText primary="Address" secondary={providerInfo.address} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem>
                                                <ListItemText primary="VAT" secondary={providerInfo.vat} />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem>
                                                <ListItemText primary="Bank Name" secondary={providerInfo.bankName} />
                                            </ListItem>
                                        
                                            <Divider component="li" />
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Contacts" 
                                                    secondary={
                                                        <List>
                                                            {providerInfo.contacts.map((contact, index) => (
                                                                <ListItem key={index}>
                                                                    <ListItemText
                                                                        primary={`${contact.firstName} ${contact.lastName}`}
                                                                        secondary={
                                                                            <>
                                                                                <Typography component="span" variant="body2">
                                                                                    Email: {contact.email}<br />
                                                                                    Phone: {contact.phone}<br />
                                                                                    Role: {contact.role}<br />
                                                                                    Document Number: {contact.documentNumber}
                                                                                </Typography>
                                                                            </>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    }
                                                />
                                            </ListItem>
                                            <Divider component="li" />
                                            <ListItem>
                                                <ListItemText 
                                                    primary="Contract Representative Contact" 
                                                    secondary={
                                                        providerInfo.contacts.find(contact => contact._id === providerInfo.contractRepresentativeContact)
                                                            ? `${providerInfo.contacts.find(contact => contact._id === providerInfo.contractRepresentativeContact).firstName} ${providerInfo.contacts.find(contact => contact._id === providerInfo.contractRepresentativeContact).lastName}`
                                                            : 'Not set'
                                                    }
                                                />
                                            </ListItem>
                                        </List>
                                

                                        <Button 
                                            onClick={() => setIsEditing(true)} 
                                            variant="contained" 
                                            color="primary" 
                                            style={{ marginTop: '16px' }}
                                        >
                                            Edit Provider Info
                                        </Button>
                                    </Paper>
                                )}
                        </Box>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleonClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditProviderModal;

