import React, { useState, useEffect} from 'react';
import { Card, CardContent, Typography, Button, Box, CardHeader, IconButton, FormControl, InputLabel, Select, MenuItem, CircularProgress } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'; // Icon for Validation Type
import ArticleIcon from '@mui/icons-material/Article'; // Icon for Agreement Name

const validationTypes = ['skills', 'capacity', 'admission-process', 'none'];


const CourseValidationAgreementSection = ({ course, isSectionEditable, onEditSection, onSaveSection, isSaving, authToken, userEmail, isProduction }) => {
    const [originalValidationType, setOriginalValidationType] = useState(course.validationType || 'none');
    const [originalAgreementName, setOriginalAgreementName] = useState(course.agreements[0]?.name || '');
    
    const [editableValidationType, setEditableValidationType] = useState(course.validationType || 'none');
    const [editableAgreementName, setEditableAgreementName] = useState(course.agreements[0]?.name || '');

    const [agreements, setAgreements] = useState([]);

    const providerId = course.educationProvider._id;
    const editable = isSectionEditable('validationAgreement');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (editable) {
            const fetchAgreements = async () => {
                try {

                    const response = await fetch(`${apiBaseUrl}/mydra/education-providers/${providerId}/agreements`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${authToken}`,
                            'Content-Type': 'application/json',
                            'X-Environment': isProduction ? 'Production' : 'Staging',
                            'X-User-Email': userEmail,
                        }
                    });
                    if (response.ok) {
                        const data = await response.json();
                        // console.log('Agreements:', data);
                        setAgreements(data.data|| []);
                        // console.log('Agreements:', agreements)
                    } else {
                        throw new Error('Failed to fetch agreements');
                    }
                } catch (error) {
                    console.error('Failed to fetch agreements', error);
                }
            };
            fetchAgreements();
        }
    }, [editable, apiBaseUrl, providerId, authToken]);


    useEffect(() => {
        setOriginalValidationType(course.validationType || 'none');
        setOriginalAgreementName(course.agreementName || '');
        setEditableValidationType(course.validationType || 'none');
        setEditableAgreementName(course.agreementName || '');
    }, [course]);

    const handleCancel = () => {
        setEditableValidationType(originalValidationType);
        setEditableAgreementName(originalAgreementName);
        onEditSection(null);
    };

    const handleSave = async () => {
        const nonTranslationFields = {
            validationType: editableValidationType === 'none' ? null : editableValidationType,
            agreementIds: editableValidationType === 'none' ? [] : [editableAgreementName]
        };

        const payload = {
            nonTranslationFields,
        };

        onSaveSection('validationAgreement', payload);
    }
    
    // const handleSave = async () => {
    //     const nonTranslationFields = {
    //         validationType: editableValidationType,
    //         agreementIds : [editableAgreementName]
    //     };

    //     const payload = {
    //         nonTranslationFields,
    //     };

    //     onSaveSection('validationAgreement', payload);

    // }

        
    
    return (
        <Card 
            variant="outlined" 
            sx={{ mb: 2 }}
        >
            <CardHeader
                action={
                    editable ? (
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSave}
                                disabled={isSaving}
                            >
                                {isSaving ? 'Saving...' : 'Save'}
                            </Button>
                            <Button
                                variant="outlined"
                                sx={{
                                    color: 'common.white',
                                    borderColor: 'common.white',
                                    '&:hover': {
                                        backgroundColor: 'rgba(255, 255, 255, 0.1)',
                                        borderColor: 'common.white',
                                    },
                                }}
                                onClick={handleCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>
                        </Box>
                    ) : (
                        <IconButton onClick={() => onEditSection('validationAgreement')} sx={{ color: 'common.white' }}>
                            <EditIcon />
                        </IconButton>
                    )
                }
                titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
                title="Validation and Agreement"
                sx={{
                    backgroundColor: 'primary.light',
                    color: 'primary.contrastText',
                    '.MuiCardHeader-action': { m: 'auto 0' },
                    '.MuiCardHeader-title': { color: 'primary.contrastText' }
                }}
            />
            <CardContent>
                {editable ? (
                    <>
                        <FormControl fullWidth margin="normal">
                            <InputLabel>Validation Type</InputLabel>
                            <Select
                                value={editableValidationType}
                                // onChange={(e) => setEditableValidationType(e.target.value)}
                                onChange={(e) => {
                                    setEditableValidationType(e.target.value);
                                    if (e.target.value === 'none') {
                                        setEditableAgreementName('');
                                    }
                                }}
                                label="Validation Type"
                            >
                                {validationTypes.map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type === 'none' ? 'No Validation' : type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Agreement</InputLabel>
                                <Select
                                    value={editableAgreementName}
                                    onChange={(e) => setEditableAgreementName(e.target.value)}
                                    label="Agreement Name"
                                >
                                    {agreements.map((agreement) => (
                                        <MenuItem key={agreement._id} value={agreement._id}>{agreement?.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                 
                    
                    </>
                ) : (
                    <>
                        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                            <CheckCircleOutlineIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Validation Type:
                        </Typography>
                        <Typography variant="body1" paragraph>{course.validationType || 'No Validation'}</Typography>
                        
                        <Typography variant="subtitle1" gutterBottom sx={{ display: 'flex', alignItems: 'center' }}>
                            <ArticleIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Agreement:
                        </Typography>
                        <Typography variant="body1" paragraph>{course.agreements[0]?.name || 'Not available'}</Typography>
                    
                    </>
                    )}
                        
            </CardContent>
        </Card>
    );
};

export default CourseValidationAgreementSection;
