import React, { useState, useEffect, useCallback } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress, 
  Grid, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Link,
  Chip
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, subDays } from 'date-fns';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const RecentOperationsTable = ({ recentOperations }) => {
  return (
    <Paper elevation={3} sx={{ mb: 4 }}>
      <Box sx={{ p: 2, backgroundColor: 'primary.light', color: 'primary.contrastText' }}>
        <Typography variant="h6" fontWeight="bold">
          Recent Operations
        </Typography>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: 'action.hover' }}>
              <TableCell sx={{ fontWeight: 'bold', width: '5%' }}>#</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '8%' }}>Date</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '8%' }}>Environment</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '8%' }}>Entity Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '10%' }}>Entity ID</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '8%' }}>Operation Type</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '8%' }}>Status</TableCell>
              <TableCell sx={{ fontWeight: 'bold', width: '25%' }}>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {recentOperations.map((op, index) => (
              <TableRow key={index} sx={{ '&:nth-of-type(odd)': { backgroundColor: 'action.hover' } }}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{format(new Date(op.timestamp), 'yyyy-MM-dd HH:mm:ss')}</TableCell>
                <TableCell>
                  <Chip
                    label={op?.mydra_env}
                    size="small"
                    color={op?.mydra_env === 'Production' ? 'primary' : 'secondary'}
                  />
                </TableCell>
                <TableCell>{op?.entity_type}</TableCell>
                <TableCell>
                  {op?.entity_type === 'course' ? (
                    <Link
                      href={`https://www.mydrahub.com/mydra/course/${op?.entity_id}${op?.mydra_env === 'Staging' ? '?env=Staging' : ''}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {op?.entity_id}
                    </Link>
                  ) : (
                    op?.entity_id
                  )}
                </TableCell>
                <TableCell>{op?.operation_type}</TableCell>
                <TableCell>
                  <Chip
                    label={op?.status}
                    size="small"
                    color={op?.status === 'success' ? 'success' : 'error'}
                  />
                </TableCell>
                <TableCell>
                  <Typography 
                    variant="body2" 
                    // sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                    sx={{ 
                      maxWidth: '100%', 
                      wordBreak: 'break-word',
                      whiteSpace: 'normal'
                    }}
                    >
                    {op?.status === 'failed'
                      ? (op?.error_message || 'N/A')
                      : (typeof op?.details === 'object' ? JSON.stringify(op.details) : (op?.details || 'N/A'))}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};




const CourseAutomationsReporting = () => {
  const [authToken, setAuthToken] = useState(null);

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [dateRange, setDateRange] = useState({
    start: subDays(new Date(), 7),
    end: new Date()
  });

  const fetchReportData = useCallback(async (token) => {
    console.log('Fetching report data...');
    if (!token) {
      console.error('No auth token available');
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/mydra/course-automations/report`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          start_date: format(dateRange.start, 'yyyy-MM-dd'),
          end_date: format(dateRange.end, 'yyyy-MM-dd')
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('Report data:', data);  
      setReportData(data);
    } catch (error) {
      console.error('Error fetching report data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [dateRange]);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setAuthToken(token);
          fetchReportData(token);
        });
        console.log('User logged in');
      } else {
        console.log('User not logged in');
        setAuthToken(null);
        setReportData(null);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [fetchReportData]);

  useEffect(() => {
    if (authToken) {
      fetchReportData(authToken);
    }
  }, [authToken, dateRange, fetchReportData]);

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!authToken) {
    return (
      <Typography variant="h6" align="center">
        Please log in to view the report.
      </Typography>
    );
  }

  if (!reportData) {
    return (
      <Typography variant="h6" align="center">
        No report data available.
      </Typography>
    );
  }


  const { dailyStats, overallStats, recentOperations } = reportData;

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" component="h1" gutterBottom>
        Course Automated Cohort Updates Reporting
      </Typography>
      
      <Box mb={4}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container spacing={2}>
            <Grid item>
              <DatePicker
                label="Start Date"
                value={dateRange.start}
                onChange={(newValue) => setDateRange(prev => ({ ...prev, start: newValue }))}
              />
            </Grid>
            <Grid item>
              <DatePicker
                label="End Date"
                value={dateRange.end}
                onChange={(newValue) => setDateRange(prev => ({ ...prev, end: newValue }))}
              />
            </Grid>
          </Grid>
        </LocalizationProvider>
      </Box>

      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">Total Operations</Typography>
            <Typography variant="h4">{overallStats.totalOperations}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">Successful Operations</Typography>
            <Typography variant="h4">{overallStats.successfulOperations}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">Failed Operations</Typography>
            <Typography variant="h4">{overallStats.failedOperations}</Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
            <Typography variant="h6">Success Rate</Typography>
            <Typography variant="h4">{overallStats.successRate}%</Typography>
          </Paper>
        </Grid>
      </Grid>

      <Paper elevation={3} sx={{ p: 2, mb: 4 }}>
        <Typography variant="h5" gutterBottom>Daily Operations</Typography>
        <Box height={300}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={dailyStats}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="successfulOperations" fill="#4CAF50" name="Successful" />
              <Bar dataKey="failedOperations" fill="#F44336" name="Failed" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </Paper>

      <RecentOperationsTable recentOperations={recentOperations} />


      
    </Container>
  );
};

export default CourseAutomationsReporting;
