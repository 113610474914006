import React, { useState, useEffect, useMemo } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Typography, 
    Paper, CircularProgress, Container,
    Grid, Card, CardContent,
    Tabs, Tab, Fade } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ChartComponent from '../../components_mydra/ChartComponent';
import TableComponent from '../../components_mydra/TableComponent';



const fetchData = async (metric, authToken, userEmail, API_BASE_URL) => {
  // console log the metric to see what data is being fetched and the api endpoint
  console.log('Fetching data for metric:', metric);
  console.log('API_BASE_URL:', API_BASE_URL);
  try {
    const response = await fetch(`${API_BASE_URL}/specific-analytics/${metric}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
        'X-User-Email': userEmail,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to fetch analytics data');
    }

    return await response.json();
  } catch (error) {
    console.error(`Error fetching ${metric} data:`, error);
    return [];
  }
};


const AnalyticsSection = ({ title, chart, table }) => {
  const [expanded, setExpanded] = useState(true);

  return (
    <Paper sx={{ p: 2, mb: 4 }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h5">{title}</Typography>
        <Box 
          onClick={() => setExpanded(!expanded)} 
          sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
        >
          <Typography variant="button" mr={1}>
            {expanded ? 'Collapse' : 'Expand'}
          </Typography>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Box>
      </Box>
      {expanded && (
        <>
          <Box mb={4}>{chart}</Box>
          {table}
        </>
      )}
    </Paper>
  );
};


const MydraAnalyticsPage = () => {
  const [authToken, setAuthToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null);
  const [coursesByProvider, setCoursesByProvider] = useState([]);
  const [totalCourses, setTotalCourses] = useState({});
  const [coursesByCategory, setCoursesByCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [providerSortConfig, setProviderSortConfig] = useState({ key: 'total', direction: 'desc' });
  const [categorySortConfig, setCategorySortConfig] = useState({ key: 'total', direction: 'desc' });
  const [activeTab, setActiveTab] = useState(0);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL; // Ensure this is defined in your environment variables

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setAuthToken(token);
          setUserEmail(user.email);
        });
      } else {
        console.log('User not logged in');
      }
    });
  }, []);
  
  console.log("totalCourses", totalCourses);

  useEffect(() => {
        const fetchAllData = async () => {
        if (!authToken) return;
    
        setIsLoading(true);
    
        const coursesByProviderData = await fetchData('AdminTotalCoursesByProvider', authToken, userEmail, API_BASE_URL);
        const totalCoursesData = await fetchData('AdminTotalCourses', authToken, userEmail, API_BASE_URL);
        const coursesByCategoryData = await fetchData('AdminTotalCoursesByCategory', authToken, userEmail, API_BASE_URL);
    
        const sortDataWithAgreements = (data) => {
            return data.map(item => ({
            ...item,
            total: (item.published || 0) + (item.unpublished || 0),
            publishedWithAgreements: item.publishedWithAgreements || 0, // Keep the original value
            publishedWithoutAgreement: (item.published || 0) - (item.publishedWithAgreements || 0), 
            unpublishedWithAgreements: item.unpublishedWithAgreements || 0, // Keep the original value
            })).sort((a, b) => b.total - a.total);
        };
    
        const processedProviders = sortDataWithAgreements(coursesByProviderData);
        const processedCategories = sortDataWithAgreements(coursesByCategoryData);
    
        // Calculate the total publishedWithAgreements
        const totalPublishedWithAgreements = processedProviders.reduce(
            (sum, provider) => sum + (provider.publishedWithAgreements || 0),
            0
        );

        const totalUnpublishedWithAgreements = processedCategories.reduce(
            (sum, category) => sum + (category.unpublishedWithAgreements || 0),
            0
        );
    
        console.log('Processed coursesByProvider:', processedProviders);
        console.log('Processed coursesByCategory:', processedCategories);
        console.log('Processed totalCourses:', {
            published: totalCoursesData.published || 0,
            unpublished: totalCoursesData.unpublished || 0,
            publishedWithAgreements: totalPublishedWithAgreements || 0,
            publishedWithoutAgreements: (totalCoursesData.published || 0) - (totalCoursesData.publishedWithAgreements || 0),
            unpublishedWithAgreements: totalUnpublishedWithAgreements || 0,
        });
    
        setCoursesByProvider(processedProviders);
        setCoursesByCategory(processedCategories);
        setTotalCourses({
            published: totalCoursesData.published || 0,
            unpublished: totalCoursesData.unpublished || 0,
            publishedWithAgreements: totalPublishedWithAgreements,
            publishedWithoutAgreements: (totalCoursesData.published || 0) - (totalCoursesData.publishedWithAgreements || 0),
            unpublishedWithAgreements: totalUnpublishedWithAgreements
        });
    
        setIsLoading(false);
        };
    
        fetchAllData();
  }, [authToken, userEmail]);

  const sortData = (data, sortConfig) => {
    return [...data].sort((a, b) => {
      if (sortConfig.key === 'total') {
        const aValue = a.published + a.unpublished;
        const bValue = b.published + b.unpublished;
        return sortConfig.direction === 'asc' ? aValue - bValue : bValue - aValue;
      }
      return sortConfig.direction === 'asc' ? a[sortConfig.key] - b[sortConfig.key] : b[sortConfig.key] - a[sortConfig.key];
    });
  };

  const handleProviderSort = (key) => {
    setProviderSortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'desc' ? 'asc' : 'desc',
    }));
  };

  const handleCategorySort = (key) => {
    setCategorySortConfig((prevConfig) => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'desc' ? 'asc' : 'desc',
    }));
  };

  useEffect(() => {
    setCoursesByProvider((prevData) => sortData(prevData, providerSortConfig));
  }, [providerSortConfig]);

  useEffect(() => {
    setCoursesByCategory((prevData) => sortData(prevData, categorySortConfig));
  }, [categorySortConfig]);

  const providerColumns = [
    { key: 'index', label: '#' },
    { key: 'providerName', label: 'Provider Name' },
    { key: 'total', label: 'Total Courses', sortable: true, align: 'right' },
    { 
      key: 'published', 
      label: 'Published Courses (% w/agreements)', 
      sortable: true, 
      align: 'right', 
      icon: true, 
      iconColor: 'green'
    },
    { key: 'unpublished', label: 'Unpublished Courses', sortable: true, align: 'right', icon: true, iconColor: 'red' },
  ];

  const categoryColumns = [
    { key: 'index', label: '#' },
    { key: 'categoryName', label: 'Category Name' },
    { key: 'total', label: 'Total Courses', sortable: true, align: 'right' },
    { 
        key: 'published', 
        label: 'Published Courses', 
        sortable: true, 
        align: 'right', 
        icon: true, 
        iconColor: 'green' 
    },
    { 
        key: 'unpublished', 
        label: 'Unpublished Courses', sortable: true, align: 'right', icon: true, iconColor: 'red' },
  ];

  // Function to sort and limit data for the chart
  const getTopProviders = (data, sortKey, limit) => {
    return [...data]
      .sort((a, b) => (b[sortKey] || 0) - (a[sortKey] || 0))
      .slice(0, limit);
  };

  // Memoize the top providers to avoid unnecessary re-computation
  const topProviders = useMemo(() => {
    return getTopProviders(coursesByProvider, providerSortConfig.key, 30);
  }, [coursesByProvider, providerSortConfig.key]);

  const renderCellContent = (column, row) => {
    if (column.format) {
      return column.format(row[column.key], row);
    }
    return row[column.key];
  };




  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>       
      <Typography 
                variant="h4" 
                align="center"
                sx={{
                    my: 4,
                    fontWeight: 'bold',
                    color: 'primary.main',
                }}
            >
                Analytics Data
            </Typography>
      {isLoading ? (
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      ) : (
        <>

            <Container maxWidth="md">
            <Grid container spacing={2} sx={{ mb: 4 }}>
                <Grid item xs={12} md={4}>
                <Card>
                    <CardContent>
                    <Typography variant="h3" component="div" align="center">
                        {(totalCourses.published || 0) + (totalCourses.unpublished || 0)}
                    </Typography>
                    <Typography variant="subtitle1" align="center">
                        Total Courses
                    </Typography>
                    <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                        {totalCourses.publishedWithAgreements + totalCourses.unpublishedWithAgreements} with agreements
                    </Typography>
                    <Typography variant="body2" align="center">
                        ({(((totalCourses.publishedWithAgreements + totalCourses.unpublishedWithAgreements) / 
                        ((totalCourses.published || 0) + (totalCourses.unpublished || 0))) * 100).toFixed(1)}%)
                    </Typography>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                <Card>
                    <CardContent>
                    <Typography variant="h3" component="div" align="center">
                        {totalCourses.published}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <FiberManualRecordIcon sx={{ color: 'green', mr: 1 }} />
                        <Typography variant="subtitle1" align="center">
                        Published Courses
                        </Typography>
                    </Box>
                    <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                        {totalCourses.publishedWithAgreements} with agreements
                    </Typography>
                    <Typography variant="body2" align="center">
                        ({((totalCourses.publishedWithAgreements / totalCourses.published) * 100).toFixed(1)}%)
                    </Typography>
                    </CardContent>
                </Card>
                </Grid>
                <Grid item xs={12} md={4}>
                <Card>
                    <CardContent>
                    <Typography variant="h3" component="div" align="center">
                        {totalCourses.unpublished}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="center">
                        <FiberManualRecordIcon sx={{ color: 'red', mr: 1 }} />
                        <Typography variant="subtitle1" align="center">
                        Unpublished Courses
                        </Typography>
                    </Box>
                    <Typography variant="body2" align="center" sx={{ mt: 1 }}>
                        {totalCourses.unpublishedWithAgreements} with agreements
                    </Typography>
                    <Typography variant="body2" align="center">
                        ({((totalCourses.unpublishedWithAgreements / totalCourses.unpublished) * 100).toFixed(1)}%)
                    </Typography>
                    </CardContent>
                </Card>
                </Grid>
            </Grid>
            </Container>

            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} sx={{ mb: 2 }}>
              <Tab label="Courses by Provider" />
              <Tab label="Courses by Category" />
            </Tabs>

            <Box sx={{ position: 'relative', minHeight: '500px' }}> {/* Set a minimum height to prevent layout shift */}
              <Fade in={activeTab === 0} timeout={1500}>
                <Box sx={{ position: 'absolute', width: '100%', display: activeTab === 0 ? 'block' : 'none' }}>
                  <AnalyticsSection
                    title="Courses by Provider"
                    chart={
                      <ChartComponent
                        data={topProviders}
                        dataKey="providerName"
                        xAxisKey="providerName"
                        barColors={{ 
                            publishedWithAgreements: "rgba(0, 128, 0, 0.6)", 
                            publishedWithoutAgreement: "rgba(255, 140, 0, 0.6)",
                            unpublished: "rgba(255, 0, 0, 0.6)" 
                          }}
                        // barColors={{ published: "rgba(0, 128, 0, 0.6)", unpublished: "rgba(255, 0, 0, 0.6)" }}
                      />
                    }
                    table={
                      <TableComponent
                        data={coursesByProvider.map((provider, index) => ({ ...provider, index: index + 1 }))}
                        columns={providerColumns}
                        sortConfig={providerSortConfig}
                        onSort={handleProviderSort}
                        linkPrefix="https://www.mydrahub.com/mydra/provider"
                        isProvider={true}
                        showAgreements={true}
                      />
                    }
                  />
                </Box>
              </Fade>
              
              <Fade in={activeTab === 1} timeout={500}>
                <Box sx={{ position: 'absolute', width: '100%', display: activeTab === 1 ? 'block' : 'none' }}>
                  <AnalyticsSection
                    title="Courses by Category"
                    chart={
                      <ChartComponent
                        data={coursesByCategory}
                        dataKey="categoryName"
                        xAxisKey="categoryName"
                        barColors={{ 
                            publishedWithAgreements: "rgba(0, 128, 0, 0.6)", 
                            publishedWithoutAgreement: "rgba(255, 140, 0, 0.6)",
                            unpublished: "rgba(255, 0, 0, 0.6)" 
                          }}
                      />
                    }
                    table={
                      <TableComponent
                        data={coursesByCategory.map((category, index) => ({ ...category, index: index + 1 }))}
                        columns={categoryColumns}
                        sortConfig={categorySortConfig}
                        onSort={handleCategorySort}
                        linkPrefix="/mydra/category"
                        isProvider={false}
                        showAgreements={true}
                      />
                    }
                  />
                </Box>
              </Fade>
            </Box>

        </>
      )}
    </Container>
  );
};

export default MydraAnalyticsPage;