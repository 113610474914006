import { useState, useEffect } from 'react';
import { 
  Grid, 
  Paper, 
  Typography, 
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  CircularProgress,
  Switch,
  Chip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  Link
} from '@mui/material';
import {
  CheckCircle as CheckCircleIcon,
} from '@mui/icons-material';
import LocationIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import SchoolIcon from '@mui/icons-material/School';
import CloseIcon from '@mui/icons-material/Close';  
import IconButton from '@mui/material/IconButton';
import { 
    SearchOutlined as SearchIcon,
    SchoolOutlined as CourseIcon,
    PeopleOutline as ContactsIcon,
    EmailOutlined as MessageIcon
  } from '@mui/icons-material';


import { getFirestore, doc, updateDoc, getDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { providerConnectionService } from '../../services/providerConnectionService';
import { alpha } from '@mui/material/styles';

const StatusChip = ({ status, label }) => {
    const getColor = () => {
      switch (status) {
        case true:
        case 'yes':
        case 'active':
        case 'completed':
          return 'success';
        case false:
        case 'no':
        case 'inactive':
        case 'pending':
          return 'default';
        default:
          return 'default';
      }
    };
  
    return (
      <Chip 
        label={label || (status ? 'Yes' : 'No')} 
        color={getColor()} 
        size="small" 
        variant="outlined"
      />
    );
  };


  const Section = ({ title, children, action }) => (
    <Paper 
      elevation={0}
      sx={{ 
        p: 4,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        background: 'white',
        height: '100%'
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 3
      }}>
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600,
            color: 'text.primary',
            fontSize: '1.25rem'
          }}
        >
          {title}
        </Typography>
        {action}
      </Box>
      {children}
    </Paper>
  );
  
  const InfoItem = ({ icon: Icon, label, value, href }) => {
    const content = (
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-start', mb: 2 }}>
        <Icon 
          sx={{ 
            color: 'primary.main',
            opacity: 0.8,
            fontSize: '1.25rem',
            mt: 0.5
          }} 
        />
        <Box>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ mb: 0.5 }}
          >
            {label}
          </Typography>
          <Typography variant="body1">
            {value}
          </Typography>
        </Box>
      </Box>
    );
  
    return href ? (
      <Link href={href} target="_blank" rel="noopener noreferrer" underline="hover">
        {content}
      </Link>
    ) : content;
  };
  
  const ScoreCircle = ({ score }) => (
    <Box
      sx={{
        position: 'relative',
        padding: 12,
        width: 160,
        height: 160,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: (theme) => `radial-gradient(circle, ${alpha(theme.palette.primary.main, 0.1)} 0%, ${alpha(theme.palette.primary.main, 0.05)} 100%)`,
        border: '4px solid',
        borderColor: (theme) => {
          if (score <= 33.33) return theme.palette.error.main;
          if (score <= 66.66) return theme.palette.warning.main;
          return theme.palette.success.main;
        }
      }}
    >
      <Typography
        variant="h2"
        sx={{
          fontWeight: 700,
          color: (theme) => {
            if (score <= 33.33) return theme.palette.error.main;
            if (score <= 66.66) return theme.palette.warning.main;
            return theme.palette.success.main;
          }
        }}
      >
        {score.toFixed(1)}%
      </Typography>
    </Box>
  );


  



const ProviderOverview = ({ provider, onRefresh }) => {
  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [isProduction, setIsProduction] = useState(true);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectionStatus, setConnectionStatus] = useState({
    Staging: provider.mydra_education_provider?.Staging || '',
    Production: provider.mydra_education_provider?.Production || '',
  });
  const [landingPages, setLandingPages] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const [authToken, setAuthToken] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [isAutoConnecting, setIsAutoConnecting] = useState(false);
  const [isLoadingPages, setIsLoadingPages] = useState(false);

  // Add new state for tracking connection validity
  const [isConnectionValid, setIsConnectionValid] = useState(false);

  // Add effect to check connection status when environment or connection status changes
  useEffect(() => {
    const currentEnv = isProduction ? 'Production' : 'Staging';
    const hasValidConnection = Boolean(connectionStatus[currentEnv]);
    setIsConnectionValid(hasValidConnection);
  }, [isProduction, connectionStatus]);


  // Fetch Mydra providers
    // Fetch Mydra providers
    // Setup Firebase Auth listener
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setAuthToken(token);
        setUserEmail(user.email);
      }
    });

    return () => unsubscribe();
  }, []);

  // Fetch Mydra providers when auth is ready
  useEffect(() => {
    const fetchProviders = async () => {
      if (!authToken || !userEmail) return;

      try {
        const response = await fetch(`${API_BASE_URL}/mydra/education-providers`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'X-Environment': isProduction ? 'Production' : 'Staging',
            'X-User-Email': userEmail
          }
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Mydra Providers:', data);
          setProviders(data.data || []);
        } else {
          console.error('Failed to fetch providers:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching Mydra providers:', error);
      }
    };

    fetchProviders();
  }, [authToken, userEmail, isProduction, API_BASE_URL]);

  const handleAutoConnect = async () => {
    setIsAutoConnecting(true);
    try {
      const result = await providerConnectionService.autoConnect(provider.id);
      if (result.status === 'success') {
        // Update local connection status
        setConnectionStatus(result.data);
        
        // Refresh the entire provider data
        await onRefresh();
        
        // You could also add a success message here
        // if you have a notification system
      } else {
        // Show error message
        console.error('Auto-connect failed:', result.message);
      }
    } catch (error) {
      console.error('Auto-connect failed:', error);
      // Show error message
    } finally {
      setIsAutoConnecting(false);
    }
  };


  const handleConnectClick = async () => {
    if (selectedProvider) {
      setIsConnecting(true);
      try {
        const db = getFirestore();
        const siteDocRef = doc(db, 'sites', provider.id);
        
        // Get current data
        const siteDoc = await getDoc(siteDocRef);
        const currentData = siteDoc.data();

        // Update the connection
        const updateData = {
          mydra_education_provider: {
            ...currentData.mydra_education_provider,
            [isProduction ? 'Production' : 'Staging']: selectedProvider,
          },
        };

        await updateDoc(siteDocRef, updateData);

        // Update local state
        setConnectionStatus({
          ...connectionStatus,
          [isProduction ? 'Production' : 'Staging']: selectedProvider,
        });

        setSelectedProvider(''); // Reset selection
      } catch (error) {
        console.error('Error connecting provider:', error);
      } finally {
        setIsConnecting(false);
      }
    }
  };

  const generateMydraHubUrl = (providerId, providerName) => {
    return `https://www.mydrahub.com/mydra/provider/${providerId}/${encodeURIComponent(providerName)}`;
  };

  const handleGetLandingPages = async () => {
    const currentEnv = isProduction ? 'Production' : 'Staging';
    
    // Check if we have a valid connection for the current environment
    if (!connectionStatus[currentEnv]) {
      console.error(`No Mydra connection found for ${currentEnv} environment`);
      // You might want to show a notification to the user here
      return;
    }

    setIsLoadingPages(true);
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/coursestream/providers/${provider.id}/landing-pages`,
        {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'X-Environment': currentEnv, // Add environment header
            'X-Provider-Id': connectionStatus[currentEnv] // Add connected provider ID
          }
        }
      );

      if (response.ok) {
        const data = await response.json();
        setLandingPages(data.data);
        setOpenDialog(true);
      } else {
        console.error('Failed to fetch landing pages:', await response.text());
        // You might want to show an error notification here
      }
    } catch (error) {
      console.error('Error fetching landing pages:', error);
      // You might want to show an error notification here
    } finally {
      setIsLoadingPages(false);
    }
  };

  // const handleGetLandingPages = async () => {
  //   setIsLoadingPages(true);
  //   try {
  //     const response = await fetch(
  //       `${API_BASE_URL}/api/coursestream/providers/${provider.id}/landing-pages?environment=${isProduction ? 'Production' : 'Staging'}`,
  //       {
  //         headers: {
  //           'Authorization': `Bearer ${authToken}`,
  //           'Content-Type': 'application/json'
  //         }
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       setLandingPages(data.data);
  //       setOpenDialog(true);
  //     } else {
  //       console.error('Failed to fetch landing pages:', await response.text());
  //     }
  //   } catch (error) {
  //     console.error('Error fetching landing pages:', error);
  //   } finally {
  //     setIsLoadingPages(false);
  //   }
  // };


  const LandingPagesDialog = () => {
    const getLanguageName = (code) => {
      const languages = {
        en: 'English',
        fr: 'French',
        es: 'Spanish',
        de: 'German',
        it: 'Italian',
        pt: 'Portuguese'
      };
      return languages[code] || code;
    };
  
    const getPageUrl = (page) => {
      const baseUrl = isProduction 
        ? 'https://marketplace.mydra.io'
        : 'https://marketplace.staging.mydra.io';
          
      return `${baseUrl}/${page.language}/partners/${page.slug}`;
    };
  
    return (
      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: 2,
            boxShadow: (theme) => `0 8px 32px ${alpha(theme.palette.primary.main, 0.08)}`
          }
        }}
      >
        <DialogTitle sx={{ p: 3, borderBottom: '1px solid', borderColor: 'divider' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LanguageIcon sx={{ color: 'primary.main' }} />
              <Typography variant="h6" sx={{ fontWeight: 600 }}>
                Landing Pages
              </Typography>
            </Box>
            <IconButton 
              onClick={() => setOpenDialog(false)} 
              size="small"
              sx={{ 
                color: 'text.secondary',
                '&:hover': { 
                  backgroundColor: (theme) => alpha(theme.palette.error.main, 0.08),
                  color: 'error.main'
                }
              }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
  
        <DialogContent sx={{ p: 3 }}>
          {landingPages.length === 0 ? (
            <Box 
              sx={{ 
                py: 8, 
                textAlign: 'center',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: 2
              }}
            >
              <LanguageIcon 
                sx={{ 
                  fontSize: 48, 
                  color: 'text.disabled',
                  mb: 2
                }} 
              />
              <Typography variant="h6" color="text.secondary">
                No Landing Pages Found
              </Typography>
              <Typography color="text.secondary" variant="body2">
                Create your first landing page to showcase your courses
              </Typography>
            </Box>
          ) : (

            <List sx={{ mx: -3 }}>
            {landingPages.map((page, index) => (
                <ListItem
                key={page._id}
                divider={index !== landingPages.length - 1}
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    alignItems: 'flex-start',
                    p: 3,
                    transition: 'all 0.2s ease-in-out',
                    '&:hover': {
                    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04)
                    }
                }}
                >
                {/* Title row with language and date */}
                <Box 
                    sx={{ 
                    width: '100%', 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    mb: 1.5
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Typography 
                        variant="subtitle1" 
                        sx={{ 
                        fontWeight: 600,
                        color: 'text.primary'
                        }}
                    >
                        {page.title}
                    </Typography>
                    <Typography 
                        variant="caption" 
                        sx={{ 
                        color: 'text.secondary',
                        }}
                    >
                        Updated {new Date(page.updatedAt).toLocaleDateString()}
                    </Typography>
                    </Box>
                    <Chip 
                    label={getLanguageName(page.language)}
                    size="small"
                    sx={{
                        fontWeight: 500,
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                        color: 'primary.main',
                        '& .MuiChip-label': { px: 2 }
                    }}
                    />
                </Box>

                {/* Description */}
                <Typography 
                    variant="body2" 
                    color="text.secondary" 
                    sx={{ 
                    mb: 2,
                    lineHeight: 1.6
                    }}
                >
                    {page.description}
                </Typography>

                {/* Action button */}
                <Box 
                    sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 2,
                    width: '100%',
                    justifyContent: 'flex-start'
                    }}
                >
                    <Button
                    variant="outlined"
                    size="small"
                    startIcon={<LanguageIcon />}
                    component="a"
                    href={getPageUrl(page)}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                        textTransform: 'none',
                        fontWeight: 500,
                        '&:hover': {
                        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08)
                        }
                    }}
                    >
                    Visit Landing Page
                    </Button>
                </Box>
                </ListItem>
            ))}
            </List>
                        
          )}
        </DialogContent>
      </Dialog>
    );
  };

//   const LandingPagesDialog = () => {
//     const getLanguageName = (code) => {
//       const languages = {
//         en: 'English',
//         fr: 'French',
//         es: 'Spanish',
//         de: 'German',
//         it: 'Italian',
//         pt: 'Portuguese'
//       };
//       return languages[code] || code;
//     };

//     const getPageUrl = (page) => {
//         const baseUrl = isProduction 
//             ? 'https://marketplace.mydra.io'
//             : 'https://marketplace.staging.mydra.io';
            
//         return `${baseUrl}/${page.language}/partners/${page.slug}`;
//     };
//     return (
//       <Dialog 
//         open={openDialog} 
//         onClose={() => setOpenDialog(false)}
//         maxWidth="md"
//         fullWidth
//       >
//         <DialogTitle>
//           <Box display="flex" justifyContent="space-between" alignItems="center">
//             <Typography variant="h6">Landing Pages</Typography>
//             <IconButton onClick={() => setOpenDialog(false)} size="small">
//               <CloseIcon />
//             </IconButton>
//           </Box>
//         </DialogTitle>
//         <DialogContent>
//           <List>
//             {landingPages.map((page) => (
//               <ListItem
//                 key={page._id}
//                 divider
//                 sx={{ 
//                   display: 'flex', 
//                   flexDirection: 'column', 
//                   alignItems: 'flex-start',
//                   py: 2 
//                 }}
//               >
//                 <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 1 }}>
//                   <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
//                     {page.title}
//                   </Typography>
//                   <Chip 
//                     label={getLanguageName(page.language)}
//                     size="small"
//                     color="primary"
//                     variant="outlined"
//                   />
//                 </Box>
//                 <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
//                   {page.description}
//                 </Typography>
//                 <Box sx={{ display: 'flex', gap: 2 }}>
//                   <Button
//                     variant="outlined"
//                     size="small"
//                     startIcon={<LanguageIcon />}
//                     component="a"
//                     href={getPageUrl(page)}
//                     target="_blank"
//                     rel="noopener noreferrer"
//                   >
//                     View Page
//                   </Button>
//                   <Typography variant="caption" color="text.secondary" sx={{ alignSelf: 'center' }}>
//                     Last updated: {new Date(page.updatedAt).toLocaleDateString()}
//                   </Typography>
//                 </Box>
//               </ListItem>
//             ))}
//           </List>
//           {landingPages.length === 0 && (
//             <Box sx={{ py: 4, textAlign: 'center' }}>
//               <Typography color="text.secondary">
//                 No landing pages found
//               </Typography>
//             </Box>
//           )}
//         </DialogContent>
//       </Dialog>
//     );
//   };

  const EnvironmentSwitch = ({ isProduction, onChange }) => (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center',
        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.05),
        borderRadius: 2,
        p: 1
      }}
    >
      <Typography 
        variant="body2" 
        sx={{ 
          color: !isProduction ? 'primary.main' : 'text.secondary',
          fontWeight: !isProduction ? 600 : 400
        }}
      >
        Staging
      </Typography>
      <Switch
        checked={isProduction}
        onChange={onChange}
        sx={{ 
          mx: 1,
          '& .MuiSwitch-switchBase.Mui-checked': {
            color: 'primary.main'
          },
          '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: 'primary.main'
          }
        }}
      />
      <Typography 
        variant="body2"
        sx={{ 
          color: isProduction ? 'primary.main' : 'text.secondary',
          fontWeight: isProduction ? 600 : 400
        }}
      >
        Production
      </Typography>
    </Box>
  );
  
  const ConnectedProvider = ({ provider, onChangeClick }) => (
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        bgcolor: (theme) => alpha(theme.palette.success.main, 0.05),
        borderRadius: 2,
        p: 2
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <CheckCircleIcon 
          color="success" 
          sx={{ fontSize: 24 }} 
        />
        <Typography variant="subtitle2" color="text.secondary">
          Connected to:
        </Typography>
      </Box>
      <Box 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          gap: 2,
          flex: 1,
          mx: 2
        }}
      >
        {provider?.logoUrl && (
          <Box
            component="img"
            src={provider.logoUrl}
            alt={`${provider.name} logo`}
            sx={{ 
              width: 40, 
              height: 40, 
              objectFit: 'contain',
              borderRadius: 1,
              border: '1px solid',
              borderColor: 'divider'
            }}
          />
        )}
        <Typography 
          variant="subtitle1" 
          component="a"
          href={generateMydraHubUrl(provider?._id, provider?.name)}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ 
            fontWeight: 600,
            cursor: 'pointer',
            textDecoration: 'none',
            color: 'inherit',
            '&:hover': {
              color: 'primary.main'
            }
          }}
        >
          {provider?.name}
        </Typography>
      </Box>
      <Button
        variant="outlined"
        size="small"
        onClick={onChangeClick}
        sx={{ 
          textTransform: 'none',
          minWidth: 100
        }}
      >
        Change
      </Button>
    </Box>
  );
  
  const ProviderSelector = ({ 
    providers, 
    selectedProvider, 
    onProviderChange, 
    onAutoConnect, 
    onConnect, 
    isAutoConnecting,
    isConnecting 
  }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <FormControl fullWidth>
        <InputLabel>Select Mydra Provider</InputLabel>
        <Select
          value={selectedProvider}
          onChange={onProviderChange}
          label="Select Mydra Provider"
          sx={{ 
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: (theme) => alpha(theme.palette.primary.main, 0.2)
            }
          }}
        >
          {providers.map((p) => (
            <MenuItem 
              key={p._id} 
              value={p._id}
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2
              }}
            >
              {p.logoUrl && (
                <Box
                  component="img"
                  src={p.logoUrl}
                  alt={`${p.name} logo`}
                  sx={{ 
                    width: 24,
                    height: 24,
                    objectFit: 'contain'
                  }}
                />
              )}
              {p.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="outlined"
          onClick={onAutoConnect}
          disabled={isAutoConnecting}
          startIcon={isAutoConnecting ? 
            <CircularProgress size={20} /> : 
            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 16V12L14 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
              <path d="M12 12L10 14" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
              <path d="M12 4V12" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
              <path d="M4 12C4 16.4183 7.58172 20 12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4" stroke="currentColor" strokeWidth="2" strokeLinecap="round"/>
            </svg>
          }
          sx={{ 
            flex: 1,
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          {isAutoConnecting ? 'Connecting...' : 'Auto Connect'}
        </Button>
        <Button
          variant="contained"
          onClick={onConnect}
          disabled={!selectedProvider || isConnecting}
          startIcon={isConnecting ? <CircularProgress size={20} /> : null}
          sx={{ 
            flex: 1,
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          {isConnecting ? 'Connecting...' : 'Connect'}
        </Button>
      </Box>
    </Box>
  );
  
  const ProviderStatusCard = ({ 
    landingPagesCount,
    isActive, 
    onViewLandingPages,
    isLoadingPages
  }) => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      <Box 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          bgcolor: (theme) => alpha(theme.palette.background.default, 0.6),
          borderRadius: 2,
          p: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Landing Pages
            </Typography>
            {/* <StatusChip 
              status={landingPagesCount > 0} 
              label={landingPagesCount > 0 ? `${landingPagesCount} Created` : "Not Created"} 
            /> */}
          </Box>
        </Box>
        <Button
          variant="outlined"
          onClick={onViewLandingPages}
          disabled={isLoadingPages || !isConnectionValid}
          startIcon={isLoadingPages ? <CircularProgress size={20} /> : null}
          sx={{ 
            textTransform: 'none',
            fontWeight: 500
          }}
        >
          {isLoadingPages ? 'Loading...' : !isConnectionValid ? 
            `Connect to ${isProduction ? 'Production' : 'Staging'} First` : 
            'View Landing Pages'}
        </Button>
      </Box>

      <Box 
        sx={{ 
          bgcolor: (theme) => alpha(theme.palette.background.default, 0.6),
          borderRadius: 2,
          p: 2
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              Provider Status
            </Typography>
            <StatusChip 
              status={isActive} 
              label={isActive ? "Active" : "Inactive"} 
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );



  const ProcessCard = ({ title, icon: Icon, stats, trend }) => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          p: 3,
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'divider',
          bgcolor: 'background.paper',
          height: '100%',
          transition: 'all 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: (theme) => `0 12px 24px ${alpha(theme.palette.primary.main, 0.08)}`,
            borderColor: 'primary.main',
            '& .icon-wrapper': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.12),
              color: 'primary.main',
            }
          }
        }}
      >
        <Box
          className="icon-wrapper"
          sx={{
            width: 48,
            height: 48,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            color: 'primary.main',
            transition: 'all 0.2s ease-in-out',
            mb: 2
          }}
        >
          <Icon sx={{ fontSize: 24 }} />
        </Box>
  
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600,
            mb: 3,
            color: 'text.primary'
          }}
        >
          {title}
        </Typography>
  
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, flex: 1 }}>
          {stats.map((stat, index) => (
            <Box key={index}>
              <Typography 
                variant="h4" 
                sx={{ 
                  fontWeight: 700,
                  color: 'text.primary',
                  mb: 0.5
                }}
              >
                {stat.value}
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'text.secondary',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1
                }}
              >
                {stat.label}
                {stat.change && (
                  <Box
                    sx={{
                      display: 'inline-flex',
                      alignItems: 'center',
                      color: stat.change > 0 ? 'success.main' : 'error.main',
                      bgcolor: stat.change > 0 ? 'success.lighter' : 'error.lighter',
                      borderRadius: 1,
                      px: 1,
                      py: 0.25,
                      fontSize: '0.75rem',
                      fontWeight: 600
                    }}
                  >
                    {stat.change > 0 ? '+' : ''}{stat.change}%
                  </Box>
                )}
              </Typography>
            </Box>
          ))}
        </Box>
  
        {trend && (
          <Box sx={{ mt: 2, pt: 2, borderTop: '1px dashed', borderColor: 'divider' }}>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {trend}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };
  



  return (
    <Grid container spacing={3}>

        {/* Basic Information */}
        {/* Basic Information */}
        <Grid item xs={12}>
        <Section title="Basic Information">
            <Grid container spacing={4}>
            {/* Left side - Name and Description */}
            <Grid item xs={12} md={6}>
                <Box sx={{ mb: 3 }}>
                <Typography 
                    variant="h4" 
                    sx={{ 
                    fontWeight: 600,
                    mb: 1,
                    color: 'primary.main'
                    }}
                >
                    {provider.name}
                </Typography>
                <Typography 
                    variant="body1" 
                    color="text.secondary" 
                    sx={{ 
                    lineHeight: 1.8,
                    pr: { md: 4 } // Add some padding on the right for better readability
                    }}
                >
                    {provider.education_provider_analysis?.business_description}
                </Typography>
                </Box>
            </Grid>

            {/* Right side - Two columns of info items */}
            <Grid item xs={12} md={6}>
                <Grid container spacing={2}>
                {/* First column */}
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <InfoItem
                        icon={LocationIcon}
                        label="Location"
                        value={provider.linkedin_data?.company_location}
                    />
                    <InfoItem
                        icon={SchoolIcon}
                        label="Content Type"
                        value={provider.education_provider_analysis?.content_type}
                    />
                    </Box>
                </Grid>
                {/* Second column */}
                <Grid item xs={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <InfoItem
                        icon={LanguageIcon}
                        label="Website"
                        value={provider.url}
                        href={provider.url}
                    />
                    <InfoItem
                        icon={LinkedInIcon}
                        label="LinkedIn"
                        value="Company Profile"
                        href={provider.linkedin_url}
                    />
                    </Box>
                </Grid>
                </Grid>
            </Grid>
            </Grid>
        </Section>
        </Grid>


          {/* Mydra Connection Section */}

          <Grid item xs={12}>
            <Section title="Integration Status">
                <Grid container spacing={4}>
                <Grid item xs={12} lg={7}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" sx={{ fontWeight: 600 }}>
                        Mydra Connection
                        </Typography>
                        <EnvironmentSwitch 
                        isProduction={isProduction} 
                        onChange={() => setIsProduction(!isProduction)} 
                        />
                    </Box>

                    {connectionStatus[isProduction ? 'Production' : 'Staging'] ? (
                        <ConnectedProvider 
                        provider={providers.find(p => p._id === connectionStatus[isProduction ? 'Production' : 'Staging'])}
                        onChangeClick={() => setConnectionStatus({
                            ...connectionStatus,
                            [isProduction ? 'Production' : 'Staging']: ''
                        })}
                        />
                    ) : (
                        <ProviderSelector 
                        providers={providers}
                        selectedProvider={selectedProvider}
                        onProviderChange={(e) => setSelectedProvider(e.target.value)}
                        onAutoConnect={handleAutoConnect}
                        onConnect={handleConnectClick}
                        isAutoConnecting={isAutoConnecting}
                        isConnecting={isConnecting}
                        />
                    )}
                    </Box>
                </Grid>

                <Grid item xs={12} lg={5}>
                    <ProviderStatusCard 
                    landingPagesCount={landingPages.length}
                    isActive={provider.status?.active || false}
                    onViewLandingPages={handleGetLandingPages}
                    isLoadingPages={isLoadingPages}
                    />
                </Grid>
                </Grid>
            </Section>
            </Grid>
          


        {/* Landing Pages Dialog */}
        <LandingPagesDialog />

        {/* Score Section - Full Width */}
        {/* Score Section - Full Width */}
        <Grid item xs={12}>
        <Section title="Performance Score">
            <Grid container spacing={4} alignItems="center">
            <Grid item xs={12} md={4}>
                <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                alignItems: 'center',
                gap: 2
                }}>
                <ScoreCircle score={provider.score || 0} />
                <Typography 
                    variant="body2" 
                    color="text.secondary"
                    sx={{ 
                    textAlign: 'center',
                    maxWidth: 200
                    }}
                >
                    Overall score based on 6 key performance indicators
                </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} md={8}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
                {provider.status?.scoring?.details ? 
                    Object.entries(provider.status.scoring.details).map(([key, value]) => (
                    <Box 
                        key={key} 
                        sx={{ 
                        width: 'calc(50% - 12px)',
                        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.04),
                        borderRadius: 2,
                        p: 2
                        }}
                    >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography 
                            variant="subtitle2" 
                            sx={{ 
                            textTransform: 'capitalize',
                            fontWeight: 600
                            }}
                        >
                            {key.replace(/_/g, ' ')}
                        </Typography>
                        <Typography 
                            variant="subtitle2" 
                            sx={{ 
                            fontWeight: 600,
                            color: (theme) => {
                                if (value.score <= 33.33) return theme.palette.error.main;
                                if (value.score <= 66.66) return theme.palette.warning.main;
                                return theme.palette.success.main;
                            }
                            }}
                        >
                            {value.score.toFixed(1)}%
                        </Typography>
                        </Box>
                        <LinearProgress 
                        variant="determinate" 
                        value={value.score} 
                        sx={{ 
                            height: 8,
                            borderRadius: 4,
                            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.08),
                            '& .MuiLinearProgress-bar': {
                            borderRadius: 4,
                            }
                        }}
                        />
                    </Box>
                    )) : 
                    <Typography color="text.secondary">No scoring details available</Typography>
                }
                </Box>
            </Grid>
            </Grid>
        </Section>
        </Grid>

      <Grid item xs={12}>
        <Section title="Process Overview">
            <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
                <ProcessCard
                title="Crawling"
                icon={SearchIcon}
                stats={[
                    {
                    value: provider.status.crawling?.courses_found,
                    label: 'Courses Found',
                    change: 12 // Example change percentage
                    },
                    {
                    value: provider.status.crawling?.linkedin_urls_found,
                    label: 'LinkedIn Profiles'
                    },
                    {
                    value: provider.status.crawling?.emails_found,
                    label: 'Emails Found'
                    }
                ]}
                // trend="Last crawl completed 2 days ago"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <ProcessCard
                title="Courses"
                icon={CourseIcon}
                stats={[
                    {
                    value: `${provider.status.courses?.uploaded}`,
                    label: 'Courses Uploaded'
                    },
                    // {
                    // value: `${((provider.status.courses?.uploaded / provider.status.courses?.total) * 100).toFixed(1)}%`,
                    // label: 'Publication Rate',
                    // change: 5 // Example change percentage
                    // }
                ]}
                // trend="8 new courses added this week"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <ProcessCard
                title="Contacts"
                icon={ContactsIcon}
                stats={[
                    {
                    value: provider.status.outreach?.contacts_identified,
                    label: 'Contacts Identified'
                    },
                    // {
                    // value: Math.round((provider.status.outreach?.contacts_identified / provider.status.crawling?.linkedin_urls_found) * 100) + '%',
                    // label: 'Contact Rate'
                    // }
                ]}
                // trend="Contact discovery in progress"
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <ProcessCard
                title="Messages"
                icon={MessageIcon}
                stats={[
                    {
                    value: provider.status.outreach?.emails_sent,
                    label: 'Emails Sent'
                    },
                    {
                    value: (provider?.status?.outreach?.responses || 0),
                    label: 'Responses Received',
                    change: -3 // Example change percentage
                    }
                ]}
                // trend="Last message sent 3 days ago"
                />
            </Grid>
            </Grid>
        </Section>
        </Grid>



        
    </Grid> 

  );
};

export default ProviderOverview;

  