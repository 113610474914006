import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress, 
  Grid, 
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  Drawer,
  Link,
  Avatar,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  LinearProgress,
  Snackbar,
  DialogContentText,
  Tabs,
  Tab
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import LaunchIcon from '@mui/icons-material/Launch';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PublishIcon from '@mui/icons-material/Publish';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import DeleteIcon from '@mui/icons-material/Delete';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { formatDistanceToNow } from 'date-fns';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const HEADER_BLUE = '#7986cb'; // This is a Material-UI indigo color that seems close to the image

// Define status constants for better maintenance
const QA_STATUS = {
  APPROVED_TO_PUBLISH: 'APPROVED_TO_PUBLISH',
  FLAGGED_FOR_REVIEW: 'FLAGGED_FOR_REVIEW'
};

const REVIEW_STATUS = {
  PENDING_REVIEW: 'PENDING_REVIEW',
  REVIEWED_FOR_PUBLISH: 'REVIEWED_FOR_PUBLISH',
  REVIEWED_FOR_DELETE: 'REVIEWED_FOR_DELETE',
  REVIEWED: 'REVIEWED',
  PUBLISHED: 'PUBLISHED',
  DELETED: 'DELETED'
};

const checkDescriptions = {
    'Cost Validation': 'Verifies that each cohort in the course has a specified cost.',
    'Instructor Validation': 'Ensures each instructor has both a LinkedIn profile and an avatar.',
    'Instructor Categorization': 'Checks if there are more than 3 instructors listed, which might indicate a miscategorization of alumni.',
    'Daily Duration Validation': 'Validates that the daily duration for each cohort does not exceed 6 hours.',
    'Start Date Validation': 'Validates the consistency between cohort start dates and course validation type.',
    'Course Date Validation': 'Validates that all non-self-paced courses have appropriate start and end dates specified.',
    'Self-Paced Format Validation': 'Validates that self-paced courses do not have both start and end dates specified.',
    'Pre-recorded Class Type Validation': 'Validates that pre-recorded classes are correctly designated as online.',
    'Location Validation': 'Ensures course location is either a valid city or listed as "online."',
    'Instructor LinkedIn Mismatch': 'Verifies that the instructor\'s name matches their LinkedIn profile name.'
};

const PublishConfirmationModal = ({ open, onClose, courseData, onConfirmPublish, isProduction, isPublishing }) => {
  if (!courseData) return null;

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        Confirm Course Publication
      </DialogTitle>
      <DialogContent>
        {isProduction && (
          <Alert className="mb-4" severity="warning">
            You are about to publish this course to PRODUCTION
          </Alert>
        )}
        <DialogContentText>
          Are you sure you want to publish the following course?
        </DialogContentText>
        <Box className="mt-4 p-4 bg-gray-50 rounded-lg">
          <Typography className="font-semibold">
            {courseData.course_name}
          </Typography>
          <Typography className="text-gray-600 text-sm mt-2">
            Provider: {courseData.education_provider_name}
          </Typography>
        </Box>
        <Typography className="mt-4 text-sm text-gray-600">
          This action will make the course visible to users in the {isProduction ? 'production' : 'staging'} environment.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          variant="outline"
          disabled={isPublishing}
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            onConfirmPublish(courseData);
          }}
          variant="default"
          className="bg-green-600 hover:bg-green-700 text-white"
          disabled={isPublishing}
        >
          {isPublishing ? (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CircularProgress size={16} color="inherit" />
              Publishing...
            </Box>
          ) : (
            'Yes, Publish Course'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};


const ReviewModal = ({ open, onClose, courseData, onSubmitReview }) => {
  const [reviewNotes, setReviewNotes] = useState('');
  const [reviewAction, setReviewAction] = useState(''); // 'publish' or 'delete'

  
  const handleSubmit = () => {
    onSubmitReview({
      log_id: courseData.log_id || courseData.id || courseData.qa_id,
      course_id: courseData.course_id,
      notes: reviewNotes,
      review_status: reviewAction === 'publish' 
        ? REVIEW_STATUS.REVIEWED_FOR_PUBLISH 
        : REVIEW_STATUS.REVIEWED_FOR_DELETE,
      action: reviewAction,
    });
    onClose();
    setReviewAction('');
    setReviewNotes('');
  };

  if (!courseData) return null;

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Review Course: {courseData.course_name}</DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>Failed Checks:</Typography>
          {courseData.checks.filter(check => check.result === 'Fail').map((check, index) => (
            <Chip
              key={index}
              label={check.name}
              color="error"
              size="small"
              sx={{ m: 0.5 }}
            />
          ))}
        </Box>

        <TextField
          fullWidth
          multiline
          rows={4}
          label="Review Notes"
          value={reviewNotes}
          onChange={(e) => setReviewNotes(e.target.value)}
          placeholder="Detail the changes made or reasons for approval/deletion..."
          sx={{ mb: 3 }}
        />

        <FormControl fullWidth>
          <Typography variant="subtitle1" gutterBottom>Review Action:</Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              variant={reviewAction === 'publish' ? 'contained' : 'outlined'}
              color="success"
              onClick={() => setReviewAction('publish')}
              startIcon={<CheckCircleIcon />}
              sx={{ flex: 1 }}
            >
              Mark for Publication
            </Button>
            <Button
              variant={reviewAction === 'delete' ? 'contained' : 'outlined'}
              color="error"
              onClick={() => setReviewAction('delete')}
              startIcon={<DeleteIcon />}
              sx={{ flex: 1 }}
            >
              Mark for Deletion
            </Button>
          </Box>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit}
          variant="contained" 
          color={reviewAction === 'delete' ? 'error' : 'primary'}
          disabled={!reviewNotes.trim() || !reviewAction}
        >
          {reviewAction === 'delete' ? 'Confirm Review for Deletion' : 'Confirm Review for Publication'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//       <DialogTitle>Review Course: {courseData.course_name}</DialogTitle>
//       <DialogContent>
//         <Box sx={{ mb: 3 }}>
//           <Typography variant="subtitle1" gutterBottom>Failed Checks:</Typography>
//           {courseData.checks.filter(check => check.result === 'Fail').map((check, index) => (
//             <Chip
//               key={index}
//               label={check.name}
//               color="error"
//               size="small"
//               sx={{ m: 0.5 }}
//             />
//           ))}
//         </Box>
//         <TextField
//           fullWidth
//           multiline
//           rows={4}
//           label="Review Notes"
//           value={reviewNotes}
//           onChange={(e) => setReviewNotes(e.target.value)}
//           placeholder="Detail the changes made or reasons for approval..."
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button 
//           onClick={handleSubmit}
//           variant="contained" 
//           color="primary"
//           disabled={!reviewNotes.trim()}
//         >
//           Mark as Ready to Publish
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };


const CheckDetailsDrawer = ({ open, onClose, selectedLog }) => {
    
    const [anchorEl, setAnchorEl] = useState(null);
    const [popoverContent, setPopoverContent] = useState('');
    const handleInfoClick = (event, description) => {
        setAnchorEl(event.currentTarget);
        setPopoverContent(description);
      };
    
    const handlePopoverClose = () => {
    setAnchorEl(null);
    };
    if (!selectedLog) return null;

    const openPopover = Boolean(anchorEl);
    const isApproved = selectedLog.action === 'APPROVED_TO_PUBLISH';



    const formatInstructorDetails = (details) => {
        return details.map((item, index) => (
          <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <Avatar 
              src={item.avatar_url} 
              sx={{ width: 40, height: 40, mr: 2 }}
            >
              {!item.avatar_url && <PersonIcon />}
            </Avatar>
            <Box>
              <Typography variant="body2">{item.name}</Typography>
              {item.linkedin ? (
                <Link 
                  href={item.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <LinkedInIcon sx={{ fontSize: 16, mr: 0.5 }} />
                  LinkedIn Profile
                </Link>
              ) : (
                <Typography variant="body2" color="text.secondary">No LinkedIn profile</Typography>
              )}
            </Box>
          </Box>
        ));
      };

      const formatLocationDetails = (details) => {
        return details.map((item, index) => (
          <Box key={index} sx={{ mb: 1 }}>
            <Typography variant="body2">
              Cohort {item.cohort_index}: {item.location} ({item.is_valid ? 'Valid' : 'Invalid'})
            </Typography>
          </Box>
        ));
      };

      const formatLinkedInMismatchDetails = (details) => {
        console.log("Received details:", details); // Debug log
      
        return details.map((item, index) => {
          console.log(`Processing item ${index}:`, item); // Debug log for each item
      
          return (
            <Box key={index} sx={{ 
              mb: 1, 
              display: 'flex', 
              flexDirection: 'column',
              fontSize: '0.875rem',
              backgroundColor: index % 2 === 0 ? '#f5f5f5' : 'transparent',
              p: 1,
              borderRadius: 1
            }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {item.name_matches ? (
                  <CheckCircleIcon color="success" sx={{ mr: 1, fontSize: '1rem', flexShrink: 0 }} />
                ) : (
                  <CancelIcon color="error" sx={{ mr: 1, fontSize: '1rem', flexShrink: 0 }} />
                )}
                <Typography sx={{ 
                  fontWeight: 'medium', 
                  flexGrow: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                  {item.name}
                </Typography>
              </Box>
              <Box sx={{ pl: 0, mt: 0.5, fontSize: '0.75rem' }}>
                {item.linkedin_url ? (
                  <Link 
                    href={item.linkedin_url} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    sx={{ 
                      color: 'text.secondary',
                      '&:hover': {
                        color: 'primary.main',
                      },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'block'
                    }}
                  >
                    {item.linkedin_url}
                  </Link>
                ) : (
                  <Typography sx={{ color: 'text.disabled' }}>
                    No LinkedIn URL
                  </Typography>
                )}
              </Box>
            </Box>
          );
        });
      };
      
      
  
      const formatOtherDetails = (details) => {
        if (Array.isArray(details)) {
          return details.map((item, index) => (
            <Box key={index} sx={{ mb: 1 }}>
              <Typography variant="body2" component="div">
                {index}:
                {Object.entries(item).map(([key, value]) => (
                  <Box key={key} sx={{ ml: 2 }}>
                    {key}: {typeof value === 'object' ? JSON.stringify(value) : value}
                  </Box>
                ))}
              </Typography>
            </Box>
          ));
        }
        return typeof details === 'object' ? JSON.stringify(details, null, 2) : details;
      };

      const formatDetails = (check) => {
        switch (check.name) {
          case 'Instructor Validation':
            return formatInstructorDetails(check.details);
          case 'Location Validation':
            return formatLocationDetails(check.details);
          case 'Instructor LinkedIn Mismatch':
            return formatLinkedInMismatchDetails(check.details);
          default:
            return formatOtherDetails(check.details);
        }
      };
    
  
      return (
        <Drawer anchor="right" open={open} onClose={onClose} sx={{ '& .MuiDrawer-paper': { width: '40%' } }}>
          <Box sx={{ p: 3, overflowY: 'auto' }}>
            <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 2,
            width: '100%'
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1, mr: 2 }}>
              <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#333' }}>
                {selectedLog.course_name}
              </Typography>
              <IconButton
                // href={`https://www.mydrahub.com/mydra/course/${selectedLog.course_id}?env=Staging`}
                href={`https://www.mydrahub.com/mydra/course/${selectedLog.course_id}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ ml: 1 }}
                size="small"
              >
                <LaunchIcon fontSize="small" />
              </IconButton>
            </Box>
            <Chip
                icon={isApproved ? <CheckCircleIcon /> : <CancelIcon />}
                label={isApproved ? 'Approved' : 'Flagged'}
                color={isApproved ? 'success' : 'error'}
                sx={{ fontWeight: 'bold', flexShrink: 0 }}
            />
            </Box>
            
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="subtitle1" color="textSecondary">
                {selectedLog.education_provider_name}
              </Typography>
              
            </Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {formatDistanceToNow(new Date(selectedLog.timestamp), { addSuffix: true })}
            </Typography>
            <Divider sx={{ my: 2 }} />
            
            {selectedLog.checks.map((check, index) => (
              <Paper key={index} elevation={1} sx={{ p: 2, mb: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#333' }}>
                    {check.name} 
                    <IconButton
                      size="small"
                      onClick={(event) => handleInfoClick(event, checkDescriptions[check.name] || 'Description not available')}
                    >
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Chip
                      icon={check.result === 'Pass' ? <CheckCircleIcon /> : <CancelIcon />}
                      label={check.result}
                      color={check.result === 'Pass' ? 'success' : 'error'}
                      sx={{ mr: 1 }}
                    />
                    
                  </Box>
                </Box>
                {check.reason && (
                  <Typography variant="body2" gutterBottom>
                    Reason: {check.reason}
                  </Typography>
                )}
                {check.details && (
                  <Box sx={{ 
                    backgroundColor: '#f5f5f5',
                    p: 1,
                    borderRadius: 1,
                    mt: 1,
                    fontSize: '0.75rem',
                  }}>
                    {formatDetails(check)}
                  </Box>
                )}
              </Paper>
            ))}
          </Box>
          <Popover
            open={openPopover}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
          >
            <Typography sx={{ p: 2 }}>{popoverContent}</Typography>
          </Popover>
        </Drawer>
      );
    };

    

const EducationProviderStats = ({ educationProviderStats }) => {
    const providerData = Object.entries(educationProviderStats).map(([name, stats]) => ({
      name,
      ...stats,
      publishRate: stats.total_processed > 0 ? (stats.published / stats.total_processed) * 100 : 0
    }));
  
    return (
      <Paper elevation={3} sx={{ mb: 4, overflow: 'hidden' }}>
        <Box sx={{ p: 2, backgroundColor: HEADER_BLUE, color: 'white' }}>
        <Typography variant="h6" fontWeight="bold">Education Provider Statistics</Typography>
        </Box>
        
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="education provider statistics">
            <TableHead>
                <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>Provider</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Total Processed</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Published</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Flagged for Review</TableCell>
                <TableCell align="right" sx={{ fontWeight: 'bold' }}>Publish Rate</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {providerData.map((row, index) => (
                <TableRow
                  key={row.name}
                  sx={{ '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="right">{row.total_processed}</TableCell>
                  <TableCell align="right">{row.published}</TableCell>
                  <TableCell align="right">{row.flagged_for_review}</TableCell>
                  <TableCell align="right">{row.publishRate.toFixed(2)}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    );
  };


  const RecentQALogsTable = ({ 
    recentQALogs, 
    educationProviders, 
    onSubmitReview,
    authToken,
    isProduction,
    showSnackbar,
    fetchReportData,
    userEmail,
    handleDeleteCourse
  }) => {
    const [selectedProvider, setSelectedProvider] = useState('');
    const [selectedLog, setSelectedLog] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [reviewModalOpen, setReviewModalOpen] = useState(false);
    const [selectedCourseForReview, setSelectedCourseForReview] = useState(null);
    const [publishModalOpen, setPublishModalOpen] = useState(false);
    const [selectedCourseForPublish, setSelectedCourseForPublish] = useState(null);
    const [publishingCourseId, setPublishingCourseId] = useState(null);
    const [isPublishing, setIsPublishing] = useState(false);

    const [deletingCourseId, setDeletingCourseId] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [selectedCourseForDelete, setSelectedCourseForDelete] = useState(null);


    // Add delete handlers
    const handleDeleteClick = (log) => {
      setSelectedCourseForDelete(log);
      setDeleteModalOpen(true);
    };

    // And update where it's called:
    const handleConfirmDelete = async (log) => {
      setIsDeleting(true);
      setDeletingCourseId(log.course_id);
      try {
        const deleteSuccess = await handleDeleteCourse(log.course_id);
        
        if (deleteSuccess) {
          // Only update QA log if delete was successful
          await fetch(`${API_BASE_URL}/mydra/course-qa/submit-review`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${authToken}`,
              'Content-Type': 'application/json',
              'X-Environment': isProduction ? 'Production' : 'Staging',
            },
            body: JSON.stringify({
              log_id: log.id,
              course_id: log.course_id,
              notes: 'Course deleted',
              review_status: REVIEW_STATUS.DELETED,
              mydra_env: isProduction ? 'Production' : 'Staging'
            })
          });

          await fetchReportData(authToken);
          setDeleteModalOpen(false);
        }
      } catch (error) {
        console.error('Error in delete process:', error);
        showSnackbar('Failed to complete delete process', 'error');
      } finally {
        setIsDeleting(false);
        setDeletingCourseId(null);
      }
    };




    const calculateCheckStats = (checks) => {
      const total = checks.length;
      const passed = checks.filter(check => check.result === 'Pass').length;
      const percentage = (passed / total) * 100;
      const failedChecks = checks.filter(check => check.result === 'Fail');
      return { passed, total, percentage, failedChecks };
    };

    const getReviewStatus = (log) => {
        // First check if the course is published or deleted
        if (log.review_status === REVIEW_STATUS.PUBLISHED) {
          return 'Published';
        }
        if (log.review_status === REVIEW_STATUS.DELETED) {
          return 'Deleted';
        }

        // Check review decisions
        if (log.review_status === REVIEW_STATUS.REVIEWED_FOR_PUBLISH) {
          return 'Reviewed - Ready to Publish';
        }
        if (log.review_status === REVIEW_STATUS.REVIEWED_FOR_DELETE) {
          return 'Reviewed - Ready to Delete';
        }

        // Default case - needs review
        return 'Pending Review';
      };

      const getStatusColor = (status) => {
        switch (status) {
          case 'Published':
            return 'success';
          case 'Deleted':
            return 'error'; // Or perhaps a different color to distinguish from "ready to delete"
          case 'Reviewed - Ready to Publish':
            return 'info';
          case 'Reviewed - Ready to Delete':
            return 'error';
          case 'Pending Review':
            return 'warning';
          default:
            return 'default';
        }
      };


    const handleReviewClick = (log) => {
      setSelectedCourseForReview({
        ...log,
        // Send all possible ID fields to ensure backward compatibility
        id: log.id,
        log_id: log.id || log.log_id || log.qa_id,  
        course_id: log.course_id,
        course_name: log.course_name
      });
      setReviewModalOpen(true);
    };
    

    const handlePublishClick = (log) => {
      setSelectedCourseForPublish(log);
      setPublishModalOpen(true);
    };

    const handleConfirmPublish = async (log) => {
      setIsPublishing(true);
      setPublishingCourseId(log.course_id);
      try {
        const response = await fetch(`${API_BASE_URL}/api/courses/publish`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
            'X-Environment': isProduction ? 'Production' : 'Staging',
          },
          body: JSON.stringify({
            log_id: log.id,
            course_id: log.course_id,
            mydra_env: isProduction ? 'Production' : 'Staging',
            review_status: log.review_status,
            reviewed_by: log.reviewed_by,
            review_notes: log.review_notes,
            qa_approved: log.action === QA_STATUS.APPROVED_TO_PUBLISH || 
                        log.review_status === REVIEW_STATUS.REVIEWED_FOR_PUBLISH
          })
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || 'Failed to publish course');
        }

        await fetchReportData(authToken);
        showSnackbar('Course published successfully', 'success');
        setPublishModalOpen(false);
      } catch (error) {
        console.error('Error publishing course:', error);
        showSnackbar(error.message || 'Failed to publish course', 'error');
      } finally {
        setIsPublishing(false);
        setPublishingCourseId(null);
      }
    };


      const getQACheckStyles = (percentage) => {
        if (percentage === 100) {
          return {
            backgroundColor: '#e8f5e9', // Light green background
            border: '1px solid #66bb6a', // Green border
            borderRadius: '4px',
            padding: '8px',
            color: '#2e7d32', // Dark green text
          };
        }
        return {
          backgroundColor: '#ffebee', // Light red background
          border: '1px solid #ef5350', // Red border
          borderRadius: '4px',
          padding: '8px',
          color: '#c62828', // Dark red text
        };
      };

      const headerCellStyle = {
        fontWeight: 700,
        color: '#1a1a1a',
        fontSize: '0.875rem',
        whiteSpace: 'nowrap'
      };



      return (
        <>
        <Paper elevation={3} sx={{ mb: 4, overflow: 'hidden' }}>
          <Box sx={{ p: 2, backgroundColor: HEADER_BLUE, color: 'white', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Recent QA Logs</Typography>
            <FormControl variant="outlined" size="small" sx={{ minWidth: 200, backgroundColor: 'white' }}>
              <InputLabel>Education Provider</InputLabel>
              <Select
                value={selectedProvider}
                onChange={(e) => setSelectedProvider(e.target.value)}
                label="Education Provider"
              >
                <MenuItem value="">All Providers</MenuItem>
                {educationProviders.map((provider) => (
                  <MenuItem key={provider} value={provider}>{provider}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          
          <TableContainer sx={{ maxHeight: 'calc(100vh - 300px)' }}>
            <Table stickyHeader>
              <TableHead>
              <TableRow sx={{ backgroundColor: '#f5f5f5' }}>
              <TableCell sx={headerCellStyle}>#</TableCell>
              <TableCell sx={headerCellStyle}>Timestamp</TableCell>
                  <TableCell sx={headerCellStyle}>Course Name</TableCell>
                  <TableCell sx={headerCellStyle}>Provider</TableCell>
                  <TableCell sx={headerCellStyle}>QA Checks</TableCell>
                  <TableCell sx={headerCellStyle}>QA Result</TableCell>
                  <TableCell sx={headerCellStyle}>Review Status</TableCell>
                  <TableCell sx={headerCellStyle}>Review Notes</TableCell>
                  <TableCell sx={headerCellStyle}>Review Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentQALogs.map((log, index) => {
                  const checkStats = calculateCheckStats(log.checks);
                  const reviewStatus = getReviewStatus(log);
                  return (
                    <TableRow 
                        key={index}
                        sx={{ 
                          '&:nth-of-type(even)': { backgroundColor: '#f9f9f9' },
                          '&:hover': { backgroundColor: '#f0f0f0', cursor: 'pointer' }  // Added cursor pointer
                        }}
                        onClick={() => {
                          setSelectedLog(log);
                          setDrawerOpen(true);
                        }}
                      >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        {(() => {
                          const date = new Date(new Date(log.timestamp).getTime() + (60 * 60 * 1000));
                          const now = new Date();
                          const diffMs = now - date;
                          const diffMins = Math.floor(diffMs / (1000 * 60));
                          const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
                          const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
                          const diffMonths = Math.floor(diffDays / 30);

                          if (diffMins < 60) {
                            return `${diffMins} minute${diffMins === 1 ? '' : 's'} ago`;
                          } else if (diffHours < 24) {
                            return `${diffHours} hour${diffHours === 1 ? '' : 's'} ago`;
                          } else if (diffDays < 30) {
                            return `${diffDays} day${diffDays === 1 ? '' : 's'} ago`;
                          } else {
                            return `${diffMonths} month${diffMonths === 1 ? '' : 's'} ago`;
                          }
                        })()}
                      </TableCell>
                      <TableCell>
                        <Link href={`https://www.mydrahub.com/mydra/course/${log.course_id}`} target="_blank">
                          {log.course_name}
                        </Link>
                      </TableCell>
                      <TableCell>{log.education_provider_name}</TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                          <LinearProgress 
                            variant="determinate" 
                            value={checkStats.percentage} 
                            sx={{ 
                              width: 60,
                              height: 8,
                              borderRadius: 4,
                              backgroundColor: '#f5f5f5',
                              '& .MuiLinearProgress-bar': {
                                backgroundColor: checkStats.percentage === 100 ? '#4caf50' : '#ff9800'
                              }
                            }}
                          />
                          <Typography variant="body2" sx={{ minWidth: '60px' }}>
                            {checkStats.passed}/{checkStats.total}
                          </Typography>
                          {checkStats.failedChecks.length > 0 && (
                            <Tooltip title={
                              <Box>
                                <Typography variant="subtitle2">Failed Checks:</Typography>
                                {checkStats.failedChecks.map((check, i) => (
                                  <Typography key={i} variant="body2">• {check.name}</Typography>
                                ))}
                              </Box>
                            }>
                              <IconButton size="small">
                                <ErrorOutlineIcon color="error" fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </Box>
                      </TableCell>
     
                      <TableCell>
                        <Chip 
                          label={log.action}
                          color={log.action === QA_STATUS.APPROVED_TO_PUBLISH ? 'success' : 'warning'}
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                          <Chip
                            label={reviewStatus}
                            color={getStatusColor(reviewStatus)}
                            size="small"
                          />
                          
                          {log.reviewed_by && (
                              <Typography 
                              variant="caption" 
                              sx={{ 
                                  fontSize: '0.75rem',
                                  color: 'text.secondary',
                                  paddingLeft: '4px'
                                }}
                              >
                                Reviewed by: {log.reviewed_by}
                              </Typography>
                            )}

                          {/* Show publisher info when published */}
                          {log.published_by && (
                            <Typography 
                              variant="caption" 
                              sx={{ 
                                fontSize: '0.75rem',
                                color: 'text.secondary',
                                paddingLeft: '4px'
                              }}
                            >
                              Published by: {log.published_by}
                            </Typography>
                          )}                            
                        </Box>
                      </TableCell>
                      <TableCell>{log.review_notes}</TableCell>
                      {/* // Update the table row rendering condition */}
                      <TableCell>
                        {log.review_status !== REVIEW_STATUS.PUBLISHED && log.review_status !== REVIEW_STATUS.DELETED ? (
                          <>
                            {log.review_status === REVIEW_STATUS.REVIEWED_FOR_PUBLISH ? (
                              <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handlePublishClick(log);
                                }}
                                startIcon={publishingCourseId === log.course_id ? (
                                  <CircularProgress size={16} color="inherit" />
                                ) : (
                                  <PublishIcon />
                                )}
                                color="success"
                                disabled={publishingCourseId === log.course_id}
                              >
                                {publishingCourseId === log.course_id ? 'Publishing...' : 'Publish'}
                              </Button>
                            ) : log.review_status === REVIEW_STATUS.REVIEWED_FOR_DELETE ? (
                              <Button
                                variant="contained"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleDeleteClick(log);
                                }}
                                startIcon={deletingCourseId === log.course_id ? (
                                  <CircularProgress size={16} color="inherit" />
                                ) : (
                                  <DeleteIcon />
                                )}
                                color="error"
                                disabled={deletingCourseId === log.course_id}
                              >
                                {deletingCourseId === log.course_id ? 'Deleting...' : 'Delete'}
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                size="small"
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  handleReviewClick(log);
                                }}
                                startIcon={<CheckCircleIcon />}
                              >
                                Review
                              </Button>
                            )}
                          </>
                        ) : null}
                      </TableCell>
                     
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
    
          <CheckDetailsDrawer 
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            selectedLog={selectedLog}
          />
    
          <ReviewModal
            open={reviewModalOpen}
            onClose={() => setReviewModalOpen(false)}
            courseData={selectedCourseForReview}
            onSubmitReview={onSubmitReview}
          />

          <PublishConfirmationModal
              open={publishModalOpen}
              onClose={() => {
                if (!isPublishing) {
                  setPublishModalOpen(false);
                }
              }}
              courseData={selectedCourseForPublish}
              onConfirmPublish={handleConfirmPublish}
              isProduction={isProduction}
              isPublishing={isPublishing}
            />
        </Paper>
        
      {/* Add delete confirmation modal */}
      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          if (!isDeleting) {
            setDeleteModalOpen(false);
          }
        }}
      >
        <DialogTitle>Confirm Course Deletion</DialogTitle>
        <DialogContent>
          {isProduction && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              You are about to delete this course from PRODUCTION
            </Alert>
          )}
          <DialogContentText>
            Are you sure you want to delete the following course?
            <Box sx={{ mt: 2, p: 2, bgcolor: 'grey.100', borderRadius: 1 }}>
              <Typography variant="subtitle1">
                {selectedCourseForDelete?.course_name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Provider: {selectedCourseForDelete?.education_provider_name}
              </Typography>
            </Box>
            <Typography color="error" sx={{ mt: 2 }}>
              This action cannot be undone.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteModalOpen(false)}
            disabled={isDeleting}
          >
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmDelete(selectedCourseForDelete)}
            variant="contained"
            color="error"
            disabled={isDeleting}
            startIcon={isDeleting ? <CircularProgress size={16} /> : <DeleteIcon />}
          >
            {isDeleting ? 'Deleting...' : 'Delete Course'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};




    // Create a new component for the QA Management tab
const QAManagementTab = ({ 
  recent_qa_logs, 
  educationProviders, 
  onSubmitReview,
  authToken,
  isProduction,
  showSnackbar,
  fetchReportData,
  userEmail,
  handleDeleteCourse
}) => {
  return (
    <Box>
      <RecentQALogsTable 
        recentQALogs={recent_qa_logs}
        educationProviders={educationProviders}
        onSubmitReview={onSubmitReview}
        authToken={authToken}
        isProduction={isProduction}
        showSnackbar={showSnackbar}
        fetchReportData={fetchReportData}
        userEmail={userEmail}
        handleDeleteCourse={handleDeleteCourse}
      />
    </Box>
  );
};

// Create a new component for the Analytics tab
const AnalyticsTab = ({ 
  overall_stats, 
  education_provider_stats, 
  overallStatsData, 
  flagReasonsData, 
  hasFlagReasons 
}) => {
  return (
    <Box>
      {/* Overall Stats Cards */}
        {/* Overall Stats */}
        <Box display="flex" justifyContent="space-between" mb={4}>
        <Paper elevation={3} sx={{ p: 2, width: '23%', textAlign: 'center' }}>
          <Typography variant="subtitle1" color="textSecondary">Total Processed</Typography>
          <Typography variant="h4">{overall_stats.total_processed}</Typography>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, width: '23%', textAlign: 'center' }}>
          <Typography variant="subtitle1" color="textSecondary">Published</Typography>
          <Typography variant="h4">{overall_stats.published}</Typography>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, width: '23%', textAlign: 'center' }}>
          <Typography variant="subtitle1" color="textSecondary">Flagged for Review</Typography>
          <Typography variant="h4">{overall_stats.flagged_for_review}</Typography>
        </Paper>
        <Paper elevation={3} sx={{ p: 2, width: '23%', textAlign: 'center' }}>
          <Typography variant="subtitle1" color="textSecondary">Publication Rate</Typography>
          <Typography variant="h4">{overall_stats.published_percentage.toFixed(1)}%</Typography>
        </Paper>
      </Box>

      {/* Education Provider Stats */}
      <EducationProviderStats educationProviderStats={education_provider_stats} />

      {/* Charts Grid */}
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Overall QA Status</Typography>
            <Box height={300}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={overallStatsData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                  >
                    {overallStatsData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>Flag Reasons</Typography>
            {hasFlagReasons ? (
              <Box height={300}>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart data={flagReasonsData} margin={{ top: 20, right: 30, left: 20, bottom: 100 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="name" 
                      interval={0}
                      tick={({ x, y, payload }) => (
                        <g transform={`translate(${x},${y})`}>
                          <text 
                            x={0} 
                            y={0} 
                            dy={16} 
                            textAnchor="end" 
                            fill="#666" 
                            transform="rotate(-35)"
                          >
                            {payload.value}
                          </text>
                        </g>
                      )}
                    />
                    <YAxis />
                    <Tooltip />
                    {/* <Legend /> */}
                    <Bar dataKey="value" fill="#8884d8" />
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            ) : (
              <Alert severity="info">No data available for flag reasons.</Alert>
            )}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};



const CourseQAPage = () => {
  const [authToken, setAuthToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null);

  const [reportData, setReportData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isProduction, setIsProduction] = useState(true); // Add environment toggle state
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const [activeTab, setActiveTab] = useState(1); // Start with QA Management tab

    // Handle tab change
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };





  const fetchReportData = useCallback(async (token) => {
    console.log('Fetching QA report data...');
    if (!token) {
      console.error('No auth token available');
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetch(`${API_BASE_URL}/mydra/course-qa/analytics`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'X-Environment': isProduction ? 'Production' : 'Staging', // Add environment header
        },
        body: JSON.stringify({ mydra_env: isProduction ? 'Production' : 'Staging' }) // Update environment in body
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log('QA report data:', data);  
      setReportData(data);
    } catch (error) {
      console.error('Error fetching QA report data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [isProduction]); // Add isProduction to dependency array


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        user.getIdToken().then((token) => {
          setAuthToken(token);
          fetchReportData(token);
          setUserEmail(user.email);
        });
        console.log('User logged in');
      } else {
        console.log('User not logged in');
        setAuthToken(null);
        setReportData(null);
        setIsLoading(false);
      }
    });

    return () => unsubscribe();
  }, [fetchReportData]);


  const toggleEnvironment = () => {
    setIsProduction(!isProduction);
  };


  const educationProviders = useMemo(() => {
    if (reportData && reportData.recent_qa_logs) {
      return Array.from(new Set(reportData.recent_qa_logs.map(log => log.education_provider_name)));
    }
    return [];
  }, [reportData]);


  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!authToken) {
    return (
      <Typography variant="h6" align="center">
        Please log in to view the QA report.
      </Typography>
    );
  }

  if (!reportData) {
    return (
      <Typography variant="h6" align="center">
        No QA report data available.
      </Typography>
    );
  }


  const { overall_stats, flag_reasons, education_provider_stats, recent_qa_logs } = reportData;


  const overallStatsData = [
    { name: 'Published', value: overall_stats.published },
    { name: 'Flagged', value: overall_stats.flagged_for_review },
    { name: 'Reviewed', value: overall_stats.reviewed },
    { name: 'Pending', value: overall_stats.pending_review },
  ].filter(item => item.value > 0);

  const flagReasonsData = Object.entries(flag_reasons)
    .map(([name, value]) => ({ name, value }))
    .sort((a, b) => b.value - a.value);

  // const hasOverallStats = overallStatsData.length > 0;
  const hasFlagReasons = flagReasonsData.length > 0;

  console.log('overallStatsData:', overallStatsData);
  console.log('flagReasonsData:', flagReasonsData);

    
  const handleDeleteCourse = async (courseId) => {
    try {
      console.log(`Attempting to delete course ${courseId}`);
      
      const response = await fetch(`${API_BASE_URL}/mydra/courses/${courseId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          // 'Content-Type': 'application/json',
          'X-User-Email': userEmail,
          'X-Environment': isProduction ? 'Production' : 'Staging',
        },
      });


      // const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/mydra/courses/${course._id}`, {
      //   method: 'DELETE',
      //   headers: {
      //     'Authorization': `Bearer ${authToken}`,
      //     'X-User-Email': userEmail,
      //     'X-Environment': isProduction ? 'Production' : 'Staging',
      //   },
      // });


  
      // Check for specific status codes
      if (response.status === 204) {
        console.log(`Course ${courseId} deleted successfully`);
        showSnackbar('Course deleted successfully', 'success');
        return true;
      } else if (response.status === 404) {
        console.error(`Course ${courseId} not found`);
        showSnackbar('Course not found', 'error');
        return false;
      } else if (!response.ok) {
        const errorData = await response.json().catch(() => null);
        const errorMessage = errorData?.error || 'Failed to delete course';
        console.error(`Failed to delete course: ${errorMessage}`);
        showSnackbar(errorMessage, 'error');
        return false;
      }
  
      return true;
    } catch (error) {
      console.error('Error deleting course:', error);
      showSnackbar('Failed to delete course: Network error', 'error');
      throw error;
    }
  };


  const handleSubmitReview = async (reviewData) => {
    if (!authToken) return;

    try {
      const response = await fetch(`${API_BASE_URL}/mydra/course-qa/submit-review`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${authToken}`,
          'Content-Type': 'application/json',
          'X-Environment': isProduction ? 'Production' : 'Staging',
        },
        body: JSON.stringify({
          log_id: reviewData.log_id,
          course_id: reviewData.course_id,
          notes: reviewData.notes,
          review_status: reviewData.review_status, // Send the review status instead of ready_to_publish
          mydra_env: isProduction ? 'Production' : 'Staging'
        })
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to submit review');
      }

      // If it's marked for deletion, proceed with course deletion
      // if (reviewData.review_status === REVIEW_STATUS.REVIEWED_FOR_DELETE) {
      //   await handleDeleteCourse(reviewData.course_id);
      // }

      await fetchReportData(authToken);
      showSnackbar('Review submitted successfully', 'success');
    } catch (error) {
      console.error('Error submitting review:', error);
      showSnackbar(error.message || 'Failed to submit review', 'error');
    }
  };

  

  

  return (
    <Container maxWidth={false}>
      <Box sx={{ mb: 3 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Course QA System
        </Typography>

        {/* Environment Toggle and Warning */}
        <Box display="flex" alignItems="center" mb={2}>
          <Typography variant="body1">Staging</Typography>
          <Switch
            checked={isProduction}
            onChange={toggleEnvironment}
            inputProps={{ 'aria-label': 'Environment toggle' }}
          />
          <Typography variant="body1">Production</Typography>
        </Box>

        {isProduction && (
          <Alert severity="warning" sx={{ mb: 2 }}>
            You are in Production mode. This view shows live course data.
          </Alert>
        )}
      </Box>

      {/* Tabs */}
      <Paper sx={{ mb: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={handleTabChange}
          variant="fullWidth"
          sx={{ borderBottom: 1, borderColor: 'divider' }}
        >
          <Tab 
            icon={<ManageSearchIcon />} 
            label="QA Management" 
            value={1}
            sx={{ 
              '&.Mui-selected': { fontWeight: 'bold' },
              minHeight: '72px'
            }}
          />
          <Tab 
            icon={<AnalyticsIcon />} 
            label="Analytics Dashboard" 
            value={0}
            sx={{ 
              '&.Mui-selected': { fontWeight: 'bold' },
              minHeight: '72px'
            }}
          />
        </Tabs>
      </Paper>

      {/* Tab Panels */}
      {activeTab === 0 && (
        <AnalyticsTab 
          overall_stats={overall_stats}
          education_provider_stats={education_provider_stats}
          overallStatsData={overallStatsData}
          flagReasonsData={flagReasonsData}
          hasFlagReasons={hasFlagReasons}
        />
      )}

      {activeTab === 1 && (
        <QAManagementTab 
          recent_qa_logs={recent_qa_logs}
          educationProviders={educationProviders}
          onSubmitReview={handleSubmitReview}
          authToken={authToken}
          isProduction={isProduction}
          showSnackbar={showSnackbar}
          fetchReportData={fetchReportData}
          userEmail={userEmail}
          handleDeleteCourse={handleDeleteCourse}
        />
      )}

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CourseQAPage;

