import React, { memo, useState, useCallback } from 'react';
import { 
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  Typography,
  IconButton,
  InputAdornment,
  useTheme,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  
} from '@mui/material';
import {
  Close as CloseIcon,
  Language as LanguageIcon,
  LinkedIn as LinkedInIcon,
  Business as BusinessIcon,
  ExpandMore as ExpandMoreIcon,
  AutoAwesome as AutoAwesomeIcon,

} from '@mui/icons-material';
import { alpha } from '@mui/material/styles';






const INITIAL_FORM_STATE = {
  name: '',
  url: '',
  linkedin_url: ''
};



// const ProcessExplanation = () => {
//   const theme = useTheme();

//   return (
//     <Box sx={{ mb: 3 }}>
//     <Accordion
//       elevation={0}
//       sx={{
//         backgroundColor: 'background.default',
//         '&:before': { display: 'none' },
//         border: '1px solid',
//         borderColor: 'divider',
//         borderRadius: 2,
//         mb: 2,
//         mt: 1
//       }}
//     >
//       <AccordionSummary
//         expandIcon={<ExpandMoreIcon />}
//         sx={{ 
//           '&.Mui-expanded': { minHeight: 48 },
//           '.MuiAccordionSummary-content.Mui-expanded': { margin: '12px 0' }
//         }}
//       >
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//           <AutoAwesomeIcon sx={{ color: 'primary.main' }} />
//           <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
//             What happens when you add a provider?
//           </Typography>
//         </Box>
//       </AccordionSummary>
//       <AccordionDetails>
//         <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
//           {/* Step 1 */}
//           <Box sx={{ display: 'flex', gap: 2 }}>
//             <Box sx={{ 
//               width: 24, 
//               height: 24, 
//               bgcolor: 'primary.main', 
//               borderRadius: '50%',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               color: 'white',
//               fontSize: '0.875rem',
//               fontWeight: 600 
//             }}>
//               1
//             </Box>
//             <Box>
//               <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
//                 Data Enrichment
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 Extracts data from LinkedIn profile, homepage content, and Google reviews
//               </Typography>
//             </Box>
//           </Box>

//           {/* Step 2 */}
//           <Box sx={{ display: 'flex', gap: 2 }}>
//             <Box sx={{ 
//               width: 24, 
//               height: 24, 
//               bgcolor: 'primary.main', 
//               borderRadius: '50%',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               color: 'white',
//               fontSize: '0.875rem',
//               fontWeight: 600 
//             }}>
//               2
//             </Box>
//             <Box>
//               <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
//                 Provider Setup
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 Creates provider profiles in both staging and production environments if they don't exist, then connects them to the provider you are adding.
//               </Typography>
//             </Box>
//           </Box>

//           {/* Step 3 */}
//           <Box sx={{ display: 'flex', gap: 2 }}>
//             <Box sx={{ 
//               width: 24, 
//               height: 24, 
//               bgcolor: 'primary.main', 
//               borderRadius: '50%',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               color: 'white',
//               fontSize: '0.875rem',
//               fontWeight: 600 
//             }}>
//               3
//             </Box>
//             <Box>
//               <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
//                 Website Crawling
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 AI-powered crawling of the entire website to identify and analyze course pages (may take up to 24 hours)
//               </Typography>
//             </Box>
//           </Box>

//           {/* Step 4 */}
//           <Box sx={{ display: 'flex', gap: 2 }}>
//             <Box sx={{ 
//               width: 24, 
//               height: 24, 
//               bgcolor: 'primary.main', 
//               borderRadius: '50%',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               color: 'white',
//               fontSize: '0.875rem',
//               fontWeight: 600 
//             }}>
//               4
//             </Box>
//             <Box>
//               <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
//                 Course Processing
//               </Typography>
//               <Typography variant="body2" color="text.secondary">
//                 Automatically uploads qualified courses through QA system, flagging others for review
//               </Typography>
//             </Box>
//           </Box>
//         </Box>

//         <Box sx={{ 
//           mt: 2, 
//           p: 2, 
//           bgcolor: alpha(theme.palette.primary.main, 0.05), 
//           borderRadius: 1,
//           border: '1px dashed',
//           borderColor: 'primary.main'
//         }}>
//           <Typography variant="subtitle2" sx={{ 
//             color: 'primary.main', 
//             display: 'flex', 
//             alignItems: 'center', 
//             gap: 1 
//           }}>
//             <AutoAwesomeIcon fontSize="small" />
//             Coming Soon
//           </Typography>
//           <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }}>
//             Automated contact discovery and personalized outreach sequences
//           </Typography>
//         </Box>
//       </AccordionDetails>
//     </Accordion>

//     <Alert 
//       severity="warning"
//       sx={{ 
//         borderRadius: 2,
//         '& .MuiAlert-message': { width: '100%' }
//       }}
//     >
//       <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
//         Important Note
//       </Typography>
//       <Typography variant="body2">
//         Once initiated, this process cannot be stopped. Please ensure you want to proceed with the full automation workflow before submitting.
//       </Typography>
//     </Alert>
//   </Box>
//   );
// };


const ProcessExplanation = ({ isExpanded, onExpandChange }) => {
  const theme = useTheme();

  // Create a reusable style for the number circles
  const numberCircleStyle = {
    width: 28, // Increased from 24 to give more space
    height: 28, // Increased from 24 to give more space
    bgcolor: 'primary.main', 
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '0.875rem',
    fontWeight: 600,
    flexShrink: 0 // Prevent number from shrinking
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Accordion
        elevation={0}
        expanded={isExpanded}
        onChange={onExpandChange}
        sx={{
          backgroundColor: 'background.default',
          '&:before': { display: 'none' },
          border: '1px solid',
          borderColor: 'divider',
          borderRadius: 2,
          mb: 2,
          mt: 1
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ 
            '&.Mui-expanded': { minHeight: 48 },
            '.MuiAccordionSummary-content.Mui-expanded': { margin: '12px 0' }
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AutoAwesomeIcon sx={{ color: 'primary.main' }} />
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              What happens when you add a provider?
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5 }}> {/* Increased gap from 2 to 2.5 */}
            {/* Step 1 */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={numberCircleStyle}>1</Box>
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Data Enrichment
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Extracts data from LinkedIn profile, homepage content, and Google reviews
                </Typography>
              </Box>
            </Box>

            {/* Step 2 */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={numberCircleStyle}>2</Box>
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Provider Setup
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Creates provider profiles in both staging and production environments if they don't exist, then connects them to the provider you are adding
                </Typography>
              </Box>
            </Box>

            {/* Step 3 */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={numberCircleStyle}>3</Box>
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Landing Pages Creation
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Automatically generates and optimizes provider landing pages in both staging and production environments
                </Typography>
              </Box>
            </Box>

            {/* Step 4 */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Box sx={numberCircleStyle}>4</Box>
              <Box>
                <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                  Website Crawling
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  AI-powered crawling of the entire website to identify and analyze course pages (may take up to 24 hours)
                </Typography>
              </Box>
            </Box>

          
            
         
          
          {/* Step 5 */}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={numberCircleStyle}>5</Box>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Initial Course Review
              </Typography>
              <Typography variant="body2" color="text.secondary">
                AI agent reviews all identified courses from the crawler. Courses that pass initial quality checks are automatically queued for upload to staging environment.
              </Typography>
            </Box>
          </Box>

          {/* Step 6 */}
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Box sx={numberCircleStyle}>6</Box>
            <Box>
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                Automated Quality Assurance
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Each uploaded course undergoes 9 automated QA checks. Courses passing all checks are automatically published, while others are flagged for manual review.
              </Typography>
            </Box>
          </Box>
          </Box>

          
          <Box sx={{ 
            mt: 3, // Increased from 2
            p: 2, 
            bgcolor: alpha(theme.palette.primary.main, 0.05), 
            borderRadius: 1,
            border: '1px dashed',
            borderColor: 'primary.main'
          }}>
            <Typography variant="subtitle2" sx={{ 
              color: 'primary.main', 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1 
            }}>
              <AutoAwesomeIcon fontSize="small" />
              Coming Soon
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary', mt: 0.5 }}>
              Automated contact discovery and personalized outreach sequences
            </Typography>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Alert 
        severity="warning"
        sx={{ 
          borderRadius: 2,
          '& .MuiAlert-message': { width: '100%' }
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 600, mb: 0.5 }}>
          Important Note
        </Typography>
        <Typography variant="body2">
          Once initiated, this process cannot be stopped. Please ensure you want to proceed with the full automation workflow before submitting.
        </Typography>
      </Alert>
    </Box>
  );
};


const validateFormData = (formData) => {
  const errors = {};
  if (!formData.name.trim()) {
    errors.name = 'Name is required';
  }
  if (!formData.url.trim()) {
    errors.url = 'URL is required';
  } else if (!/^https?:\/\/.+/.test(formData.url)) {
    errors.url = 'Please enter a valid URL starting with http:// or https://';
  }
  if (formData.linkedin_url && !/^https?:\/\/(www\.)?linkedin\.com\/.+/.test(formData.linkedin_url)) {
    errors.linkedin_url = 'Please enter a valid LinkedIn URL';
  }
  return errors;
};

const AddProviderModal = memo(({ open, onClose, onAdd }) => {
  const theme = useTheme();
  const [formData, setFormData] = useState(INITIAL_FORM_STATE);
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);


  const handleClose = useCallback(() => {
    if (!isSubmitting) {
      setFormData(INITIAL_FORM_STATE);
      setErrors({});
      setIsAccordionExpanded(false); // Reset accordion state
      onClose();
    }
  }, [onClose, isSubmitting]);

  const handleAccordionChange = useCallback((event, expanded) => {
    setIsAccordionExpanded(expanded);
  }, []);

  const handleChange = useCallback((e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    if (errors[name]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  }, [errors]);

  const handleSubmit = useCallback(async () => {
    const newErrors = validateFormData(formData);
    if (Object.keys(newErrors).length === 0) {
        setIsSubmitting(true);
        try {
            const result = await onAdd({
                ...formData,
                status: {
                    enrichment: 'pending',
                    scoring: 'pending',
                    courses: 'pending',
                    outreach: 'pending'
                },
                metrics: {
                    coursesFound: 0,
                    coursesUploaded: 0,
                    contacts: 0
                }
            });

            console.log('Add provider result:', result);

            // Check specifically for the data structure your backend returns
            if (result?.status === 'success' && result?.data?.provider) {
                setFormData(INITIAL_FORM_STATE);
                handleClose();
                return result; // Return the result!
            } else {
                throw new Error('Invalid response format from server');
            }
        } catch (error) {
            console.error('Error adding provider:', error);
            setErrors({
                submit: error.message
            });
            throw error; // Re-throw the error
        } finally {
            setIsSubmitting(false);
        }
    } else {
        setErrors(newErrors);
    }
}, [formData, onAdd, handleClose]);


  return (
    <Dialog 
      open={open} 
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      TransitionProps={{
        unmountOnExit: true
      }}
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: '0 8px 32px rgba(0,0,0,0.1)'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          alignItems: 'center', 
          justifyContent: 'space-between',
          p: 3,
          borderBottom: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Typography variant="h5" component="h2" sx={{ fontWeight: 600 }}>
          Add New Provider
        </Typography>
        <IconButton 
          onClick={handleClose}
          size="small"
          disabled={isSubmitting}
          sx={{ 
            color: 'text.secondary',
            '&:hover': { 
              backgroundColor: theme.palette.error.lighter || alpha(theme.palette.error.light, 0.1),
              color: theme.palette.error.main
            }
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 3 }}>
        <ProcessExplanation 
          isExpanded={isAccordionExpanded}
          onExpandChange={handleAccordionChange}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
            Please provide the details of the education provider you want to add to the system.
          </Typography>

          <TextField
            name="name"
            label="Provider Name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            disabled={isSubmitting}
            fullWidth
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon sx={{ color: isSubmitting ? 'action.disabled' : 'action.active' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
              opacity: isSubmitting ? 0.7 : 1
            }}
          />

          <TextField
            name="url"
            label="Website URL"
            value={formData.url}
            onChange={handleChange}
            error={!!errors.url}
            helperText={errors.url}
            disabled={isSubmitting}
            fullWidth
            placeholder="https://example.com"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LanguageIcon sx={{ color: isSubmitting ? 'action.disabled' : 'action.active' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
              opacity: isSubmitting ? 0.7 : 1
            }}
          />

          <TextField
            name="linkedin_url"
            label="LinkedIn URL (Optional)"
            value={formData.linkedin_url}
            onChange={handleChange}
            error={!!errors.linkedin_url}
            helperText={errors.linkedin_url}
            disabled={isSubmitting}
            fullWidth
            placeholder="https://linkedin.com/company/example"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LinkedInIcon sx={{ color: isSubmitting ? 'action.disabled' : 'action.active' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                '&:hover fieldset': {
                  borderColor: theme.palette.primary.main,
                },
              },
              opacity: isSubmitting ? 0.7 : 1
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions 
        sx={{ 
          p: 3, 
          pt: 2,
          borderTop: '1px solid',
          borderColor: 'divider'
        }}
      >
        <Button 
          onClick={handleClose} 
          color="inherit"
          disabled={isSubmitting}
          sx={{ 
            fontWeight: 500,
            textTransform: 'none',
            px: 3,
            opacity: isSubmitting ? 0.7 : 1
          }}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          variant="contained" 
          color="primary"
          disabled={isSubmitting}
          startIcon={isSubmitting ? <CircularProgress size={20} color="inherit" /> : null}
          sx={{ 
            fontWeight: 500,
            textTransform: 'none',
            px: 3,
            minWidth: 120,
            color: 'white',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            '&:hover': {
              boxShadow: '0 6px 16px rgba(0,0,0,0.2)',
            },
            '&.Mui-disabled': {
              backgroundColor: theme.palette.primary.main,
              opacity: 0.7,
              color: 'white'
            }
          }}
        >
          {isSubmitting ? 'Adding...' : 'Add Provider'}
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default AddProviderModal;


