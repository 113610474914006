

import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Container, Box, Typography, Link, Avatar, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import LanguageIcon from '@mui/icons-material/Language';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const EducationProviderList = () => {
    const [providers, setProviders] = useState([]);
    const navigate = useNavigate();
    const theme = useTheme();
    const [loading, setLoading] = useState(true);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const auth = getAuth();
        onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    fetch(`${API_BASE_URL}/get-education-providers`, {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        setProviders(data);
                        console.log('Education providers:', data);
                        setLoading(false);
                    })
                    .catch(error => {
                        console.error('Error fetching education providers:', error);
                        setLoading(false);
                    });
                });
            } else {
                navigate('/login');
            }
        });
    }, [navigate]);

    const handleManageClick = (site_id) => {
        navigate(`/provider/${site_id}`);
    };

    const handleBack = () => {
        navigate(-1);
    };

    const renderStatus = (status) => {
        switch (status) {
            case 'complete':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                        <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                        <Typography variant="body1">Complete</Typography>
                    </Box>
                );
            case 'processing':
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                        <CircularProgress size={20} sx={{ mr: 1 }} />
                        <Typography variant="body1">Processing</Typography>
                    </Box>
                );
            case '':
                return null;
            default:
                return (
                    <Typography variant="body1">{status}</Typography>
                );
        }
    };


    if (loading) {
        return (
            <Container component="main" maxWidth="lg" sx={{ mt: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                <CircularProgress />
            </Container>
        );
    }

    const getDisplayName = (provider) => {
        if (provider.name) return provider.name;
        
        try {
            if (provider?.url) {
                return new URL(provider.url).hostname;
            }
        } catch (e) {
            console.warn(`Invalid URL for provider:`, provider);
        }
        
        return 'Unknown Provider';
    };

    return (
        <Container component="main" maxWidth="lg" sx={{ mt: 2 }}>
            <Box sx={{ position: 'relative', mt: 0 }}>
                <Button
                    variant="outlined"
                    startIcon={<ArrowBackIosNewIcon />}
                    onClick={handleBack}
                    sx={{
                        mb: 2,
                        color: theme.palette.secondary.main,
                        borderColor: theme.palette.secondary.light,
                        position: 'relative',
                        top: 0, left: 0,
                    }}
                >
                    Go Back
                </Button>

                <Box sx={{
                    p: 2,
                    bgcolor: theme.palette.background.default,
                    boxShadow: theme.shadows[2],
                    borderRadius: theme.shape.borderRadius,
                    textAlign: 'center',
                    mb: 4,
                }}>
                    <Typography variant="h4" component="h1" sx={{
                        fontWeight: 'bold',
                        color: theme.palette.primary.main,
                    }}>
                        Education Providers
                    </Typography>
                </Box>

                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>#</TableCell>
                                <TableCell sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>Name</TableCell>
                                <TableCell sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>Website</TableCell>
                                <TableCell sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>LinkedIn</TableCell>
                                <TableCell sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>Mydra Connection</TableCell>
                                <TableCell sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>Crawling Process</TableCell>
                                
                                <TableCell sx={{ bgcolor: theme.palette.primary.light, color: theme.palette.primary.contrastText }}>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {providers.map((provider, index) => (
                                <TableRow key={provider.site_id}>
                                    <TableCell>{index + 1}</TableCell>
                                    
                                    <TableCell>
                                        <Link
                                            component={RouterLink}
                                            to={`/provider/${provider.site_id}`}
                                            sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
                                        >
                                            <Avatar
                                                // alt={provider.name || new URL(provider?.url).hostname}
                                                alt={getDisplayName(provider)}
                                                src={provider?.profile_pic_url}
                                                sx={{ width: 40, height: 40, mr: 2 }}
                                            />
                                            {/* {provider.name || new URL(provider?.url).hostname} */}
                                            {getDisplayName(provider)}

                                        </Link>
                                    </TableCell>
                                    
                                    <TableCell>
                                        <Link href={provider.url} target="_blank" rel="noopener noreferrer">
                                            <LanguageIcon />
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {provider?.linkedin_url && (
                                            <Link href={provider?.linkedin_url} target="_blank" rel="noopener noreferrer">
                                                <LinkedInIcon />
                                            </Link>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {provider?.mydra_education_provider?.Production ? (
                                            <Box display="flex" alignItems="center">
                                                <Link
                                                    href={`https://www.mydrahub.com/mydra/provider/${provider?.mydra_education_provider?.Production}/${provider?.name}`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    sx={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}
                                                >
                                                    <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                                                        <CheckCircleIcon sx={{ color: 'green', mr: 1 }} />
                                                        <Typography variant="body1">Connected</Typography>
                                                    </Box>
                                                </Link>
                                            </Box>
                                        ) : (
                                            <Box display="flex" alignItems="center">
                                                <LinkOffIcon color="error" sx={{ mr: 1 }} />
                                                Disconnected
                                            </Box>
                                        )}
                                    </TableCell>
                                    <TableCell> 
                                        {renderStatus(provider.status_crawling_process)}
                                    </TableCell>


                                    
                                   
                                    <TableCell>
                                        <IconButton onClick={() => handleManageClick(provider.site_id)}>
                                            <ManageAccountsIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Container>
    );
};

export default EducationProviderList;




