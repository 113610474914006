import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  TableRow,
  TableCell,
  IconButton,
  Avatar,
  Box,
  Typography,
  Tooltip,
  CircularProgress
} from '@mui/material';
import {
  OpenInNew as OpenInNewIcon,
  // PlayArrow as PlayArrowIcon,
  Language as LanguageIcon,
  Link as LinkIcon,
  LinkOff as LinkOffIcon,
  CheckCircle as CheckCircleIcon,
  Schedule as ScheduleIcon,
  Error as ErrorIcon
} from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { alpha } from '@mui/material/styles';
import { useProviderStatusListener } from '../../hooks/useProviderStatusListener';


const StatusChip = ({ status, showProcessingText = false }) => {
  const theme = useTheme();

  const getStatusConfig = (status) => {
    switch (status) {
      case 'completed':
        return { 
          color: theme.palette.success.main, 
          label: 'Completed',
          icon: <CheckCircleIcon sx={{ fontSize: 20 }} />,
          bg: theme.palette.success.lighter || alpha(theme.palette.success.light, 0.1)
        };
      case 'processing':
        return { 
          color: theme.palette.primary.main, 
          label: showProcessingText ? 'Crawling in progress...' : 'Processing',
          icon: <CircularProgress size={18} />,
          bg: theme.palette.primary.lighter || alpha(theme.palette.primary.light, 0.1)
        };
      case 'error':
        return { 
          color: theme.palette.error.main, 
          label: 'Error',
          icon: <ErrorIcon sx={{ fontSize: 20 }} />,
          bg: theme.palette.error.lighter || alpha(theme.palette.error.light, 0.1)
        };
      case 'pending':
      default:
        return { 
          color: theme.palette.text.disabled, 
          label: 'Pending',
          icon: <ScheduleIcon sx={{ fontSize: 20 }} />,
          bg: theme.palette.action.disabledBackground
        };
    }
  };

  const config = getStatusConfig(status);

  return (
    <Tooltip title={config.label}>
      <Box 
        component="span" 
        sx={{ 
          display: 'inline-flex',
          color: config.color,
          alignItems: 'center',
          gap: 1,
          padding: '4px 8px',
          borderRadius: '12px',
          backgroundColor: config.bg,
          transition: 'all 0.2s ease',
          '& svg': {
            transition: 'transform 0.2s ease'
          },
          '&:hover': {
            backgroundColor: alpha(config.color, 0.15),
            '& svg': {
              transform: 'scale(1.1)'
            }
          }
        }}
      >
        {config.icon}
        {showProcessingText && status === 'processing' && (
          <Typography variant="caption">
            {config.label}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
};



const MydraConnectionChip = ({ provider }) => {
  const theme = useTheme();
  const { status } = useProviderStatusListener(provider);

  // Use status instead of provider to check connections
  const hasProductionConnection = status?.mydra_education_provider?.Production;
  const hasStagingConnection = status?.mydra_education_provider?.Staging;

  const getConnectionDetails = () => {
    const connections = [];
    if (hasProductionConnection) {
      connections.push(`Production: ${hasProductionConnection}`);
    }
    if (hasStagingConnection) {
      connections.push(`Staging: ${hasStagingConnection}`);
    }
    
    console.log('[MYDRA_CONNECTION] Connection status update:', {
      providerId: status.site_id || status.id,
      production: hasProductionConnection,
      staging: hasStagingConnection
    });

    return connections.join('\n');
  };

  if (!hasProductionConnection && !hasStagingConnection) {
    return (
      <Tooltip title="Not connected to Mydra">
        <Box 
          sx={{ 
            display: 'inline-flex',
            padding: '2px 6px',
            borderRadius: '8px',
            backgroundColor: alpha(theme.palette.error.main, 0.1),
            color: theme.palette.error.main
          }}
        >
          <LinkOffIcon sx={{ fontSize: 18 }} />
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={getConnectionDetails()}>
      <Box 
        sx={{ 
          display: 'inline-flex',
          padding: '2px 6px',
          borderRadius: '8px',
          backgroundColor: alpha(theme.palette.success.main, 0.1),
          color: theme.palette.success.main
        }}
      >
        <LinkIcon sx={{ fontSize: 18 }} />
      </Box>
    </Tooltip>
  );
};



const ProviderRow = ({ provider, index, timeAgo, onView }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const { status, isProcessing } = useProviderStatusListener(provider);
  
  if (!provider) return null;

  // Debug log to check what data we're receiving
  console.log('[PROVIDER_ROW]', {
    providerId: provider.site_id || provider.id,
    isProcessing,
    currentStatus: status.status,
});

  const handleViewProvider = () => {
    console.log('Navigating to provider:', `/course-stream-ai/provider/${provider.id}`);
    navigate(`/course-stream-ai/provider/${provider.id}`);
  };


  const handleOpenUrl = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const getEnrichmentStatus = () => {
    return {
        status: status.status?.enrichment?.status || 'pending',
        linkedinStatus: status.status?.enrichment?.linkedin || 'pending',
        googleStatus: status.status?.enrichment?.google || 'pending',
        contentStatus: status.status?.enrichment?.homepage || 'pending'
    };
};

const getScore = () => {
  // Use status.score if available, fall back to N/A
  const score = status.score || 'N/A';
  return score === 'N/A' ? score : Number(score).toFixed(2);
};


const getCrawlingStatus = () => {
  const crawlingStatus = status.status?.crawling || {};
  
  return {
    status: crawlingStatus.status || 'pending',
    text: (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
          Courses found: {crawlingStatus.courses_found || 0}
        </Typography>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
          Emails found: {crawlingStatus.emails_found || 0}
        </Typography>
        <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
          Linkedins found: {crawlingStatus.linkedin_urls_found || 0}
        </Typography>
        {/* {crawlingStatus.status === 'processing' && (
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block' }}>
            Pages crawled: {crawlingStatus.pages_crawled || 0}
          </Typography>
        )} */}
      </Box>
    )
  };
};


  const getCourseStatus = () => {
    const status = provider.status?.courses?.status || 'pending';
    // const total = provider.status?.courses?.total || 0;
    const uploaded = provider.status?.courses?.uploaded || 0;
    return {
      status,
      text: `Uploaded: ${uploaded}`
    };
  };

  const getOutreachStatus = () => {
    const status = provider.status?.outreach?.status || 'pending';
    const contacts = provider.status?.outreach?.contacts_identified || 0;
    return {
      status,
      text: `Contacts: ${contacts}`
    };
  };

  const enrichmentStatus = getEnrichmentStatus();
      // const crawlingStatus = getCrawlingStatus();
  const courseStatus = getCourseStatus();
  const outreachStatus = getOutreachStatus();


  return (
    <TableRow 
      hover 
      sx={{ 
        '&:hover': { 
          backgroundColor: alpha(theme.palette.primary.main, 0.04),
          '& .action-buttons': {
            opacity: 1
          }
        }
      }}
    >
      <TableCell sx={{ width: '50px' }}>
        <Typography variant="body2" color="text.secondary">
          {index}
        </Typography>
      </TableCell>

      <TableCell sx={{ width: '250px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', minWidth: 0 }}>
          <Avatar
            src={status.linkedin_data?.profile_pic_url || provider.linkedin_data?.profile_pic_url}
            alt={status.name || provider.name}
            sx={{ 
              width: 40, 
              height: 40, 
              mr: 1.5,
              border: '2px solid',
              borderColor: 'background.paper',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
            }}
          />
          <Box sx={{ minWidth: 0 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
              <Tooltip title={provider.name}>
                <Typography 
                  variant="subtitle2" 
                  sx={{ 
                    fontWeight: 600,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    maxWidth: '150px'
                  }}
                >
                  {provider.name}
                </Typography>
              </Tooltip>
              <MydraConnectionChip provider={provider} />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title={`Visit ${provider.url}`}>
                <IconButton 
                  size="small" 
                  onClick={() => handleOpenUrl(provider.url)}
                  sx={{ 
                    color: theme.palette.primary.main,
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.primary.main, 0.2)
                    }
                  }}
                >
                  <LanguageIcon sx={{ fontSize: 16 }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </TableCell>

      <TableCell>
        <Typography 
          variant="caption" 
          color="text.secondary"
          sx={{ 
            fontStyle: timeAgo ? 'normal' : 'italic',
            display: 'block',
            lineHeight: 1.4
          }}
        >
          {timeAgo || 'No date'}
        </Typography>
      </TableCell>

      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {[
            { status: enrichmentStatus.linkedinStatus, label: 'LinkedIn' },
            { status: enrichmentStatus.googleStatus, label: 'Google' },
            { status: enrichmentStatus.contentStatus, label: 'Content' }
          ].map((item, idx) => (
            <Box key={idx} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <StatusChip status={item.status} />
              <Typography variant="caption" color="text.secondary">
                {item.label}
              </Typography>
            </Box>
          ))}
        </Box>
      </TableCell>

      <TableCell>
          <Typography 
              variant="body2" 
              sx={{ 
                  fontWeight: 600,
                  color: (theme) => {
                      const score = status.score;
                      if (!score || score === 'N/A') return theme.palette.text.secondary;
                      const numScore = Number(score);
                      if (numScore <= 33.33) return theme.palette.error.main;
                      if (numScore <= 66.66) return theme.palette.warning.main;
                      return theme.palette.success.main;
                  }
              }}
          >
              {getScore()}
          </Typography>
      </TableCell>

      {/* <TableCell>
        <Typography 
          variant="body2" 
          sx={{ 
            fontWeight: 600,
            color: (theme) => {
              const score = Number(getScore());
              if (score <= 33.33) return theme.palette.error.main;
              if (score <= 66.66) return theme.palette.warning.main;
              return theme.palette.success.main;
            }
          }}
        >
          {Number(getScore()).toFixed(2)}
        </Typography>
      </TableCell> */}

      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: 32 }}> {/* Fixed height container */}
            <StatusChip 
              status={getCrawlingStatus().status} 
              showProcessingText={getCrawlingStatus().status === 'processing'} 
            />
          </Box>
          {getCrawlingStatus().text}
        </Box>
      </TableCell>

      {/* // Courses Cell */}
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: 32 }}> {/* Fixed height container */}
            {/* <StatusChip status={courseStatus.status} /> */}
            <StatusChip status={getCrawlingStatus().status} />
            </Box>
          <Typography variant="caption" color="text.secondary">
            {courseStatus.text}
          </Typography>
        </Box>
      </TableCell>

      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: 32 }}> {/* Fixed height container */}
            <StatusChip status={outreachStatus.status} />
          </Box>
          <Typography variant="caption" color="text.secondary">
            {outreachStatus.text}
          </Typography>
        </Box>
      </TableCell>

      <TableCell align="right">
        <Box 
          className="action-buttons"
          sx={{ 
            opacity: 0.4,
            transition: 'opacity 0.2s ease'
          }}
        >
          <IconButton 
            size="small" 
            onClick={handleViewProvider}
            sx={{ 
              color: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.2)
              }
            }}
          >
            <OpenInNewIcon sx={{ fontSize: 20 }} />
          </IconButton>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ProviderRow;