
import { Grid, Paper, Typography, Box, Chip, Icon, useTheme, Link } from '@mui/material';
import { 
  LinkedIn as LinkedInIcon,
  Google as GoogleIcon,
  Analytics as AnalyticsIcon,
  CheckCircle as CheckCircleIcon,
  Star as StarIcon,
  Schedule as ScheduleIcon,
  ErrorOutline as ErrorIcon,
  OpenInNew as OpenInNewIcon
} from '@mui/icons-material';
import StatusChip from '../StatusChip';
import { alpha } from '@mui/material/styles';

const EnrichmentCard = ({ title, icon: Icon, status, url, children }) => {
  const theme = useTheme();
  
  return (
    <Paper
      elevation={0}
      sx={{
        height: '100%',
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          borderColor: 'primary.main',
          boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.12)}`
        }
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3 }}>
          <Box
            sx={{
              width: 48,
              height: 48,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              color: 'primary.main'
            }}
          >
            <Icon sx={{ fontSize: 24 }} />
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5 }}>
              {title}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <StatusChip status={status} />
              {url && (
                <Link
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'primary.main',
                    '&:hover': { opacity: 0.8 }
                  }}
                >
                  <OpenInNewIcon sx={{ fontSize: 16 }} />
                </Link>
              )}
            </Box>
          </Box>
        </Box>
        {children}
      </Box>
    </Paper>
  );
};

const ProviderEnrichment = ({ provider }) => {
  const theme = useTheme();

  const renderStarRating = (rating) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography variant="h4" sx={{ fontWeight: 700 }}>
          {rating}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {[1, 2, 3, 4, 5].map((star) => (
            <StarIcon
              key={star}
              sx={{
                color: star <= rating ? 'warning.main' : alpha(theme.palette.warning.main, 0.3),
                fontSize: 20
              }}
            />
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Grid container spacing={3}>
      {/* LinkedIn Enrichment */}
      <Grid item xs={12} md={6}>
        <EnrichmentCard
          title="LinkedIn Data Enrichment"
          url={provider?.linkedin_url}
          icon={LinkedInIcon}
          status={provider.status.enrichment?.linkedin}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
  
            {provider.linkedin_data && (
              <>
                {provider.linkedin_data.company_name && (
                  <Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                      Company Name
                    </Typography>
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                      {provider.linkedin_data.company_name}
                    </Typography>
                  </Box>
                )}

                {provider.linkedin_data.business_description && (
                  <Box>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                      Description
                    </Typography>
                    <Typography variant="body2">
                      {provider.linkedin_data.business_description}
                    </Typography>
                  </Box>
                )}

                <Grid container spacing={2}>
                  {provider.linkedin_data.company_location && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        Location
                      </Typography>
                      <Typography variant="body2">
                        {provider.linkedin_data.company_location}
                      </Typography>
                    </Grid>
                  )}

                  {provider.linkedin_data.year_founded && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        Founded
                      </Typography>
                      <Typography variant="body2">
                        {provider.linkedin_data.year_founded}
                      </Typography>
                    </Grid>
                  )}

                  {provider.linkedin_data.number_of_employees && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        Employees
                      </Typography>
                      <Typography variant="body2">
                        {provider.linkedin_data.number_of_employees}
                      </Typography>
                    </Grid>
                  )}

                  {provider.linkedin_data.number_of_alumni_or_followers && (
                    <Grid item xs={12} sm={6}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                        Followers
                      </Typography>
                      <Typography variant="body2">
                        {provider.linkedin_data.number_of_alumni_or_followers}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </>
            )}
            
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              gap: 1,
              color: 'text.secondary',
              borderTop: '1px dashed',
              borderColor: 'divider',
              pt: 2
            }}>
              <ScheduleIcon sx={{ fontSize: 16 }} />
              <Typography variant="caption">
                Last Updated: {new Date(provider.status.enrichment?.last_updated).toLocaleString()}
              </Typography>
            </Box>
          </Box>
        </EnrichmentCard>
      </Grid>

      {/* Google Reviews */}
      <Grid item xs={12} md={6}>
        <EnrichmentCard
          title="Google Reviews"
          icon={GoogleIcon}
          status={provider.status.enrichment?.google}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Location
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                  {provider.google_reviews?.name || 'Not available'}
                </Typography>
                {provider.google_reviews?.google_maps_url && 
                 provider.google_reviews.google_maps_url !== '' &&
                 provider.google_reviews.google_maps_url !== 'Information not available' && (
                  <Link
                    href={provider.google_reviews.google_maps_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    sx={{
                      color: 'primary.main',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <OpenInNewIcon sx={{ fontSize: 16 }} />
                  </Link>
                )}
              </Box>
              <Typography variant="caption" color="text.secondary" sx={{ mt: 0.5, display: 'block' }}>
                {provider.google_reviews?.reviews || 0} reviews
              </Typography>
            </Box>

            <Box>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Rating
              </Typography>
              {provider.google_reviews?.rating ? 
                renderStarRating(provider.google_reviews.rating) :
                <Typography variant="subtitle1" sx={{ color: 'text.disabled' }}>
                  No ratings available
                </Typography>
              }
            </Box>
          </Box>
        </EnrichmentCard>
      </Grid>

      {/* Content Analysis */}
      <Grid item xs={12}>
        <EnrichmentCard
          title="Homepage Analysis"
          url={provider.url}
          icon={AnalyticsIcon}
          status={provider.status.enrichment?.homepage}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          
            {/* Business Description */}
            {provider.education_provider_analysis?.business_description && (
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  Business Description
                </Typography>
                <Typography sx={{ lineHeight: 1.8 }}>
                  {provider.education_provider_analysis.business_description}
                </Typography>
              </Box>
            )}

            {/* Content Type */}
            {provider.education_provider_analysis?.content_type && (
              <Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  Content Type
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon 
                      sx={{ 
                        fontSize: 20,
                        color: provider.education_provider_analysis.content_type === 'instructor-led' || 
                               provider.education_provider_analysis.content_type === 'both' ? 
                               'success.main' : 'text.disabled'
                      }} 
                    />
                    <Typography>Instructor-led</Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CheckCircleIcon
                      sx={{ 
                        fontSize: 20,
                        color: provider.education_provider_analysis.content_type === 'recorded' || 
                               provider.education_provider_analysis.content_type === 'both' ? 
                               'success.main' : 'text.disabled'
                      }}
                    />
                    <Typography>Recorded</Typography>
                  </Box>
                </Box>
              </Box>
            )}

            {/* Course Type Analysis */}
            {provider.education_provider_analysis?.explanation_for_course_type && (
              <Box 
                sx={{ 
                  p: 3,
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04),
                  borderRadius: 2
                }}
              >
                <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                  Course Type Analysis
                </Typography>
                <Typography sx={{ lineHeight: 1.8 }}>
                  {provider.education_provider_analysis.explanation_for_course_type}
                </Typography>
              </Box>
            )}

            {/* Accreditations */}
            <Box>
              <Typography 
                variant="subtitle2" 
                color="text.secondary"
                sx={{ mb: 2 }}
              >
                Accreditations
              </Typography>
              
              {provider.education_provider_analysis?.accreditations?.length > 0 ? (
                <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                  {provider.education_provider_analysis.accreditations.map((accreditation, index) => (
                    <Chip
                      key={index}
                      label={accreditation}
                      sx={{
                        bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                        color: 'primary.main',
                        fontWeight: 500,
                        '&:hover': {
                          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.12)
                        }
                      }}
                    />
                  ))}
                </Box>
              ) : (
                <Box 
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center',
                    gap: 1,
                    color: 'text.secondary'
                  }}
                >
                  <ErrorIcon sx={{ fontSize: 20 }} />
                  <Typography>No accreditations found</Typography>
                </Box>
              )}
            </Box>
          </Box>
        </EnrichmentCard>
      </Grid>
    </Grid>
  );
};

export default ProviderEnrichment;
