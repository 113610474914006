import React, { useState, useEffect, useCallback, memo, useMemo } from 'react';
import { 
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  CircularProgress,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Alert,
  TablePagination,
  Container
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import FilterListIcon from '@mui/icons-material/FilterList';
import Snackbar from '@mui/material/Snackbar';

import { useNavigate } from 'react-router-dom';

import ProviderRow from './components/ProviderRow';
import AddProviderModal from './components/AddProviderModal';

import { providerService } from '../services/providerService';
import { formatTimeAgo } from '../utils/timeUtills';

import { useTheme } from '@mui/material/styles';


// const AddProviderDialog = memo(({ open, onClose, onAdd }) => {
//   if (!open) return null;
//   return (
//     <AddProviderModal 
//       open={open}
//       onClose={onClose}
//       onAdd={onAdd}
//     />
//   );
// });


const ProviderList = () => {
  console.log("ProviderList is rendering")
  const navigate = useNavigate();
  const theme = useTheme();


  const [providersData, setProvidersData] = useState({
    providers: [],
    isLoading: true,
    error: null,
    pagination: {
      total: 0,
      page: 1,
      pageSize: 20,
      totalPages: 0
    }
  });

  

  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    pageSize: 20,
    sortBy: 'metadata.created_at',
    sortOrder: 'desc'
  });

  const [showAddModal, setShowAddModal] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

      // Memoize all handlers
    const handleShowModal = useCallback(() => {
      setShowAddModal(true);
    }, []);

    const handleModalClose = useCallback(() => {
      setShowAddModal(false);
    }, []);

    // Fetch providers with pagination
    const fetchProviders = useCallback(async () => {
      try {
          setProvidersData(prev => ({ ...prev, isLoading: true }));
          const response = await providerService.listProvidersPaginated(paginationParams);
          
          console.log('Response from server:', response); // Debug log
          
          if (response.status === 'success' && response.data) {
              setProvidersData({
                  providers: response.data.providers || [],
                  pagination: {
                      total: response.data.pagination.total || 0,
                      page: response.data.pagination.page || 1,
                      pageSize: response.data.pagination.pageSize || 20,
                      totalPages: response.data.pagination.totalPages || 0
                  },
                  isLoading: false,
                  error: null
              });
          } else {
              throw new Error('Invalid response format from server');
          }
      } catch (error) {
          console.error('Error fetching providers:', error);
          setProvidersData(prev => ({
              ...prev,
              isLoading: false,
              error: error.message
          }));
          setSnackbar({
              open: true,
              message: 'Error loading providers',
              severity: 'error'
          });
      }
  }, [paginationParams]);

  const handleRefresh = useCallback(() => {
    fetchProviders();
  }, [fetchProviders]);

  useEffect(() => {    
    fetchProviders();
  }, [paginationParams, fetchProviders]); 


  // Handle page change
  const handlePageChange = useCallback((event, newPage) => {
    console.log('Page change triggered:', { newPage, currentPage: providersData.pagination.page });
    setProvidersData(prev => ({ ...prev, isLoading: true }));
    setPaginationParams(prev => {
        const newParams = {
            ...prev,
            page: newPage + 1  // MUI uses 0-based indexing
        };
        console.log('New pagination params:', newParams);
        return newParams;
    });
  }, [providersData.pagination.page]);


  const handleRowsPerPageChange = useCallback((event) => {
    const newPageSize = parseInt(event.target.value, 10);
    console.log('Changing page size to:', newPageSize); // Debug log
    setProvidersData(prev => ({ ...prev, isLoading: true }));
    setPaginationParams(prev => ({
        ...prev,
        page: 1, // Reset to first page
        pageSize: newPageSize
    }));
  }, []);



  // Handle adding a provider without refetching the entire list
  const handleAddProvider = useCallback(async (providerData) => {
    try {
        const response = await providerService.addProvider(providerData);
        console.log('Add provider response:', response);

        if (response.status === 'success' && response.data?.provider) {
            // Add the new provider and sort the list
            setProvidersData(prev => {
                const newProviders = [...prev.providers, response.data.provider]
                    .sort((a, b) => {
                        const dateA = a.metadata?.created_at;
                        const dateB = b.metadata?.created_at;
                        if (!dateA && !dateB) return 0;
                        if (!dateA) return 1;
                        if (!dateB) return -1;
                        return new Date(dateB) - new Date(dateA);
                    });
                return {
                    ...prev,
                    providers: newProviders
                };
            });

            // Show success message
            setSnackbar({
                open: true,
                message: 'Provider added successfully',
                severity: 'success'
            });

            // Close modal
            setShowAddModal(false);

            // If there were any warnings, show them
            if (response.warnings?.length) {
                console.warn('Provider created with warnings:', response.warnings);
                setSnackbar({
                    open: true,
                    message: 'Provider added with warnings',
                    severity: 'warning'
                });
            }

            return response; // Return the response!
        } else {
            throw new Error('Invalid response format from server');
        }
    } catch (error) {
        console.error('Error adding provider:', error);
        setSnackbar({
            open: true,
            message: error.message || 'Error adding provider',
            severity: 'error'
        });
        throw error; // Re-throw the error
    }
}, []);

  // Memoized handlers
  const handleCloseSnackbar = useCallback(() => {
    setSnackbar(prev => ({ ...prev, open: false }));
  }, []);


  // Enhanced header with improved styling
  const renderHeader = useMemo(() => (
    <Box 
      sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        mb: 4,
        background: theme.palette.background.paper,
        borderRadius: 2,
        p: 3,
        boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
      }}
    >
      <Box>
        <Typography 
          variant="h4" 
          sx={{ 
            fontWeight: 600,
            background: `linear-gradient(45deg, ${theme.palette.primary.main}, ${theme.palette.primary.light})`,
            backgroundClip: 'text',
            textFillColor: 'transparent',
            mb: 1
          }}
        >
          CourseStream AI
        </Typography>
        <Typography 
          variant="subtitle1" 
          color="text.secondary"
          sx={{ 
            maxWidth: '600px',
            lineHeight: 1.6
          }}
        >
          Manage your education providers
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={handleShowModal}
          sx={{ 
            boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
            textTransform: 'none',
            px: 3,
            py: 1
          }}
        >
          Add Provider
        </Button>
        <IconButton 
          onClick={handleRefresh}
          sx={{ 
            backgroundColor: 'background.paper',
            '&:hover': { backgroundColor: 'action.hover' },
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
          }}
        >
          <RefreshIcon />
        </IconButton>
        <IconButton
          sx={{ 
            backgroundColor: 'background.paper',
            '&:hover': { backgroundColor: 'action.hover' },
            boxShadow: '0 2px 4px rgba(0,0,0,0.05)'
          }}
        >
          <FilterListIcon />
        </IconButton>
      </Box>
    </Box>
  ), [handleShowModal, handleRefresh, theme]);

  return (
    <Container maxWidth="xl" sx={{ py: 4 }}>
      {renderHeader}
      
      <Paper 
        elevation={0}
        sx={{ 
          borderRadius: 2,
          overflow: 'hidden',
          border: '1px solid',
          borderColor: 'divider',
          boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
        }}
      >
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell 
                  sx={{ 
                    width: '40px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  #
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '250px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  Provider
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '80px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  Added
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '120px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  Enrichment
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '80px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  Score
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '120px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  Crawling
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '120px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  Courses
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '120px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }}
                >
                  Outreach
                </TableCell>
                <TableCell 
                  sx={{ 
                    width: '100px',
                    backgroundColor: 'background.default',
                    fontWeight: 600
                  }} 
                  align="right"
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providersData.isLoading ? (
                <TableRow>
                  <TableCell colSpan={9} align="center" sx={{ py: 6 }}>
                    <CircularProgress size={40} />
                  </TableCell>
                </TableRow>
              ) : providersData.providers.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center" sx={{ py: 6 }}>
                    <Typography color="text.secondary" sx={{ fontWeight: 500 }}>
                      No providers found
                    </Typography>
                  </TableCell>
                </TableRow>
              ) : (
                providersData.providers.map((provider, index) => (
                  <ProviderRow 
                    key={provider?.site_id || provider?.id || index}
                    provider={provider} 
                    index={(providersData.pagination.page - 1) * providersData.pagination.pageSize + index + 1}
                    timeAgo={formatTimeAgo(provider?.metadata?.created_at)}
                    onView={() => navigate(`/coursestream/provider/${provider?.id}`)}
                    onRefresh={fetchProviders}
                  />
                ))
              )}
            </TableBody>
          </Table>
          <TablePagination
            component="div"
            count={providersData.pagination.total}
            page={providersData.pagination.page - 1}
            onPageChange={handlePageChange}
            rowsPerPage={providersData.pagination.pageSize}
            onRowsPerPageChange={handleRowsPerPageChange}
            rowsPerPageOptions={[10, 20, 50]}
            sx={{
              borderTop: '1px solid',
              borderColor: 'divider',
              backgroundColor: 'background.default'
            }}
          />
        </TableContainer>
      </Paper>

      <AddProviderModal 
        open={showAddModal}
        onClose={handleModalClose}
        onAdd={handleAddProvider}
      />

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          variant="filled"
          sx={{ 
            boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
            '& .MuiAlert-icon': { fontSize: '24px' }
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default ProviderList;


