import { useState, useEffect } from 'react';    
import { 
  Grid, 
  Paper, 
  Typography, 
  Box, 
  CircularProgress,
  Link,
  Button,
  useTheme,
  Tooltip,
  IconButton,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import {
  Schedule as ScheduleIcon,
  Search as SearchIcon,
  LinkedIn as LinkedInIcon,
  Email as EmailIcon,
  OpenInNew as OpenInNewIcon,
  School as SchoolIcon,
  LocationOn as LocationIcon,
  AttachMoney as CostIcon,
  Dashboard as DashboardIcon,
  Storefront as StorefrontIcon,
  AccessTime as AccessTimeIcon,
  Upload as UploadIcon,
  Replay as ReplayIcon
} from '@mui/icons-material';
import StatusChip from '../StatusChip';
import { providerService } from '../../services/providerService';
import { courseMatchingService } from '../../services/courseMatchingService';
import { formatTimeAgo } from '../../utils/timeUtills';
import { alpha } from '@mui/material/styles';

import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebaseConfig';

const StatCard = ({ value, label, icon: Icon, trend }) => {
  const theme = useTheme();
  
  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        border: '1px solid',
        borderColor: 'divider',
        bgcolor: 'background.paper',
        height: '100%',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: `0 12px 24px ${alpha(theme.palette.primary.main, 0.08)}`,
          borderColor: 'primary.main'
        }
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          mb: 2
        }}
      >
        <Box
          sx={{
            width: 48,
            height: 48,
            borderRadius: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
            color: 'primary.main'
          }}
        >
          <Icon sx={{ fontSize: 24 }} />
        </Box>
        <Typography variant="subtitle2" color="text.secondary">
          {label}
        </Typography>
      </Box>

      <Typography 
        variant="h3" 
        sx={{ 
          fontWeight: 700,
          color: 'primary.main',
          mb: 1
        }}
      >
        {value}
      </Typography>

      {trend && (
        <Typography 
          variant="caption" 
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: 'text.secondary'
          }}
        >
          <ScheduleIcon sx={{ fontSize: 14 }} />
          {trend}
        </Typography>
      )}
    </Box>
  );
};

const CourseItem = ({ course, index, provider, onStatusUpdate }) => {
  const theme = useTheme();
  const [uploadConfirmOpen, setUploadConfirmOpen] = useState(false);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const [isUploading, setIsUploading] = useState({
    Production: false,
    Staging: false
  });


  const getMatchUrls = (mydraId, environment) => {
    const isProduction = environment === 'Production';
    return {
      hub: `https://www.mydrahub.com/mydra/course/${mydraId}${!isProduction ? '?env=Staging' : ''}`,
      marketplace: `https://marketplace${!isProduction ? '.staging' : ''}.mydra.io/en/courses/${mydraId}`
    };
  };

  const handleUploadClick = (environment) => {
    setSelectedEnvironment(environment);
    setUploadConfirmOpen(true);
  };

  const handleUploadConfirm = async () => {
    if (!selectedEnvironment) return;
    setIsConfirmLoading(true);

    try {
        // Update Firestore
        const courseDocRef = doc(db, 'sites', provider.id, 'identified_urls', course.id);
        await updateDoc(courseDocRef, {
            [`mydra_matching_status.${selectedEnvironment}`]: 'uploading',
        });

        // Update local state immediately
        onStatusUpdate(course.id, selectedEnvironment, 'uploading');

        // Close the dialog but keep the loading state
        setUploadConfirmOpen(false);

        const courseData = {
            courseUrl: course.url,
            additionalInfo: {
                name: course.result.course_name,
                category: course.result.course_category,
                cost: course.result.course_cost,
                location: course.result.course_location
            }
        };

        const providerId = provider?.mydra_education_provider?.[selectedEnvironment];
        if (!providerId) {
            throw new Error(`No provider ID found for ${selectedEnvironment}`);
        }

        const response = await providerService.uploadCourse(providerId, courseData);
        
        if (response.status === 'success') {
            // Update both Firestore and local state with success
            await updateDoc(courseDocRef, {
                [`mydra_matching_status.${selectedEnvironment}`]: 'matched',
                [`mydra_course_ids.${selectedEnvironment}`]: [response.data?.course_id]
            });
            onStatusUpdate(course.id, selectedEnvironment, 'matched', response.data?.course_id);
        }

    } catch (error) {
        console.error('Error uploading course:', error);
        // Update both Firestore and local state with error
        const courseDocRef = doc(db, 'sites', provider.id, 'identified_urls', course.id);
        await updateDoc(courseDocRef, {
            [`mydra_matching_status.${selectedEnvironment}`]: 'error',
        });
        onStatusUpdate(course.id, selectedEnvironment, 'error');
    } finally {
        setIsConfirmLoading(false);
        setSelectedEnvironment(null);
    }
  };

  const handleCloseConfirm = () => {
    setUploadConfirmOpen(false);
    setSelectedEnvironment(null);
  };


  const handleUploadCourse = async (environment) => {
    console.log("Clicked upload button for", environment)
    console.log("provider", provider)
    console.log("course", course)

    setIsUploading(prev => ({ ...prev, [environment]: true }));
    try {

      // First update the course status to 'uploading' in Firestore
      const courseDocRef = doc(db, 'sites', provider.id, 'identified_urls', course.id);
      await updateDoc(courseDocRef, {
        [`mydra_matching_status.${environment}`]: 'uploading',
      });


      // Create course data object
      const courseData = {
        courseUrl: course.url,
        additionalInfo: {
          name: course.result.course_name,
          category: course.result.course_category,
          cost: course.result.course_cost,
          location: course.result.course_location
        }
      };

      // Get the correct provider ID based on environment
      const providerId = provider?.mydra_education_provider?.[environment];
      if (!providerId) {
        throw new Error(`No provider ID found for ${environment}`);
      }

      // Call the upload endpoint
      const response = await providerService.uploadCourse(providerId, courseData);
      console.log('Course uploaded:', response);

      // Show success message (you'll need to implement this)
      // toast.success(`Course uploaded successfully to ${environment}`);

    } catch (error) {
      console.error('Error uploading course:', error);
      // Show error message (you'll need to implement this)
      // toast.error(`Failed to upload course to ${environment}: ${error.message}`);
    } finally {
      setIsUploading(prev => ({ ...prev, [environment]: false }));
    }
  };

  const getMatchStatus = (environment) => {
    // First check the matching status directly
    const matchingStatus = course?.mydra_matching_status?.[environment];
    const mydraIds = course?.mydra_course_ids?.[environment] || [];
    
    
    // If status is "matched" and we have IDs, return success state
    if (matchingStatus === 'matched' && mydraIds.length > 0) {
        return {
            status: 'completed',  // Changed to match StatusChip expectations
            label: `${mydraIds.length} match${mydraIds.length > 1 ? 'es' : ''}`,
            color: 'success',
            matches: mydraIds.map(id => ({
                id,
                urls: getMatchUrls(id, environment)
            }))
        };
    }

    // If status is "no_match", return error state
    if (matchingStatus === 'no_match') {
        return {
            status: 'error',  // Changed to match StatusChip expectations
            label: 'No Match',
            color: 'error'
        };
    }

    // For any other state (not matched, not no_match)
    return {
        status: 'pending',  // Changed to match StatusChip expectations
        label: 'Not Processed',
        color: 'default'
    };
  };

const MatchLinks = ({ match, environment }) => (
  <Box sx={{ 
    display: 'flex', 
    alignItems: 'center',
    gap: 1,
    py: 0.5
  }}>
    <Tooltip title={`Mydra ID: ${match.id}`}>
      <Box sx={{ 
        bgcolor: alpha(theme.palette.primary.main, 0.1),
        px: 1,
        py: 0.5,
        borderRadius: 1,
        fontSize: '0.75rem',
        fontFamily: 'monospace'
      }}>
        {match.id.slice(0, 8)}...
      </Box>
    </Tooltip>
    
    <Tooltip title="Open in Mydra Hub">
      <IconButton 
        size="small"
        href={match.urls.hub}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ 
          color: 'primary.main',
          '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.1) }
        }}
      >
        <DashboardIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>

    <Tooltip title="Open in Marketplace">
      <IconButton 
        size="small"
        href={match.urls.marketplace}
        target="_blank"
        rel="noopener noreferrer"
        sx={{ 
          color: 'primary.main',
          '&:hover': { bgcolor: alpha(theme.palette.primary.main, 0.1) }
        }}
      >
        <StorefrontIcon sx={{ fontSize: 18 }} />
      </IconButton>
    </Tooltip>
  </Box>
);





  return (
    <Box
      sx={{
        p: 3,
        borderBottom: '1px solid',
        borderColor: 'divider',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.04)
        }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', mb: 2 }}>
        <Typography 
            variant="body2" 
            sx={{ 
              fontWeight: 600,
              color: 'text.secondary',
              minWidth: '2rem',
              height: '2rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '50%',
              marginRight: 2,
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              border: '1px solid',
              borderColor: (theme) => alpha(theme.palette.primary.main, 0.2)
            }}
          >
            {index + 1}
          </Typography>
        
        <Typography 
          variant="h6" 
          sx={{ 
            fontWeight: 600,
            color: 'text.primary',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap'
          }}
        >
          {course.result.course_name}
          {course.id && (
            <Typography
              variant="caption"
              sx={{
                ml: 1,
                color: 'text.secondary',
                alignSelf: 'center'
              }}
            >
              (ID: {course.id})
            </Typography>
          )}
        </Typography>
        <Link
          href={course.url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            marginLeft: 2,
            color: 'primary.main',
            textDecoration: 'none',
            '&:hover': {
              textDecoration: 'underline'
            }

          }}
        >
          <OpenInNewIcon sx={{ fontSize: 16 }} />
        </Link>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <SchoolIcon sx={{ color: 'action.active', fontSize: 20 }} />
              <Typography variant="body2" color="text.secondary">
                Category: {course.result.course_category || 'Not specified'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <CostIcon sx={{ color: 'action.active', fontSize: 20 }} />
              <Typography variant="body2" color="text.secondary">
                Cost: {course.result.course_cost || 'Not specified'}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <LocationIcon sx={{ color: 'action.active', fontSize: 20 }} />
              <Typography variant="body2" color="text.secondary">
                Location: {course.result.course_location || 'Not specified'}
              </Typography>
            </Box>
            {/* Last Updated moved here */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <ScheduleIcon sx={{ color: 'action.active', fontSize: 20 }} />
              <Typography variant="body2" color="text.secondary">
                Last Updated: {formatTimeAgo(course.timestamp)}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            gap: 2
          }}>
            {/* Status section */}
            <Box sx={{ 
              display: 'flex',
              gap: 4,
              mt: 1
            }}>
              {['Production', 'Staging'].map(environment => {
                const matchStatus = getMatchStatus(environment);
                const isUploading = course?.mydra_matching_status?.[environment] === 'uploading';
                const isError = course?.mydra_matching_status?.[environment] === 'error';
                return (
                  <Box key={environment}>
                        {/* Title and Status on same line */}
                        <Box sx={{ 
                          display: 'flex', 
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                          mb: 1
                        }}>
                          <Typography 
                            variant="body2" 
                            color="text.secondary"
                            sx={{ mr: 1 }}
                          >
                            {environment} Status
                          </Typography>
                          <StatusChip 
                            status={
                              course?.mydra_matching_status?.[environment] === 'uploading' ? 'processing' : 
                              course?.mydra_matching_status?.[environment] === 'error' ? 'error' : 
                              matchStatus.status
                            }
                            size="small"
                            sx={{ ml: 2 }}
                          />
                          {/* Add upload button if status is not completed */}
                          {matchStatus.status !== 'completed' && (
                          <Tooltip title={isUploading ? 'Uploading...' : `Upload to ${environment}`}>
                              <IconButton
                                size="small"
                                onClick={() => handleUploadClick(environment)}
                                disabled={
                                  isUploading || 
                                  !provider?.mydra_education_provider?.[environment] ||
                                  course?.mydra_matching_status?.[environment] === 'matched'
                                }
                                // disabled={
                                //   course?.mydra_matching_status?.[environment] === 'uploading' || 
                                //   !provider?.mydra_education_provider?.[environment] ||
                                //   course?.mydra_matching_status?.[environment] === 'matched'
                                // }
                                sx={{ ml: 1 }}
                              >
                                {isUploading ? (
                                  <CircularProgress size={16} />
                                ) : isError ? (
                                  <ReplayIcon sx={{ fontSize: 20 }} />
                                ) : (
                                  <UploadIcon sx={{ fontSize: 20 }} />
                                )}
                              </IconButton>

                            </Tooltip>
                          )}
                        </Box>
                        {/* Matches below */}
                        {matchStatus.matches && (
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                            {matchStatus.matches.map((match, index) => (
                              <MatchLinks 
                                key={`${match.id}-${index}`}
                                match={match}
                                environment={environment}
                              />
                            ))}
                          </Box>
                        )}
                      </Box>
                    );
                  })}         
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* Confirmation Dialog */}
      <Dialog
        open={uploadConfirmOpen}
        onClose={!isConfirmLoading ? handleCloseConfirm : undefined}
        aria-labelledby="upload-confirm-dialog-title"
        aria-describedby="upload-confirm-dialog-description"
      >
        <DialogTitle id="upload-confirm-dialog-title">
          Confirm Course Upload
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="upload-confirm-dialog-description">
            Are you sure you want to upload this course to {selectedEnvironment}?
            <Box mt={2}>
              <Typography variant="subtitle2" color="text.secondary">
                Course: {course.result.course_name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                URL: {course.url}
              </Typography>
            </Box>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleCloseConfirm} 
            color="primary"
            disabled={isConfirmLoading}
          >
            Cancel
          </Button>
          <Button
            onClick={handleUploadConfirm}
            color="primary"
            variant="contained"
            disabled={isConfirmLoading}
            startIcon={isConfirmLoading ? <CircularProgress size={20} /> : null}
          >
            {isConfirmLoading ? 'Uploading...' : 'Upload'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

const LinkedInProfilesList = ({ urls }) => {
  const theme = useTheme();
  
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {urls?.map((url, index) => (
        <Link
          key={index}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            color: 'primary.main',
            textDecoration: 'none',
            p: 2,
            borderRadius: 1,
            transition: 'all 0.2s ease-in-out',
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
              transform: 'translateX(4px)'
            }
          }}
        >
          <LinkedInIcon sx={{ fontSize: 20 }} />
          <Typography variant="body2" sx={{ flex: 1 }}>
            {url}
          </Typography>
          <OpenInNewIcon sx={{ fontSize: 16, opacity: 0.7 }} />
        </Link>
      ))}
    </Box>
  );
};


const ProviderCrawling = ({ provider, onUpdate }) => {
    const [providerData, setProviderData] = useState(provider);
    // const [courses, setCourses] = useState({ data: [], loading: false });

    const [courses, setCourses] = useState({ 
        data: [], 
        loading: false,
        pagination: {
            total: 0,
            limit: 100,
            offset: 0
        }
    });
    const [page, setPage] = useState(1);

    const [refreshInterval] = useState(30); // Fixed 30-second interval
    const [countdown, setCountdown] = useState(refreshInterval);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [isMatching, setIsMatching] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(false);

    const crawlingStatus = providerData.status.crawling;

    const handleMatchCourses = async (environment) => {
      setIsMatching(true);
      try {
          await courseMatchingService.matchCourses(
              provider.id,
              environment,
              provider?.mydra_education_provider?.[environment],
              courses.data
          );
          
          // Reset page to 1 before fetching data
          setPage(1);
          // Fetch data with offset 0
          await fetchAllData(0);
          
      } catch (error) {
          console.error('Error matching courses:', error);
          // Show error message
      } finally {
          setIsMatching(false);
      }
  };

    const fetchAllData = async (offset = 0) => {
        const isInitialLoad = offset === 0;
          if (isInitialLoad) {
              setIsRefreshing(true);
          } else {
              setIsLoadingPage(true);
          }

        // setIsRefreshing(true);
        try {
            // Fetch provider data
            const providerResponse = await providerService.getProvider(provider.id);
            if (providerResponse.status === 'success') {
                setProviderData(providerResponse.data);
                if (onUpdate) {
                    onUpdate(providerResponse.data);
                }
            }

            // Fetch courses data
            setCourses(prev => ({ ...prev, loading: true }));
            // const coursesResponse = await providerService.getIdentifiedCourses(provider.id);
            const coursesResponse = await providerService.getIdentifiedCourses(provider.id, {
                offset,
                limit: 100 // You could make this configurable
            });
              
          if (coursesResponse.status === 'success') {
                // const coursesOnly = coursesResponse.data.filter(item => 
                //     item.result?.page_type === 'course' && item.status === 'success'
                // );
                // setCourses({ data: coursesResponse.data, loading: false });
                setCourses({ 
                  data: coursesResponse.data, 
                  loading: false,
                  pagination: coursesResponse.pagination
              });
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setCourses(prev => ({ ...prev, loading: false }));
        } finally {
          if (isInitialLoad) {
            setIsRefreshing(false);
          } else {
              setIsLoadingPage(false);
          } 
            // setIsRefreshing(false);
        }
    };
    // Handle page change
    const handlePageChange = async (event, newPage) => {
      setPage(newPage);
      // Adjust the offset calculation (page is 1-based, but offset is 0-based)
      const newOffset = (newPage - 1) * courses.pagination.limit;
      console.log('Changing page:', { newPage, newOffset, limit: courses.pagination.limit });
      await fetchAllData(newOffset);
  
      // Scroll the courses container to top
      const coursesContainer = document.querySelector('.courses-container');
      if (coursesContainer) {
          coursesContainer.scrollTo({
              top: 0,
              behavior: 'smooth'
          });
      }
  };

    // Initial data fetch
    useEffect(() => {
        if (provider.id) {
            fetchAllData();
        }
    }, [provider.id]);

    // Countdown and refresh logic
    useEffect(() => {
        let intervalId;

        if (crawlingStatus?.status === 'processing' && !isRefreshing) {
            intervalId = setInterval(() => {
                setCountdown(prev => {
                    if (prev <= 1) {
                        // When countdown reaches 0, trigger fetch and reset countdown
                        fetchAllData();
                        return refreshInterval;
                    }
                    return prev - 1;
                });
            }, 1000);
        } else if (crawlingStatus?.status !== 'processing') {
            setCountdown(refreshInterval); // Reset countdown when processing stops
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [crawlingStatus?.status, isRefreshing, refreshInterval]);


    // Add a function to update a single course's status
    const updateCourseStatus = (courseId, environment, status, mydraId = null) => {
        setCourses(prevCourses => ({
            ...prevCourses,
            data: prevCourses.data.map(course => {
                if (course.id === courseId) {
                    return {
                        ...course,
                        mydra_matching_status: {
                            ...course.mydra_matching_status,
                            [environment]: status
                        },
                        ...(mydraId && {
                            mydra_course_ids: {
                                ...course.mydra_course_ids,
                                [environment]: [mydraId]
                            }
                        })
                    };
                }
                return course;
            })
        }));
    };







    
    return (
        <Grid container spacing={3}>
          {/* Crawling Status Section */}
          <Grid item xs={12}>
            <Paper
              elevation={0}
              sx={{ 
                p: 4,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                <Box>
                  <Typography variant="h5" sx={{ fontWeight: 600, mb: 1 }}>
                    Crawling Status
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <StatusChip status={crawlingStatus?.status} />
                    <Typography variant="body2" color="text.secondary">
                      Last Updated: {formatTimeAgo(new Date(crawlingStatus?.last_updated).getTime() + 60 * 60 * 1000)}
                    </Typography>
                    {crawlingStatus?.status === 'processing' && (
                      <Box 
                        sx={{ 
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          px: 2,
                          py: 0.5,
                          borderRadius: 2,
                          bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                          color: 'primary.main'
                        }}
                      >
                        {isRefreshing ? (
                          <>
                            <CircularProgress size={16} color="inherit" />
                            <Typography variant="body2">Refreshing...</Typography>
                          </>
                        ) : (
                          <>
                            <ScheduleIcon sx={{ fontSize: 16 }} />
                            <Typography variant="body2">Next refresh in {countdown}s</Typography>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
    
              <Grid container spacing={3}>
                <Grid item xs={12} md={4}>
                  <StatCard
                    value={crawlingStatus?.courses_found}
                    label="Courses Found"
                    icon={SearchIcon}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StatCard
                    value={crawlingStatus?.linkedin_urls_found}
                    label="LinkedIn Profiles Found"
                    icon={LinkedInIcon}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <StatCard
                    value={crawlingStatus?.emails_found}
                    label="Email Addresses Found"
                    icon={EmailIcon}
                  />
                </Grid>
              </Grid>
    
              {crawlingStatus?.started_at && crawlingStatus?.completed_at && (
                <Box 
                  sx={{ 
                    mt: 1,
                    p: 1,
                    borderRadius: 2,
                    bgcolor: (theme) => alpha(theme.palette.background.default, 0.6)
                  }}
                >
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Crawling Duration
                  </Typography>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ScheduleIcon sx={{ color: 'text.secondary' }} />
                        <Typography>
                          Started: {new Date(crawlingStatus.started_at).toLocaleString()}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <ScheduleIcon sx={{ color: 'text.secondary' }} />
                        <Typography>
                          Completed: {new Date(crawlingStatus.completed_at).toLocaleString()}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <AccessTimeIcon sx={{ color: 'text.secondary' }} />
                        <Typography>
                          {(() => {
                            const start = new Date(crawlingStatus.started_at);
                            const end = new Date(crawlingStatus.completed_at);
                            const diff = Math.abs(end - start);
                            const hours = Math.floor(diff / (1000 * 60 * 60));
                            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                            return `Duration: ${hours}h ${minutes}m`;
                          })()}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Paper>
          </Grid>
    
          {/* LinkedIn Profiles Section */}
          <Grid item xs={12} md={4}>
            <Paper
              elevation={0}
              sx={{ 
                p: 4,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                height: '100%'
              }}
            >
              <Typography variant="h6" sx={{ mb: 3, fontWeight: 600 }}>
                Identified LinkedIn Profiles
              </Typography>
              <Box sx={{ height: '600px', overflow: 'auto' }}>
                <LinkedInProfilesList urls={providerData.identified_linkedin_urls} />
              </Box>
            </Paper>
          </Grid>
    
          {/* Courses Section */}
          {/* Courses Section */}
          <Grid item xs={12} md={8}>
            <Paper
              elevation={0}
              sx={{ 
                p: 4,
                borderRadius: 2,
                border: '1px solid',
                borderColor: 'divider',
                height: '100%'
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  Identified Courses
                </Typography>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => handleMatchCourses('Production')}
                    disabled={isMatching || !provider?.mydra_education_provider?.Production}
                    startIcon={isMatching ? <CircularProgress size={20} /> : null}
                  >
                    Match Production
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleMatchCourses('Staging')}
                    disabled={isMatching || !provider?.mydra_education_provider?.Staging}
                    startIcon={isMatching ? <CircularProgress size={20} /> : null}
                  >
                    Match Staging
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                  <Typography variant="body2" color="text.secondary">
                    Courses Found: {courses.pagination.total}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Showing {courses.data.length} of {courses.pagination.total}
                  </Typography>
                </Box>
              </Box>

              {courses.loading ? (
                <Box 
                  sx={{ 
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 2,
                    py: 8
                  }}
                >
                  <CircularProgress />
                  <Typography color="text.secondary">
                    Loading courses...
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ position: 'relative' }}>
                  {isLoadingPage && (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'rgba(255,255,255,0.7)',
                        zIndex: 1,
                        borderRadius: 1
                      }}
                    >
                      <CircularProgress />
                    </Box>
                  )}
                  
                  <Box sx={{ height: '600px', overflow: 'auto' }} className="courses-container">
                    {courses.data.map((course, index) => (
                      <CourseItem 
                        key={course.id} 
                        course={course} 
                        index={((page - 1) * courses.pagination.limit) + index}
                        provider={provider}
                        onStatusUpdate={updateCourseStatus}
                      />
                    ))}
                  </Box>
                  
                  <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    pt: 3,
                    borderTop: '1px solid',
                    borderColor: 'divider',
                    mt: 3
                  }}>
                    <Pagination 
                      count={Math.ceil(courses.pagination.total / courses.pagination.limit)}
                      page={page}
                      onChange={handlePageChange}
                      color="primary"
                      size="large"
                    />
                  </Box>
                </Box>
              )}
            </Paper>
          </Grid>
        </Grid>
      );
    };
    
    export default ProviderCrawling;
    