import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  Tabs,
  Tab,
  Button,
  CircularProgress,
  Avatar,
  Container,
  IconButton,
  useTheme,
  alpha
} from '@mui/material';
import { 
  ArrowBack as ArrowBackIcon,
  Public as PublicIcon,
  LinkedIn as LinkedInIcon,
  Refresh as RefreshIcon
} from '@mui/icons-material';
import StatusChip from './StatusChip';
import { providerService } from '../services/providerService';
import ProviderOverview from './tabs/ProviderOverview';
import ProviderEnrichment from './tabs/ProviderEnrichment';
import ProviderCrawling from './tabs/ProviderCrawling';
import ProviderCourses from './tabs/ProviderCourses';

const ProviderHeader = ({ provider, onRefresh }) => {
  const theme = useTheme();
  
  const handleVisitWebsite = () => {
    window.open(provider?.url, '_blank', 'noopener,noreferrer');
  };

  const handleVisitLinkedIn = () => {
    window.open(provider?.linkedin_url, '_blank', 'noopener,noreferrer');
  };

  return (
    <Box sx={{ 
      position: 'relative',
      height: '280px',
      mb: 4,
      borderRadius: 3,
      overflow: 'hidden',
      boxShadow: '0 4px 20px rgba(0,0,0,0.15)'
    }}>
      {/* Background Image with Gradient Overlay */}
      <Box sx={{ 
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundImage: `url(${provider?.linkedin_data?.background_cover_image_url || '/default-cover.jpg'})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: `linear-gradient(to bottom, 
            rgba(0,0,0,0.3) 0%, 
            rgba(0,0,0,0.6) 100%
          )`,
        }
      }} />

      {/* Content */}
      <Box sx={{ 
        position: 'relative',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        p: 4
      }}>
        <Box sx={{ 
          display: 'flex', 
          alignItems: 'flex-end', 
          gap: 3,
          mb: 2 
        }}>
          <Avatar
            src={provider?.linkedin_data?.profile_pic_url}
            alt={provider?.name}
            sx={{ 
              width: 120, 
              height: 120, 
              border: '4px solid white',
              boxShadow: '0 4px 12px rgba(0,0,0,0.15)'
            }}
          />
          <Box sx={{ flex: 1 }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'space-between',
              width: '100%'
            }}>
              <Box sx={{ color: 'white' }}>
                <Typography 
                  variant="h3" 
                  sx={{ 
                    fontWeight: 600,
                    textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                    mb: 1
                  }}
                >
                  {provider?.name}
                </Typography>
                <Typography 
                  variant="h6" 
                  sx={{ 
                    opacity: 0.9,
                    textShadow: '0 1px 2px rgba(0,0,0,0.2)'
                  }}
                >
                  {provider?.linkedin_data?.company_location}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 1 }}>
                {provider?.url && (
                  <IconButton 
                    onClick={handleVisitWebsite}
                    sx={{ 
                      backgroundColor: alpha(theme.palette.common.white, 0.1),
                      backdropFilter: 'blur(8px)',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.common.white, 0.2)
                      }
                    }}
                  >
                    <PublicIcon />
                  </IconButton>
                )}
                {provider?.linkedin_url && (
                  <IconButton 
                    onClick={handleVisitLinkedIn}
                    sx={{ 
                      backgroundColor: alpha(theme.palette.common.white, 0.1),
                      backdropFilter: 'blur(8px)',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: alpha(theme.palette.common.white, 0.2)
                      }
                    }}
                  >
                    <LinkedInIcon />
                  </IconButton>
                )}
                <IconButton 
                  onClick={onRefresh}
                  sx={{ 
                    backgroundColor: alpha(theme.palette.common.white, 0.1),
                    backdropFilter: 'blur(8px)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: alpha(theme.palette.common.white, 0.2)
                    }
                  }}
                >
                  <RefreshIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const ProviderView = () => {
  const { providerId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const [provider, setProvider] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProvider = async () => {
      try {
        setLoading(true);
        const response = await providerService.getProvider(providerId);
        if (response?.status === 'success') {
          setProvider(response.data);
        }
      } catch (error) {
        console.error('Error fetching provider:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProvider();
  }, [providerId]);

  const refreshProvider = async () => {
    try {
      setLoading(true);
      const response = await providerService.getProvider(providerId);
      if (response?.status === 'success') {
        setProvider(response.data);
      }
    } catch (error) {
      console.error('Error refreshing provider:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh' 
      }}>
        <CircularProgress size={48} />
      </Box>
    );
  }

  return (
    <Box sx={{ bgcolor: 'background.default', minHeight: '100vh' }}>
      <Container maxWidth="xl" sx={{ py: 4 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate('/course-stream-ai/providers')}
          sx={{ 
            mb: 3,
            color: 'text.primary',
            bgcolor: theme.palette.background.paper,
            boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
            '&:hover': {
              bgcolor: alpha(theme.palette.primary.main, 0.05)
            },
            textTransform: 'none',
            fontSize: '1rem',
            fontWeight: 500,
            px: 2,
            py: 1
          }}
        >
          Back to Providers
        </Button>

        <ProviderHeader provider={provider} onRefresh={refreshProvider} />

        <Paper 
          elevation={0}
          sx={{ 
            mb: 4,
            borderRadius: 2,
            overflow: 'hidden',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              px: 2,
              '& .MuiTab-root': {
                textTransform: 'none',
                fontSize: '1rem',
                fontWeight: 500,
                minHeight: 56,
                '&.Mui-selected': {
                  color: 'primary.main'
                }
              },
              '& .MuiTabs-indicator': {
                height: 3,
                borderRadius: '3px 3px 0 0'
              }
            }}
          >
            <Tab label="Overview" />
            <Tab label="Enrichment" />
            <Tab label="Crawling" />
            <Tab label="Courses" />
            <Tab label="Contacts" />
            <Tab label="Messages" />
            <Tab label="Outreach" />
          </Tabs>
        </Paper>

        <Paper 
          elevation={0}
          sx={{ 
            borderRadius: 2,
            overflow: 'hidden',
            border: '1px solid',
            borderColor: 'divider'
          }}
        >
          {activeTab === 0 && <ProviderOverview provider={provider} onRefresh={refreshProvider} />}
          {activeTab === 1 && <ProviderEnrichment provider={provider} />}
          {activeTab === 2 && <ProviderCrawling provider={provider} />}
          {activeTab === 3 && <ProviderCourses provider={provider} />}
          {activeTab === 4 && (
            <Box sx={{ p: 4 }}>
              <Typography 
                variant="h5" 
                sx={{ 
                  fontWeight: 600,
                  mb: 3 
                }}
              >
                Outreach Status
              </Typography>
              <Box sx={{ 
                display: 'flex', 
                gap: 2, 
                alignItems: 'center',
                mb: 3 
              }}>
                <StatusChip status={provider?.status?.outreach?.status} />
                <Typography variant="body1">
                  {provider?.status?.outreach?.contacts_identified} contacts identified
                </Typography>
              </Box>
              <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(2, 1fr)',
                gap: 3 
              }}>
                <Paper 
                  variant="outlined"
                  sx={{ p: 3, borderRadius: 2 }}
                >
                  <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
                    {provider?.status?.outreach?.emails_sent}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Emails sent
                  </Typography>
                </Paper>
                <Paper 
                  variant="outlined"
                  sx={{ p: 3, borderRadius: 2 }}
                >
                  <Typography variant="h6" color="primary.main" sx={{ mb: 1 }}>
                    {provider?.status?.outreach?.responses}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Responses received
                  </Typography>
                </Paper>
              </Box>
            </Box>
          )}
        </Paper>
      </Container>
    </Box>
  );
};

export default ProviderView;
